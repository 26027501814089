<template>
    <div>
        <div style="height: 100px;background: rgb(255,251,235);border-radius: 8px;margin:15px; ">
            <div style="text-align: left; color: #ffcb41;font-size: small;padding: 10px 10px 0 10px;font-weight: 550;">
                温馨提示
            </div>
            <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
                {{ message }}
            </div>
        </div>
        <van-empty v-if="reverseList.length === 0" description="暂无数据">
        </van-empty>
        <span v-for="(item, index) in reverseList" :key="index" @click="showMessage">
        <div style="height: 150px;background: white;border-radius: 10px;margin:15px; box-shadow:6px 6px 9px #eee;">

        <div style="padding: 15px;">
          <span class="van-ellipsis"
                style="float: left;font-size: large;">{{ JSON.parse(item.remarks).company.title }}</span>
          <span
                  style="text-align: right;float: right;color: rgb(6,214,160);">{{
              item.state === 0 ? '待确认' : '已确认'
              }}</span>
        </div>
        <van-divider/>
        <div style="height: 20px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: small">姓名</span>
          <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ item.name }}</span>
        </div>
        <div style="height: 20px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: small">手机号</span>
          <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ item.phone }}</span>
        </div>

        <div style="height: 20px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: small">医生姓名</span>
          <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ JSON.parse(item.remarks).parentInfo.title }}</span>
        </div>

        <div style="height: 20px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: small">创建时间</span>
          <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ item.createdAt }}</span>
        </div>
      </div>
    </span>

    </div>
</template>

<script>
import axios from "axios";
import {Dialog} from "vant";

export default {
    name: "doctorOrder",
    data() {
        return {
            reverseList: [],
            message: "提交签约家庭医生申请后，工作人员会尽快与您联系，请耐心等待。"
        };
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        this.getReverseList();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        showMessage() {
            Dialog.alert({message: '您的签约申请已经收到，工作人员会尽快与您联系，请耐心等待。', messageAlign: 'left',});
        },
        goSubscribeDetail(item) {
            localStorage.setItem('subscribeDetail', JSON.stringify(item))
            this.$router.push("subscribeDetail")
        },
        getReverseList() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/orders/my', {
                "modelType": "App\\Doctor",
                "userId": userInfo.id,
                "state": 1,
                "title": "_",
                "parentId": 0,
                "page": 1,
                "per_page": 20,
                "companyId": userInfo.companyId
            })
                .then(function (response) {
                    that.reverseList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>