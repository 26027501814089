<template>
  <div>
    <div style="margin-top: 20px;">
      <van-image

          width="80"
          height="80"
          src="http://vaccine.images.wxdev.top/操作成功.png"
      />
    </div>

    <div>
      <span style="font-size: 20px;"> 排队成功 </span>
    </div>
    <div style="text-align: left;margin-left: 20px;margin-top: 30px;">
      <span>温馨提示:</span>
    </div>
    <div style="text-align: left;margin: 20px;">
      <!-- <span style="font-size: 13px;">{{message}}</span> -->
      <p v-html="message" class="content" style="font-size:13px;width: 100%;overflow: hidden;" ></p>
    </div>

    <!--<div style="margin-top: 60px;" >
      <van-button @click="detail" color="linear-gradient(to right, #8dc3f9, #1989fa)"
                  style="border-radius: 5px;width: 150px; margin: 10px 20px;">
        查看详细
      </van-button>
    </div>-->

    <div style="float: none;width: 100%; height: 80px;margin-top: 60px;">
      <!--<van-button type="default" @click="detail">查看详细</van-button>-->
      <van-button type="info" style="margin-left: 30px;" @click="showTip">分享给朋友</van-button>

    </div>

    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block" >
          <van-image  @click="show = false"
              width="100%"
              height="100%"
              src="http://vaccine.images.wxdev.top/分享提示.png"
          />

        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { Image as VanImage } from 'vant';
// import wx from 'weixin-js-sdk'
import { Overlay } from 'vant';
import axios from "axios";
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;
Vue.use(Overlay);
Vue.use(VanImage);
export default {
  name: "QuSuccess",
  data() {
    return {
        message:'',
        show:false
    }
  },
  mounted() {
    //if(localStorage.getItem('queuingSuccessDescription') != null)
      //this.message = localStorage.getItem('queuingSuccessDescription')
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    this.comFun.initJsSDK();
    this.getOrderInfo();
  },
  methods:{
    showTip() {
      //提示分享
      this.show = true;
    },
    /*share() {
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: '正式预约疫苗服务', // 分享标题
          desc: '分享一个正规快速的预约疫苗服务平台，快来帮我助力！', // 分享描述
          link: 'http://p.eshekang.com/#/share', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://vaccin-image.wxdev.top/logo.png', // 分享图标
          success: function () {
            // 设置成功
          }
        })
      });
    },*/
    /*initJsSDK() {
      //let that = this;
      //alert("window.location.href = "+ encodeURIComponent(window.location.href));
      //alert("location.href.split('#')[0] = "+ encodeURIComponent(location.href.split('#')[0]));
      let url = encodeURIComponent(location.href.split('#')[0]);
      axios.get('/api/wechat/js/sdk?url='+ url)
          .then(function (response) {
            let res = response.data.data;
            //alert('config = ' + JSON.stringify(res));
            wx.config({
              debug: false,
              appId: res.appId,
              timestamp:res.timestamp,
              nonceStr: res.nonceStr,
              signature: res.signature,
              jsApiList: ['getLocation','openLocation','updateAppMessageShareData','scanQRCode']
            });

            wx.error(function(res){
              console.log(res)
              //alert('JSSDK error............' + JSON.stringify(res))
            });
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
              wx.updateAppMessageShareData({
                title: '预约疫苗服务', // 分享标题
                desc: '分享一个正规快速的预约疫苗服务平台，快来帮我助力！', // 分享描述
                link: 'http://p.eshekang.com/#/share', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'http://vaccin-image.wxdev.top/logo.png', // 分享图标
                success: function () {
                  // 设置成功
                  //alert('设置成功');
                },fail: function () {
                  //alert(JSON.stringify(res))
                }
              })
            });

          })
          .catch(function (error) {
            console.log(error);
          });

    },*/
    //订单信息
    getOrderInfo() {
      let that = this;
      axios.get('/api/queue/product/' + this.$route.query.id)
          .then(function (response) {
            console.log(response)
            that.getVaccineInfo(response.data.data.product.id)
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //疫苗信息
    getVaccineInfo(id) {
      let that = this;
      axios.get('/api/vaccine/product/' + id
      )
          .then(function (response) {
            that.vaccineInfo = response.data.data;
            if (that.vaccineInfo.queuing_success_description != null) {
              that.message = that.vaccineInfo.queuing_success_description;
            }
            //针次
            that.injectionTotal = that.vaccineInfo.vaccine.injection_total;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    detail() {
      this.$router.replace({name:'reserveDetail',query:{id:this.$route.query.id}})
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>