<template>
  <div>
    <div style="height: 150px;background: white;">
      <div style="padding: 15px;">
        <span style="float: left;font-size: large;">{{ JSON.parse(detail.remarks).vaccine.title }}</span>
        <span style="text-align: right;float: right;color: rgb(6,214,160);">待通知</span>
      </div>
      <van-divider/>
      <div style="height: 20px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">订阅人</span>
        <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ detail.name }}</span>
      </div>

      <div style="height: 20px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">订阅时间</span>
        <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ detail.createdAt }}</span>
      </div>
      <div style="height: 20px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">订阅门诊</span>
        <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ JSON.parse(detail.remarks).company.title }}</span>
      </div>
    </div>
    <div style="height: 100px;background: rgb(255,251,235);border-radius: 8px;margin:15px; ">
      <div style="text-align: left; color: #ffcb41;font-size: small;padding: 10px 10px 0 10px;font-weight: 550;">
        温馨提示
      </div>
      <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
        {{ message }}
      </div>
    </div>

    <div style="text-align: left; height: 40px; font-size: small;margin-left: 15px;">
      <van-icon name="info-o"/>
      <span> 其他注意事项详见订阅须知</span>
    </div>

    <div style="float: none;width: 100%; height: 80px;">
      <van-button type="default" @click="confirmCancel">取消订阅</van-button>
      <van-button type="info" style="margin-left: 30px;">分享给朋友</van-button>

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import {Button, Dialog} from 'vant';
import axios from "axios";

Vue.use(Button);
export default {
  name: "SubscribeDetail",
  data() {
    return {
      detail: [],
      message: "机构到苗后将发送到苗通知，请留意\"预约疫苗服务\"公众号[服务进度提醒]，收到提醒后请及时预约，预约成功后即可进行接种。"
    }
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    console.log(localStorage.getItem('subscribeDetail'))
    this.detail = JSON.parse(localStorage.getItem("subscribeDetail"))
  },
  methods:{

    confirmCancel(){
      Dialog.confirm({

        message: '确认要取消该疫苗订阅吗？取消后不能及时接收到苗通知。',
      })
          .then(() => {
            // on confirm
            this.cancelSubscribe()
          })
          .catch(() => {
            // on cancel
          });
    },

    cancelSubscribe(){


      let that = this;
      axios.delete('/api/collection/product/'+ this.detail.id, {

      })
          .then(function (response) {
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
              confirmButtonColor: 'black'
            }).then(() => {
              // on close
              //if(response.data.code === 200)
                that.$router.back();
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
</script>

<style lang="less">
.van-dialog {
  border-radius: 8px;
  background: white url(../../assets/dialogLogo.png) no-repeat ;
}
</style>