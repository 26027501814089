<template>
    <div>
        <div class="background-image">
            <div class="title">
                {{ name }}{{ relation }},{{ greetings }}
                <span>
                    <van-icon name="setting-o" @click="setting()"
                              style="margin-right: 20px;float: right;font-size: 28px;"/>
                </span>
            </div>
        </div>
        <div>
            <van-cell title="育儿要点" to="parentingCategory" is-link value="更多"/>
            <span v-for="(item, index) in articleList" :key="index" @click="goDetail(item);">
              <div style="width: 100%;">
                <div style="height: 80px;margin: 15px; ">
                  <div style="width: 70%;float: left;">
                    <div style="text-align: left;">

                      <div class="van-multi-ellipsis--l2" style="font-size: medium;font-weight: 550;color: #565656;">
                        {{ item.title }}
                      </div>
                    </div>

                    <div style="text-align: left;float: left;margin-top: 20px;">
                      <span style="font-size: small;color: steelblue;">
                        #{{ item.subtitle }}
                      </span>
                      <span style="font-size: small;margin-left: 20px;color: #565656;">
                        {{ item.createdAt }}
                      </span>
                    </div>
                  </div>
                  <div style=" float: right; height: 30px; width: 30%; text-align: end;">
                    <van-image
                        cover
                        width="100px"
                        height="80px"
                        :src=item.thumb
                    />
                  </div>
                </div>
                <van-divider/>
              </div>
            </span>
        </div>

    </div>
</template>

<script setup>
import axios from "axios";
import moment from "moment";
import {formatDate} from "@/utils/date";

export default {
    name: "parentingHome",
    data() {
        return {
            articleList: [],
            relation: '家长',
            name: '孩子',
            greetings: '您好',
        }
    },
    mounted() {
        document.title = '育儿知识'
        if (this.$route.query.relation != null) {
            this.relation = this.$route.query.relation;
            this.name = this.$route.query.name;

            localStorage.setItem("relation", this.$route.query.relation);
            localStorage.setItem("petName", this.$route.query.name);
        } else if (localStorage.getItem("relation") != null) {
            this.relation = localStorage.getItem("relation");
            this.name = localStorage.getItem("petName");
        }
        this.getTimeState();
        this.getRecommend();
    },
    methods: {
        //计算年龄范围
        getTag(birthday) {
            console.log('生日' + birthday)
            const startDate = new Date(birthday);
            const endDate = formatDate(new Date(), 'yyyy-MM-dd');
            return this.getDaysDiff(startDate, endDate);
        },
        getDaysDiff(date1, date2) {
            const diffInDays = moment(date2).diff(moment(date1), 'days');
            console.log('diffInDays ' + diffInDays)
            if (diffInDays < 365) {
                return '0-1岁';
            }
            if (diffInDays >= 365 && diffInDays <= 1095) {
                return '1-3岁';
            } else {
                return '3-6岁';
            }

        },
        setting() {
            this.$router.push('parentingSetting')
        },
        goDetail(item) {
            this.$router.push({name: 'parentingArticle', query: {id: item.id}})
        },
        //请求文章列表
        getRecommend() {
            let that = this;
            //let appid = localStorage.getItem('appid');
            //let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/parenting/recommend', {
                "tag": this.getTag(this.$route.query.birthday)
            })
                .then(function (response) {
                    that.articleList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getTimeState() {
            // 获取当前时间
            let timeNow = new Date();
            // 获取当前小时
            let hours = timeNow.getHours();
            // 设置默认文字
            let state = `您好`;
            // 判断当前时间段
            if (hours >= 0 && hours <= 4) {
                state = `晚安!`;
            }
            if (hours > 4 && hours <= 11) {
                state = `早上好!`;
            } else if (hours > 11 && hours <= 14) {
                state = `中午好!`;
            } else if (hours > 14 && hours <= 18) {
                state = `下午好!`;
            } else if (hours > 18 && hours <= 21) {
                state = `晚上好!`;
            } else if (hours > 21 && hours <= 24) {
                state = `晚安!`;
            }
            this.greetings = state;
        }
    }
}
</script>

<style scoped>
.background-image {
    height: 120px; /* 设置div的高度 */
    background: rgb(106, 199, 132);
    /*background-image: url('https://vaccin-image.wxdev.top/WechatIMG22.jpg');*/ /* 设置背景图片 */
    background-size: cover; /* 背景图片覆盖整个div区域 */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat;
    width: 100%; /* 或者具体宽度 */

}

.sub-title {
    margin: 10px 10px;
    color: white;
    font-size: small;
    text-align: left;
}

.title {
    padding-top: 50px;
    margin-left: 10px;
    color: white;
    font-weight: bold;
    font-size: larger;
    text-align: left;
}
</style>