<template>
    <div>
        <van-tabs v-model="activeTab" @click="onTabChange">
      <span v-for="(item, index) in vaccineClassList" :key="index">
        <van-tab :title=item.title>
          <!-- <div style="position: absolute;margin-top: 100px;margin-left: 70%;z-index: 100;float: end;">详细信息</div>-->
          <div v-if="item.thumb != null">
            <van-image
                    fit="contain"
                    lazy-load
                    width="100%"
                    :src=item.thumb
            />
          </div>
          <span v-for="(item2, index) in vaccineList" :key="index">
            <div style="margin: 15px;border-radius: 10px;background: white;">
              <van-cell center :title=item2.title @click="goVaccineList(item2)" :label=item2.description is-link/>
            </div>
          </span>

        </van-tab>
      </span>


            <!--<van-tab title="流感疫苗">
              <div>
                <van-image
                    fit="contain"
                    lazy-load
                    width="100%"
                    src="http://vaccine.images.wxdev.top/%E6%B5%81%E6%84%9F%E7%96%AB%E8%8B%97_1595991690087.png"
                />
              </div>
            </van-tab>


            <van-tab title="流感疫苗">
              <div>
                <van-image
                    fit="contain"
                    lazy-load
                    width="100%"
                    src="http://vaccine.images.wxdev.top/%E6%B5%81%E6%84%9F%E7%96%AB%E8%8B%97_1595991690087.png"
                />
              </div>
            </van-tab>
            <van-tab title="标签 3">内容 3</van-tab>
            <van-tab title="标签 4">内容 4</van-tab>-->
        </van-tabs>
    </div>
</template>

<script>

import axios from "axios";
//import {Toast} from 'vant';
import Vue from 'vue'
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;
export default {
    name: "vaccin",
    data() {
        return {
            activeTab: 0,
            vaccineClassList: [],
            vaccineList: [],
            token: ''
            //categoryId: 0
        };
    },
    created() {
        let appid = this.comFun.getAppid();
        if (appid === undefined) {
            appid = this.$route.query.appid;
        }
        if (appid !== undefined) {
            localStorage.setItem('appid', appid);
            console.log('this.$route.query.index == ' + this.$route.query.index)
            this.activeTab = this.$route.query.index ? parseInt(this.$route.query.index) : 0;//TODO 重新返回界面的时候再次选中之前的活动Tab
            this.getCompanyInfo(this.activeTab, appid);

        } else {
            appid = localStorage.getItem('appid');
            this.activeTab = this.$route.query.index ? parseInt(this.$route.query.index) : 0;//TODO 重新返回界面的时候再次选中之前的活动Tab
            this.getCompanyInfo(this.activeTab, appid);
        }

        let code = this.comFun.getQueryVariable('code');
        if (localStorage.getItem('userInfo' + appid) == null) {
            if (code === false || code.length < 10) {
                const appid2 = this.$route.query.appid;
                if (localStorage.getItem('appid') === undefined) {
                    localStorage.setItem('appid', appid2);
                }
                this.comFun.getCode(appid2);
            } else {
                this.comFun.getUserInfo();
            }
        } else {
            this.comFun.initJsSDK();
        }

    },
    mounted: function () {

        document.title = Vue.platformTitle;
    },
    methods: {
        //获取公司信息
        getCompanyInfo(tabIndex, appid) {
            let _this = this;
            axios.post('/companies/appId', {
                "appId": appid
            })
                .then(function (response) {
                    localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
                    document.title = response.data.data.title;
                    _this.getVaccineCategory(tabIndex)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //跳转到选择门诊列表，记录疫苗id
        goVaccineList(item) {
            //选中的疫苗
            //localStorage.setItem('chooseVaccineId', item.id)
            //alert("选中的疫苗 id = " + item.id + "   疫苗名称 = " +item.title)
            this.$router.push({
                    name: 'step1',
                    query: {
                        chooseVaccineId: item.id
                    }
                }
            );
        },
        //tab切换事件
        onTabChange(index, title) {
            //Toast(index);
            //Toast(title);
            console.log('title ============================ ');
            console.log(title);

            console.log('index ============================ ');
            console.log(index);

            //获取疫苗分类id，获取产品列表
            let vaccineClass = this.vaccineClassList[index];

            //alert(vaccineClass['id']);

            console.log('================ ' + JSON.stringify(vaccineClass));

            //Toast(vaccineClass['id'])

            //this.categoryId = vaccineClass['id'];
            let that = this;
            //axios.defaults.headers.common['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZCIsImlzcyI6ImdkIiwiZXhwIjoxNjA5NDI5Njc1LCJpYXQiOjE2MDg1NjU2NzUsInJvbCI6IjEifQ.HNe3_MH4dAobi6i3dlDNWqRe_jwz1VC8rLMHAfCxHXcENp8dpRd8cIBIu4heB8IADYhoLRy2vIynY9ddrfV5oQ";
            //请求疫苗列表
            axios.post('/vaccines/list', {
                "categoryIds": vaccineClass['id'],
                "companyId": 25,

            })
                .then(function (response) {
                    console.log(response);
                    that.activeTab = index;
                    //that.images.splice(0);//清空
                    that.vaccineList = response.data.data;
                    /*for (let i = 0; i < arr.length; i++) {
                      that.images.push(arr[i].thumb);
                      console.log(arr[i].thumb);
                    }*/
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
        //获取疫苗分类
        getVaccineCategory(index) {
            let that = this;
            //axios.defaults.headers.common['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZCIsImlzcyI6ImdkIiwiZXhwIjoxNjA5NDI5Njc1LCJpYXQiOjE2MDg1NjU2NzUsInJvbCI6IjEifQ.HNe3_MH4dAobi6i3dlDNWqRe_jwz1VC8rLMHAfCxHXcENp8dpRd8cIBIu4heB8IADYhoLRy2vIynY9ddrfV5oQ";
            axios.post('/aggregates/list', {
                "modelType": "vaccineCategory",
                "title": "_",
                "state": 1,
                "parentId": 0,
                "order_new": true,
                "page": 1,
                "per_page": 50,
                "companyId": 25,
                "company_ids": 0
            })
                .then(function (response) {
                    console.log(response);
                    //that.images.splice(0);//清空
                    that.vaccineClassList = response.data.data;
                    //alert( that.$route.query.index)

                    that.onTabChange(index, 'title');

                    /*if(localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) {
                      that.getUserInfo();
                    } else {
                      let code = that.getQueryVariable('code');
                      //alert('code ============================] ' + code)
                      if (code) {
                        //alert(code);
                        //localStorage.setItem('code', code);
                        that.getToken(code);
                      } else {
                        //alert(code)
                        that.getCode();
                      }
                    }*/


                    //that.activeTab = that.activeTab;
                    /*for (let i = 0; i < arr.length; i++) {
                      that.images.push(arr[i].thumb);
                      console.log(arr[i].thumb);
                    }*/
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.van-cell__title, .van-cell__value {
    text-align: start;
    font-size: medium;
}

.van-cell__title {
    font-size: medium;
}

.van-cell__label {
    font-size: 9px;
}

.van-cell {
    border-radius: 5px;
    box-shadow: 2px 2px 5px #eee;
    padding: 5px 15px;
}

.van-cell__label {
    font-size: 12px;
}
</style>