<template>
  <div class="main container">
    <div class="news_details">
      <div style="text-align: left;margin: 0 15px;">
        <h2>{{ detail.title }}</h2>
        <span style="width:100%;text-align: left;font-size: small;">发布时间: {{ detail.createdAt }}</span>
        <div class="news_content">
          <p v-html="detail.content" class="content" style="font-size:32px;width: 100%;overflow: hidden;"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import Vue from 'vue'

export default {
  name: "Detail.vue",
  data() {
    return {
      detail: [],
    };
  },
  mounted() {
    this.getNewsInfo();
  },
  methods: {
    //疫苗信息
    getNewsInfo() {
      let that = this;
      axios.defaults.headers.common.Authorization = localStorage.getItem("token");
      axios.post('/aggregates/info', {
        id: this.$route.query.id,
        modelType: 'App\\Aggregate'
      })
          .then(function (response) {
            that.detail = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
  }
}
</script>

<style scoped>
.news_content {
  overflow-y: auto;
}

.content >>> img {
  max-width: 100%;
  height: auto;
  display: flex;
}
</style>