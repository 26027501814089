<template>
    <div>
        <!--    <div style="height: 100px;background: rgb(255,251,235);border-radius: 8px;margin:15px; ">
              <div style="text-align: left; color: #ffcb41;font-size: small;padding: 10px 10px 0 10px;font-weight: 550;">
                温馨提示
              </div>
              <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
                {{ message }}
              </div>
            </div>-->
        <van-empty v-if="reverseList.length === 0" description="暂无数据">
        </van-empty>
        <span v-for="(item, index) in reverseList" :key="index" @click="showMessage(item)">
        <div style="height: 140px;background: white;border-radius: 10px;margin:15px; box-shadow:6px 6px 9px #eee;">

        <div style="padding: 15px;">
          <span class="van-ellipsis"
                style="float: left;font-size: large;">{{ item.title }}</span>
          <span
                  style="text-align: right;float: right;color: orangered;">￥{{ item.price }}</span>
        </div>
        <van-divider/>
        <div style="height: 60px;padding: 0 20px;">
          <span style="float: left;color: #909090;font-size: small">说明:</span>
          <br/>
          <span style="text-align: left;float: left; font-size: small;color: #565656;">
          {{ item.description }}
          </span>
        </div>

      </div>
    </span>

    </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue';
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;

export default {
    name: "nucleinStep1",
    data() {
        return {
            reverseList: [],
            message: "核酸检测预约。"
        };
    },
    created() {
        let appid = this.comFun.getAppid();
        if (appid === undefined) {
            appid = this.$route.query.appid;
        }
        if (appid !== undefined) {
            localStorage.setItem('appid', appid);
            this.getCompanyInfo(appid);
        } else {
            appid = localStorage.getItem('appid');
            this.getCompanyInfo(appid);
        }
        let code = this.comFun.getQueryVariable('code');
        if (localStorage.getItem('userInfo' + appid) == null) {
            if (code === false || code.length < 10) {
                const appid2 = this.$route.query.appid;
                if (localStorage.getItem('appid') === undefined) {
                    localStorage.setItem('appid', appid2);
                }
                this.comFun.getCode(appid2);
            } else {
                this.comFun.getUserInfo();
            }
        } else {
            this.comFun.initJsSDK();
        }
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
    },
    methods: {
        //获取公司信息
        getCompanyInfo(appid) {
            let _this = this;
            axios.post('/companies/appId', {
                "appId": appid
            })
                .then(function (response) {
                    localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
                    document.title = response.data.data.title;
                    _this.getReverseList()
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        goBack() {
            this.$router.go(-1);
        },
        showMessage(item) {
            //Dialog.alert({message: '您的签约申请已经收到，工作人员会尽快与您联系，请耐心等待。', messageAlign: 'left',});
            localStorage.setItem('chooseNucleinId', item.id)
            this.$router.push("nucleinStep2")
        },
        goSubscribeDetail(item) {
            localStorage.setItem('subscribeDetail', JSON.stringify(item))
            this.$router.push("subscribeDetail")
        },
        getReverseList() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/items/list', {
                "modelType": "App\\Doctor",
                "userId": userInfo.id,
                "state": 1,
                "title": "_",
                "parentId": 0,
                "page": 1,
                "per_page": 20,
                "companyId": userInfo.companyId
            })
                .then(function (response) {
                    that.reverseList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>