<template>
    <div>

                <span v-for="(item, index) in articleList" :key="index" @click="goDetail(item);">
              <div style="width: 100%;">
                <div style="height: 65px;margin: 15px; ">
                  <div style="width: 70%;float: left;">
                    <div style="text-align: left;">
                      <div class="van-multi-ellipsis--l2" style="font-size: medium;font-weight: 550;color: #565656;">
                        {{ item.title }}
                      </div>
                    </div>
                    <div style="text-align: left;float: left;margin-top: 20px;">
                      <span style="font-size: small;color: steelblue;">
                        #{{ item.subtitle }}
                      </span>
                      <span style="font-size: small;margin-left: 20px;color: #565656;">
                        {{ item.createdAt }}
                      </span>
                    </div>
                  </div>
                  <div style=" float: right; height: 30px; width: 30%; text-align: end;">
                    <van-image
                        cover
                        width="80px"
                        height="65px"
                        :src=item.thumb
                    />
                  </div>
                </div>
                <van-divider/>
              </div>
            </span>


    </div>


</template>

<script>
import Vue from 'vue';
import {Divider} from 'vant';
import axios from "axios";

Vue.use(Divider);
export default {
    name: "parentingList",
    data() {
        return {
            articleList: [],
        }
    },
    mounted() {
        //TODO 获取用户所选的生日范围
        document.title = '文章列表'
        this.getRecommend()
    },

    methods: {
        goDetail(item) {
            this.$router.push({name: 'parentingArticle', query: {id: item.id}})
        },
        //文章列表
        getRecommend() {
            let that = this;
            //let appid = localStorage.getItem('appid');
            //let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/parenting/list', {
                "parentId": this.$route.query.id,
                "limit": 100,
            })
                .then(function (response) {
                    that.articleList = response.data.data;
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },

    }
}
</script>

<style scoped>
.van-grid-item__content--square {
    border-radius: 15px;
}

.van-image__img {
    width: 76%;
    vertical-align: middle;
}

.van-grid-item-sub-text {
    color: #cecece;
    font-size: small;
    white-space: nowrap
}

.van-grid-item-text {
    font-size: 0.87rem;
    color: #555555;
    white-space: nowrap;
    font-weight: 500;
}
</style>