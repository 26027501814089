<template>
  <div>


        <van-cell-group>
          <van-field v-model="dateTime" label="测量时间" @click="visible=true">
          </van-field>
          <vue-hash-calendar format="YY-MM-DD hh:mm" :visible="visible" model="dialog"
                             @confirm="confirmDate"/>
          <van-field label="尿酸值" type="number" v-model="rate"   placeholder="80-650μmol/L"  />
          <van-field label="备注" v-model="mark" input-align="left" placeholder="请输入备注" />
        </van-cell-group>

        <van-button style="margin-top: 50px;width: 70%;text-align: center;" type="primary" @click="saveData" >保存</van-button>



  </div>

</template>
<script>
import {Dialog} from "vant";
import axios from "axios";

export default {
  name: "addUric",
  data() {
    return {
      rate:'',
      type:'',
      mark:'',
      activeTab:0,
      visible:false,
      dateTime:'',
      userInfo: [],
    };
  },
  mounted() {
    document.title = "添加尿酸数据"
    const date = new Date();
    this.dateTime = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));

  },
  methods:{
    confirmDate(value) {
      this.visible = false;
      this.dateTime = value;
    },
    saveData() {
      if(this.rate === '') {
        Dialog.alert({
          message: '请输入尿酸值',
          messageAlign: 'left',
          confirmButtonColor: 'black'
        })
        return;
      }

      let that = this;
      /*let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      axios.defaults.headers.common.Authorization = localStorage.getItem("token");*/
      axios.post('/chronic/save', {
        "userId": this.userInfo.id,
        "companyId": this.userInfo.companyId,
        "type": 3,
        "value1": this.rate,
        "value2": '',
        "value3": '',
        "dateTime": this.dateTime,
        "mark": this.mark,
      })
          .then(function (response) {
            console.log(JSON.stringify(response.data))
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
              confirmButtonColor: 'black'
            }).then(() => {
              // on close
              //if (response.data.code === 200)
              that.$router.back()
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
</script>
<style scoped>

</style>