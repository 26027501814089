<template>
    <div>
        <div style="width: 100%;">
        <span v-for="(item, index) in doctorTeamList" :key="index">
            <div style="height: 80px;margin: 15px;" @click="showPopup(item)">
              <div style="width: 70%;float: left;">
                <div style="text-align: left; ">
                  <div class="van-ellipsis" style="font-size: medium;font-weight: 550;color: #565656;">
                    {{ item.title }}
                  </div>
                </div>
                <div style="text-align: left;float: left;margin-top: 20px;">
                  <div class="van-multi-ellipsis--l2" style="font-size: small;color: #565656;">
                    {{ item.description }}
                  </div>
                </div>
              </div>
          </div>
          <van-divider/>
          <van-divider/>
        </span>

            <van-empty v-if="doctorTeamList.length === 0" description="暂无数据">
            </van-empty>
        </div>

        <van-popup
                v-model="show"
                closeable
                close-icon-position="top-right"
                position="bottom"
                :style="{ height: '70%' }"
        >

            <div style="height: 50px;margin: 15px;">
                <div style="width: 70%;float: left;">
                    <div style="text-align: left; ">
                        <div class="van-ellipsis" style="font-size: medium;font-weight: 550;color: #565656;">
                            {{ chooseTeam }}
                        </div>
                    </div>
                    <div style="text-align: left;float: left;margin-top: 20px;">
                        <div class="van-multi-ellipsis--l2" style="font-size: small;color: #565656;">
                            {{ chooseTeamDesc }}
                        </div>
                    </div>
                </div>
            </div>

            <div style="height: 1px;width: 100%;">
            </div>
            <van-divider/>

            <van-radio-group v-model="radio">
        <span class="spanStyle" v-for="(item, index) in doctorList" :key="index">
          <van-radio :name=item.id @click="chooseDoctor(item.id)"
                     style="text-align: left;margin: 10px;width: 70%;float: left">{{ item.title }}<br>
             {{ item.description }}
          </van-radio>
          <van-image
                  cover
                  radius="2px"
                  width="18%"
                  height="18%"
                  :src=item.thumb
          />

          <van-divider/>
        </span>

                <div class="submit-bar">
                    <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                                style="border-radius: 5px;width: 200px; margin: 10px 20px; " @click="goDoctorForm">
                        签约本团队
                    </van-button>
                </div>

            </van-radio-group>
        </van-popup>

    </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import {Dialog, Popup} from 'vant';
import {RadioGroup, Radio} from 'vant';
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);
export default {
    name: "doctorList",
    data() {
        return {
            show: false,
            showSheet: false,
            doctorTeamList: [],
            doctorList: [],
            radio: '1',
            chooseTeam: '',
            chooseTeamDesc: ''
        }
    },
    created() {
        let appid = this.comFun.getAppid();
        if (appid === undefined) {
            appid = this.$route.query.appid;
        }
        if (appid !== undefined) {
            localStorage.setItem('appid', appid);
            this.getCompanyInfo(appid);
        } else {
            appid = localStorage.getItem('appid');
            this.getCompanyInfo(appid);
        }
        let code = this.comFun.getQueryVariable('code');
        if (localStorage.getItem('userInfo' + appid) == null) {
            if (code === false || code.length < 10) {
                const appid2 = this.$route.query.appid;
                if (localStorage.getItem('appid') === undefined) {
                    localStorage.setItem('appid', appid2);
                }
                this.comFun.getCode(appid2);
            } else {
                this.comFun.getUserInfo();
            }
        } else {
            this.comFun.initJsSDK();
        }
    },
    mounted() {
        /*if (localStorage.getItem("token") == null) {
          this.getUserInfo();
          this.getLocation();
        }*/
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        localStorage.removeItem('chooseDoctorId')

    },

    methods: {
        //获取公司信息
        getCompanyInfo(appid) {
            let _this = this;
            axios.post('/companies/appId', {
                "appId": appid
            })
                .then(function (response) {
                    localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
                    document.title = response.data.data.title;
                    _this.getDoctorTeamList()
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        goBack() {
            this.$router.go(-1);
        },
        chooseDoctor(id) {
            localStorage.setItem('chooseDoctorId', id)
        },
        showPopup(item) {
            this.show = true;
            this.chooseTeam = item.title;
            this.chooseTeamDesc = item.description;
            this.getDoctorList(item.id);
        },
        goDoctorForm() {
            //localStorage.setItem('chooseDoctor',item);
            if (localStorage.getItem('chooseDoctorId') == null) {
                Dialog.alert({
                    message: '请选择签约医生',
                });
                return;
            }

            this.$router.push('doctorForm')
        },
        showActionSheet() {
            console.log('showActionSheet .......................')
            this.showSheet = true;
        },
        onSelect(item) {
            console.log('item == ' + item)
            this.showSheet = false;
        },
        //获取团队列表
        getDoctorTeamList() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/aggregates/list', {
                "modelType": "doctorTeam",
                "parentId": "0",
                "state": "1",
                "title": "_",
                "company_ids": 9,
                "companyId": userInfo.companyId,
                "per_page": 5
            })
                .then(function (response) {
                    that.doctorTeamList = response.data.data;
                    //if(that.doctorTeamList.length > 0) {
                    Dialog({
                        title: '签约家庭医生好处多多',
                        message: '&emsp;&emsp;①  家庭医生主要是社区医院医生、全科医生等基层医务工作者。服务对象是婴幼儿、孕产妇、慢性病患者和老年人群，免挂号费诊费，签约了还可走医保优惠，平常巡诊，体检，可以提供初级病、常见病、基础性的预防和治疗。\n' +
                            '\n' +
                            '&emsp;&emsp;②  大病和小病的分开诊疗可以防止浪费医疗资源，家庭医生就像守门员，可以节约大量医疗资源，能够解决的小病都在大医院以外解决。社区首诊旨在缓解大院的诊疗压力，中度病症，重度病症的患者的医疗资源有保障，大医院才能发挥它真正的作用。\n' +
                            '\n' +
                            '&emsp;&emsp;③  家庭医生服务制可以防患于未然，甚至降低死亡率。而且慢性病需要医生和患者经常打交道，所以家庭医生可能比大医院医生更能提供高质量服务，因为“熟悉患者比了解疾病更为重要。”所以建立家庭医生制度，对于老年人口来说无疑是件好事。\n',
                        messageAlign: 'left',
                        confirmButtonText: '我已知晓',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on confirm
                    })
                        .catch(() => {
                            // on cancel
                        });
                    //}
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //获取医生列表
        getDoctorList(teamId) {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/aggregates/list', {
                "modelType": "doctor",
                "parentId": teamId,
                "state": "1",
                "title": "_",
                "companyId": userInfo.companyId,
                "category_ids": teamId,
                "per_page": 5
            })
                .then(function (response) {
                    that.doctorList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }

}
</script>

<style scoped>
.van-radio__label {
    text-align: left;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

.van-dialog-font-size {
    font-size: medium;
}

.van-dialog-message-font-size {
    font-size: medium;
}

/*.spanStyle{
  white-space: nowrap;  !*强制span不换行*!
  display: inline-block;  !*将span当做块级元素对待*!

  overflow: hidden;  !*超出宽度部分隐藏*!
  text-overflow: ellipsis;  !*超出部分以点号代替*!
  line-height: 0.9;  !*数字与之前的文字对齐*!
}*/
</style>