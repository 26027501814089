<template>
  <div>
    <div style="font-size: large;padding: 30px;">什么情况下不能接种或需要推迟接种?</div>
    <div style="font-size: medium;text-align: left;line-height: 35px;margin: 20px;">

      01. 如果有发热或全身不适，应推迟接种。<br>


      02. 若患有严重慢性疾病，处于慢性疾病的急性发作期，建议推迟接种。<br>


      03. 若正患急性传染病或急性传染病痊愈不到2周时，应推迟接种。<br>


      04. 若既往接种同一种疫苗后，出现过严重不良反应（过敏反应、虚脱或休克、无明确病因的脑炎/脑病或惊厥）者，不应继续接种同种疫苗。<br>


      05. 若近期使用过免疫抑制剂，如免疫球蛋白，应推迟减毒活疫苗的接种。<br>


      06. 若免疫力异常（如有先天性或获得性免疫缺陷、恶性肿瘤等），不应接种减毒活疫苗。<br>


      07. 若处于妊娠期或随后3个月内有怀孕打算，不应接种风疹、水痘等减毒活疫苗。甲肝、脊髓灰质炎、伤寒等疫苗也需根据情况综合考虑，建议提前咨询医生。<br>


      08. 若有脑病、未控制的癫痫和其他进行性神经系统疾病，不应接种含有百日咳抗原的疫苗，以及乙脑、流脑疫苗。<br>


      09. 若有严重过敏史，接种前应详细了解自己的过敏原。含有该过敏原的疫苗不应接种，不含该过敏原的疫苗可接种。<br>


      10. 若有感冒、月经、服用药物等特殊情况，建议先咨询门诊医生，确认是否可以接种。<br>
    </div>
  </div>
</template>

<script>
export default {
name: "Warning"
}
</script>

<style scoped>

</style>