<template style="background: #EEEEEE">
  <div>
    <van-tabs v-model="activeTab" @click="onTabChange">
      <van-tab title="上传处方">
        <van-cell value="图片上传" style="margin: 20px 0"/>

        <van-uploader  style="margin: 20px" v-model="fileList1" :before-read="beforeRead" :after-read="afterRead" multiple :max-count="5" />

        <van-button style="margin-top: 50px;width: 70%;text-align: center;" type="primary" @click="submit(1)">确定</van-button>

        <div style="margin: 20px 5px;">
          <van-cell value="最新上传" />
            <van-uploader style="margin-top: 20px;" v-model="preview1" v-if="preview1.length > 0"  :deletable="false" :max-count="1"/>
        </div>
      </van-tab>
      <van-tab title="上传药盒">
        <van-cell value="图片上传" style="margin: 20px 0"/>

        <van-uploader  style="margin: 20px" v-model="fileList2" :before-read="beforeRead" :after-read="afterRead" multiple :max-count="5" />

        <van-button style="margin-top: 50px;width: 70%;text-align: center;" type="primary" @click="submit(2)">确定</van-button>
        <div style="margin: 20px  5px;">
          <van-cell value="最新上传" />

          <van-uploader style="margin-top: 20px;"  v-if="preview2.length > 0" v-model="preview2"  :deletable="false" :max-count="1"/>
        </div>
      </van-tab>
      <van-tab title="上传化验单">
        <van-cell value="图片上传" style="margin: 20px 0"/>

        <van-uploader  style="margin: 20px" v-model="fileList3" :before-read="beforeRead" :after-read="afterRead" multiple :max-count="5" />

        <van-field style="margin: 20px;width: 90%;" label="化验单类型" input-align="right" v-model="mark" placeholder="请输入化验单类型" />

        <van-button style="margin-top: 50px;width: 70%;text-align: center;" type="primary" @click="submit(3)">确定</van-button>
        <div style="margin: 20px 5px;">
          <van-cell value="最新上传" />
          <van-uploader style="margin-top: 20px;"  v-model="preview3" v-if="preview3.length > 0"  :deletable="false" :max-count="1"/>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import Vue from 'vue';
import {Dialog, Toast, Uploader} from 'vant';
import * as qiniu from 'qiniu-js';
import axios from "axios";
Vue.use(Uploader);
export default {
  name: "upload",
  data() {
    return {
      preview1: [],
      preview2: [],
      preview3: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      imageList: [],
      activeTab: 0,
      type: '',
      mark: '',
      qiniuData: {
        token: '',
        key: ''
      },
      // 七牛云上传储存区域的上传域名（华东、华北、华南、北美、东南亚,华东2区）
      upload_qiniu_url: 'http://upload-z2.qiniup.com',
      // 七牛云返回储存图片的子域名
      upload_qiniu_addr: 'http://vaccin-image.wxdev.top/',
      userInfo: [],
    };
  },
  mounted() {
    document.title = '拍照上传'
    let appid = localStorage.getItem('appid');
    this.userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
    this.getPicToken();
    this.showImage(1);
  },
  methods:{
    submit(type) {
      if(this.imageList.length === 0) {
        Dialog.alert({
          message: '请上传图片',
          messageAlign: 'left',
          confirmButtonColor: 'black'
        })
        return;
      }
      const that = this;
      axios.post('/chronic/upload', {
        "userId": this.userInfo.id,
        "companyId": this.userInfo.companyId,
        "type": type,
        "imageList": JSON.stringify(this.imageList),
        "dateTime": this.dateTime,
        "mark": this.mark,
      })
          .then(function (response) {

            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
              confirmButtonColor: 'black'
            }).then(() => {
              that.fileList1 = [];
              that.fileList2 = [];
              that.fileList3 = [];
              that.imageList = [];
              that.preview1 = [];
              that.preview2 = [];
              that.preview3 = [];
              that.mark = '';
              that.showImage(type);
            });
          })
          .catch(function (error) {
            console.log('/chronic/upload' + error);
          });
    },
    showImage(type) {
      const that = this;
      axios.post('/chronic/image', {
        "userId": this.userInfo.id,
        "companyId": this.userInfo.companyId,
        "type": type,
      })
          .then(function (response) {
            response.data.data.forEach(function (element) {
              switch (type) {
                case 1:
                  that.preview1.push({url: element.url, isImage: true})
                  break;
                case 2:
                  that.preview2.push({url: element.url, isImage: true})
                  break;
                default:
                  that.preview3.push({url: element.url, isImage: true})
                  break;
              }
            })
          })
          .catch(function (error) {
            console.log('/chronic/image'+error);
          });
    },
    getPicToken() {
      const _this = this;
      axios.get('/qiniu/getToken',
          {})
          .then(function (response) {
            _this.qiniuData.token = response.data.data.token;
            _this.qiniuData.key = response.data.data.key;
          })
          .catch(function (error) {
            console.log('/qiniu/getToken'+error);
          });
    },
    onTabChange(index, title) {
      console.log('onTabChange '+title);
      switch (index) {
        case 1:
          this.preview2 = [];
          this.showImage(2)
          break;
        case 2:
          this.preview3 = [];
          this.showImage(3)
          break;
        default:
          this.preview1 = [];
          this.showImage(1)
          break;
      }
    },
    beforeRead (file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        Toast('请上传 jpg或png 格式图片');
        return false;
      }
      return true;
    },
    afterRead (file)  {
      const that = this;
      this.getPicToken();
      file.status = 'uploading';
      file.message = '上传中...';

      const putExtra = { fname: this.qiniuData.key };
      const config = { useCdnDomain: true };

      const observable = qiniu.upload(file.file, this.qiniuData.key, this.qiniuData.token, putExtra, config);
      observable.subscribe({
        next(res) {
          // 可以在这里添加上传进度条更新的逻辑
          console.log("1111111111111 ===== >"+res.key)
        },
        error(err) {
          // 处理上传错误
          console.error('上传失败', err);
          Toast('上传失败，请联系系统管理员处理')
        },
        complete(res) {
          // 上传完成
          console.log('上传成功', that.upload_qiniu_addr + res.key);
          //Toast('上传成功')
          file.message = '';
          file.status = 'complete';
          // 这里可以获取到云存储中的图片地址，可以用于展示或其他用途
          // 通常情况下，res.key 就是你的图片名
          that.imageList.push(that.upload_qiniu_addr + res.key)
        }
      });
    }
  }
}
</script>
<style scoped>

</style>