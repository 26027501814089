<template>
    <div style="background: #f7f8fd;">
        <div>
            <!--      <van-image contain width="100%" :style="{'height':swipeHeight}" src="https://img.yzcdn.cn/vant/cat.jpeg"/>-->
            <van-image :style="{'height':swipeHeight,'width':imageWidth}" fit="fill" :src=clinicInfo.thumb
                       style="z-index: 1;position: static;width: 100%; "/>
        </div>

        <div
                style="background: white;height: 280px; margin: 15px 10px ;   "
                :style="{'width':mainWidth}">
            <div class="van-multi-ellipsis--l2"
                 style="font-size: large;font-weight: 650;color: #565656;padding: 20px 15px ;text-align: left">
                {{ clinicInfo.title }}
            </div>

            <div style="width: 100%;">
                <div style="width: 100%;float:left;">
                    <div class="van-multi-ellipsis--l2"
                         style="font-size: medium;color: #565656;margin-left:15px;text-align: left;line-height: 30px;">
                        上班时间：<br> {{ clinicInfo.workingHours }}
                    </div>

                    <div class="van-multi-ellipsis--l2"
                         style="font-size: medium;color: #565656;margin-left:15px;text-align: left;line-height: 30px; ">
                        机构地址：<br>

                        <span style="width: 90%">{{ clinicInfo.address }} </span>

                        <div style="width: 25px;height: 30px;float: right;vertical-align: middle" @click="openMap">
                            <van-icon name="location" size="18"/>
                        </div>
                    </div>

                    <div class="van-multi-ellipsis--l2"
                         style="font-size: medium;color: #565656;margin-left:15px;text-align: left;line-height: 30px;">
                        机构电话：<br>

                        <span style="width: 90%">{{ clinicInfo.telephone }}</span>
                        <div style="width: 25px;height: 30px;float: right;vertical-align: middle"
                             @click="call(clinicInfo.telephone)">
                            <van-icon name="phone" size="18"/>
                        </div>
                    </div>
                </div>
            </div>

            <!--<van-image-preview v-model="showImagePreview " :images="images" @change="onChange">
              &lt;!&ndash;<template v-slot:index>第{{ index }}页</template>&ndash;&gt;
            </van-image-preview>-->

        </div>
        <!--<div
            style="text-align: left;margin-left: 15px;font-weight: 550;font-size: large;margin-top: 10px; padding-top: 10px;align-items: center;line-height: 30px;">
          机构资质
        </div>
        <div style="background: white;height: 160px;">
          <div style="float: left;padding : 20px;">
            <van-image
                width="100"
                height="100"
                @click="showImage(credentials1)"
                :src=credentials1
            />
            <div>
              营业执照
            </div>
          </div>

          <div style="float: left;padding : 20px;">
            <van-image
                width="100"
                height="100"
                @click="showImage(credentials2)"
                :src=credentials2
            />
            <div>
              医疗执业许可证
            </div>
          </div>
        </div>-->

        <div v-if="clinicInfo.description != null">


            <div
                    style="text-align: left;margin-left: 15px;font-weight: 550;font-size: large;margin-top: 10px; padding-top: 10px;align-items: center;line-height: 30px;">
                机构简介
            </div>
            <div style="background: white;text-align: left;min-height: 100px;">
                <div style="margin-left: 20px;margin-right: 20px;">
                    {{ clinicInfo.description }}
                </div>

            </div>
        </div>
        <div style="height: 180px;width: 80%;">
            <!--占位-->
        </div>
        <van-divider/>
    </div>
</template>
<div>

</div>
<script>
import Vue from 'vue';
import {Swipe, SwipeItem} from 'vant';
import {Lazyload} from 'vant';
import {Grid, GridItem} from 'vant';
import {Tabbar, TabbarItem} from 'vant';
import {Image as VanImage} from 'vant';
import {ImagePreview} from 'vant';
import {Icon} from 'vant';
import {Tab, Tabs} from 'vant';
import axios from "axios";
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);

export default {
    name: "clinicInfo",
    components: {
        [ImagePreview.Component.name]: ImagePreview.Component,
    },
    data() {
        return {
            showImagePreview: false,
            imageWidth: '100%',
            index: 0,
            images: [],
            clinicInfo: [],
            credentials1: '',
            credentials2: ''
        };
    },
    mounted() {
        //axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
        this.getClinicInfo();
    },
    methods: {
        showImage(url) {
            ImagePreview([url]);
        },
        openMap() {
            this.comFun.openMap(this.clinicInfo);
        },
        call(phone) {
            //打电话
            //window.open('tel:10086', '_blank')
            window.location.href = 'tel:' + phone;
        },
        onChange(index) {
            this.index = index;
        },
        //获取机构详情
        /*getClinicInfo() {
          let that = this;
          axios.get('/api/company/'+ localStorage.getItem('goClinicId'))
              .then(function (response) {
                //that.images.splice(0);//清空
                that.clinicInfo = response.data.data;
                /!*that.images.push(response.data.data.business_license)
                that.images.push(response.data.data.medical_license)*!/
                that.credentials1 = response.data.data.business_license;
                that.credentials2 = response.data.data.medical_license;

                /!*let arr = response.data.data;
                for (let i = 0; i < arr.length; i++) {
                  that.images.push(arr[i].thumb);
                  console.log(arr[i].thumb);
                }*!/
              })
              .catch(function (error) {
                console.log(error);
              });
        },*/
        //机构信息
        getClinicInfo() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/companies/info', {
                id: userInfo.companyId
            })
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                    that.credentials1 = response.data.data.businessLicense;
                    that.credentials2 = response.data.data.medicalLicense;

                    localStorage.setItem('companyName', response.data.data.title)
                    that.document.title = response.data.data.title;


                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    computed: {
        swipeHeight: function () {
            let width = document.documentElement.clientWidth;//屏幕宽
            return 0.6 * width + 'px';
        },
        mainWidth: function () {
            let width = document.documentElement.clientWidth;//屏幕宽
            return (width - 20) + 'px';
        },
        recommendHeight: function () {
            let width = document.documentElement.clientWidth;//屏幕宽
            return 0.3 * width + 'px';
        },
        screenHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕宽
            return (100 + height) + 'px';
        }
    }
}
</script>

<style scoped>
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
}

.van-grid-item-text {
    font-size: medium;
    color: #2c3e50;
}
</style>