<template>
  <div>
    <div style="width: 100%; ">
      <van-search
          v-model="value"
          shape="round"
          background="#ffffff"
          placeholder="搜索城市"
      />
      <div style="width: 30px;height: 50px;float: right;margin-right: 6px;">
        <span style="line-height: 50px;font-size: 14px;" @click="goBack">取消</span>
      </div>

    </div>


    <div style="margin-top: 30px;float: left; width: 100%;height: 30px;margin-left: 5px;text-align: left;font-size: medium;">
      <span style="width: 90px;color: #4a4a4a;font-size: 14px;">当前定位：</span>
      <span class="location-span">
        <van-icon name="location-o"/>
        <span class="location-text" @click="setLocationCity()">{{locationCity}}</span>
      </span>
    </div>


    <div style="width: 35%;float: left;">

      <van-sidebar  v-model="activeKey" @change="onChange">
        <span v-for="(item, index) in provinces" :key="index" >
          <van-sidebar-item :title= item.name />
        </span>

      </van-sidebar>

    </div>

    <div style="background: rgba(237,242,244,.5);;width: 100%;height: 100%;align-items: center;text-align: center;">

       <span v-for="(item, index) in cities" :key="index" >
          <span class="city" @click="chooseCity(item)">{{item.name}}</span>
       </span>

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Sidebar, SidebarItem } from 'vant';
import axios from "axios";

Vue.use(Sidebar);
Vue.use(SidebarItem);
export default {
  name: "Area",
  data() {
    return {
      activeKey: 0,
      provinces:[],
      cities:[],
      curCity:'',
      locationCity:''
    };
  },
  mounted() {
    this.curCity = localStorage.getItem('chooseCityName') ?? '广州市'
    this.getProvinceList();
    this.locationCity = localStorage.getItem('locationCity')
  },
  methods: {
    setLocationCity(){
      //选择定位城市
      localStorage.setItem('chooseCityName',localStorage.getItem('locationCity'));
      localStorage.setItem('chooseCityId',localStorage.getItem('locationCityId'));
      this.$router.back();
    },
    goBack(){
      this.$router.back();
    },
    chooseCity(item) {
      localStorage.setItem('chooseCityName',item.name);
      localStorage.setItem('chooseCityId',item.id);
      this.$router.back();
    },
    onChange(index) {
      console.log('index = ' + index)
      this.getCityList(this.provinces[index].id)
    },
    //获取省列表
    getProvinceList() {
      let that = this;
      axios.get('/api/region',{
        params: {
          "level":1
        }
      })
          .then(function (response) {
            that.provinces = response.data.data;
            that.getCityList(that.provinces[0].id)
          })
          .catch(function (error) {
            console.log(error);
          });
    },//获取城市列表
    getCityList(provinceId) {
      let that = this;
      axios.get('/api/region',{
        params: {
          "level":2,
          "parent_ids":provinceId
        }
      })
          .then(function (response) {
            that.cities = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
},
}
</script>

<style scoped>
  .city {
    text-align: center;
    font-size: medium;
    color: #8d99ae;
    width: 4rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 2px;
    background: #edf2f4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    margin: 5px;
    padding: 10px 15px;
  }

  .van-search {
    float: left;
    width:85%;
  }
  .van-sidebar {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .location-span {
    text-align: center;
    background: white;
    border-radius: 11px;
    font-size: 14px;
    border: 1px solid #bcbcbc;
    /*background:rgba(250,250,250,0.5);*/
    padding: 2px 5px;

    /*align-items: center;*/
  }
</style>