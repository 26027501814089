<template>
    <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
        <div style="height: 320px;background: white;">

            <div style="padding: 20px;">
                <span style="float: left;font-weight: 550;font-size: medium">{{ vaccineInfo.title }}</span>
                <!--<span style="text-align: right;float: right;color: red;">￥235.00</span>-->
            </div>
            <van-divider/>

            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small"></span>
                <span
                        style="text-align: left;float: right; font-size: small;color: #565656;">{{
                    vaccineInfo.description
                    }}</span>
            </div>
            <!--      <div style="height: 30px;padding: 0 20px;">
                    <span style="float: left;color: #bcbcbc;font-size: small">产品规格</span>
                    <span
                        style="text-align: right;float: right; font-size: small;color: #565656;">{{
                        productInfo.specifications
                      }}</span>
                  </div>-->
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">检测人</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{ familyName }}</span>
            </div>
            <!--      <div style="height: 30px;padding: 0 20px;">
                    <span style="float: left;color: #bcbcbc;font-size: small">针次</span>
                    <span style="text-align: right;float: right; font-size: small;color: #565656;">{{ injectionText }}</span>
                  </div>-->
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">预约时间</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{ dateTime }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">检测门诊</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{
                    clinicInfo.title
                    }}</span>
            </div>

            <div style="padding: 20px;background: white;">
                <span style="float: left;font-weight: 550;font-size: medium">费用详情</span>
            </div>
            <van-cell-group :border="false">
                <van-cell title="检测费用" label="检测费用到门诊现场支付" :value="vaccinePrice"/>
            </van-cell-group>
        </div>

        <div class="submit-bar">
            <van-button @click="submit" color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 150px; margin: 10px 20px;">
                确认提交
            </van-button>
        </div>
    </div>

</template>

<script>
import Vue from 'vue';
import {Cell, CellGroup, Dialog} from 'vant';
import {GoodsAction, GoodsActionIcon, GoodsActionButton} from 'vant';
import {Toast} from 'vant';
import axios from "axios";

Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Cell);
Vue.use(CellGroup);
export default {
    name: "nucleinStep3",
    data() {
        return {
            vaccineInfo: [],
            vaccinePrice: '',
            clinicInfo: [],
            dateTime: '',//接种时间
            familyName: '',
            injectionText: '1',
        };
    },
    computed: {
        swipeHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕高
            return height + 'px';
        }
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");

        // this.getClinicInfo();
        // this.getVaccineInfo();

        this.vaccineInfo = JSON.parse(localStorage.getItem('productInfo'));
        this.vaccinePrice = this.vaccineInfo.price

        this.dateTime = localStorage.getItem('chooseDate') + ',' + localStorage.getItem('chooseTime');
        this.familyName = localStorage.getItem('familyName');
        this.injectionText = localStorage.getItem('injectionText');
    },
    methods: {

        submit() {
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            let that = this;
            axios.post('/items/reserve', {
                "productId": localStorage.getItem('chooseNucleinId'),
                "companyId": userInfo.companyId,
                "userId": userInfo.id,
                "name": localStorage.getItem('familyName'),
                "phone": localStorage.getItem('familyPhone'),
                "gender": localStorage.getItem('familyGender'),
                "birthday": localStorage.getItem('familyBirthday') === 'null' ? '' : localStorage.getItem('familyBirthday'),
                "identityCard": localStorage.getItem('familyIdentityCard') === 'null' ? '' : localStorage.getItem('familyIdentityCard'),
                "provinceId": localStorage.getItem('familyProvinceId'),
                "cityId": localStorage.getItem('familyCityId'),
                "regionId": localStorage.getItem('familyRegionId'),
                "address": localStorage.getItem('familyAddress') === 'null' ? '' : localStorage.getItem('familyAddress'),
                "modelId": '0',
                "date": localStorage.getItem('chooseDate'),
                "time": localStorage.getItem('chooseTime'),
                "injectionTotal": '0'
            })
                .then(function (response) {
                    //console.log('预约结果')
                    //console.log(JSON.stringify(response.data))
                    if (response.data.status === 200) {
                        that.$router.replace({name: 'reSuccess', query: {id: response.data.data.id}})
                    } else {
                        Dialog.alert({
                            message: response.data.message,
                            messageAlign: 'left',
                            confirmButtonColor: 'black'
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Dialog.alert({
                        message: JSON.stringify(error),
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    });
                });

        },
        onClickIcon() {
            Toast('点击图标');
        },
        onClickButton() {
            Toast('点击按钮');
        },
        //疫苗信息
        /*getVaccineInfo() {
          let that = this;
          axios.defaults.headers.common.Authorization = localStorage.getItem("token");
          axios.post('/items/info', {
                id: localStorage.getItem('chooseNucleinId')
              }
          )
              .then(function (response) {
                //that.vaccineInfo = response.data.data.vaccine;
                that.productInfo = response.data.data;
                //that.vaccineInfo = response.data.data;
                that.vaccinePrice = '￥' + response.data.data.price;
              })
              .catch(function (error) {
                console.log(error);
              });
        },*/
        //机构信息
        getClinicInfo() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.get['Accept'] = 'application/json'
            axios.defaults.headers.get['ContentType'] = 'application/json'
            axios.defaults.headers.common.Authorization = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZCIsImlzcyI6ImdkIiwiZXhwIjoxNjU4NzM5NzIxLCJpYXQiOjE2NTc4NzU3MjEsInJvbCI6IjEifQ.nM1kd5u8PGNFu75cwLkMY2cO1_4P020UXxpsQOa33f--mli_0ajcRq2PIYwDVYx872p3XFHmvrk9KTX5bjozqg';
            axios.post('/companies/info', {
                    id: userInfo.companyId
                }
            )
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
}
</script>

<style scoped>
.van-collapse-item {
    text-align: left;
}

.van-cell__title {
    text-align: left;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}
</style>