<template>
    <div>
        <van-cell-group>
            <van-field label="宝宝小名" input-align="right" v-model="name" placeholder="请输入"/>
            <van-field label="宝宝生日" input-align="right" v-model="birthday" @focus="showBirthday=true"
                       placeholder="请选择"/>
            <van-cell title="宝宝性别" input-align="right" v-model="gender">
                <div class="btnGroup">
                    <button
                        v-for="(item, index) in genders"
                        :key="index"
                        @click="selectGender(index)"
                        :class="activeIndex1 === index ? 'active' : ''"
                    >
                        {{ item.title }}
                    </button>
                </div>
            </van-cell>


            <van-cell title="我是宝宝的" input-align="right" v-model="relation">
                <div class="btnGroup">
                    <button
                        v-for="(item, index) in relations"
                        :key="index"
                        @click="selectRelation(index)"
                        :class="activeIndex2 === index ? 'active' : ''"
                    >
                        {{ item.title }}
                    </button>
                </div>
            </van-cell>
        </van-cell-group>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
        </van-popup>
        <van-popup v-model="showBirthday" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                @confirm="confirmDate"
                @cancel="showBirthday = false"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
            />

        </van-popup>
        <van-button @click="saveSetting" style="width: 80%;margin:35px 10%;" type="primary" block>确认</van-button>
    </div>
</template>

<script setup>
import {formatDate} from "@/utils/date";
import axios from "axios";
import {Dialog} from "vant";

export default {
    name: "parentingSetting",
    data() {
        return {
            genders: [{"title": "男孩"}, {"title": "女孩"}],
            relations: [{"title": "妈妈"}, {"title": "爸爸"}, {"title": "其他"}],
            activeIndex1: [],
            activeIndex2: [],
            showBirthday: false,
            currentDate: '',
            minDate: new Date(2018, 0, 1),
            maxDate: new Date(),
            name: '',
            birthday: '',
            gender: '',
            relation: '',
            showPicker: false,
            columns: ['奶奶', '外婆', '爷爷', '外公', '姥姥', '姥爷'],
            userInfo: [],
        }
    },
    mounted() {
        document.title = '设置宝宝信息'
        this.getInfo()
    },
    created() {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));
    },
    methods: {
        showPickers(value) {
            console.log('onConfirm(value) => ' + value)
        },
        onConfirm(value) {
            console.log('onConfirm(value) => ' + value)
            this.relation = value;
            this.relations[2].title = value;
            this.showPicker = false;
        },
        onCancel() {

        },
        getInfo() {
            let that = this;
            axios.post('/parenting/child', {
                "companyId": this.userInfo.companyId,
                "userId": this.userInfo.id,
            })
                .then(function (response) {
                    console.log(response)
                    that.name = response.data.data.name;
                    that.gender = response.data.data.gender == 1 ? '男孩' : '女孩';
                    that.birthday = response.data.data.birthday;
                    if (response.data.data.gender == 1) {
                        that.selectGender(0)
                    }
                    if (response.data.data.gender == 0) {
                        that.selectGender(1)
                    }
                    if (response.data.data.relation == '妈妈') {
                        that.selectRelation(0)
                    } else if (response.data.data.relation == '爸爸') {
                        that.selectRelation(1)
                    } else {
                        that.relation = response.data.data.relation;
                        that.relations[2] = {"title": response.data.data.relation};
                        that.activeIndex2 = 2
                    }
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
        confirmDate(value) {
            this.birthday = formatDate(value, 'yyyy-MM-dd');
            this.showBirthday = false
        },
        selectGender(index) {
            // alert(index + " - " + index2);
            this.activeIndex1 = index
            this.gender = this.genders[index].title
        },
        selectRelation(index) {
            this.activeIndex2 = index
            this.relation = this.relations[index].title
            if (index === 2) {
                this.showPicker = true;
            }
        },
        saveSetting() {
            let that = this;
            if (this.name.length === 0) {
                this.showTip('请输入宝宝小名');
                return;
            }
            if (this.birthday.length === 0) {
                this.showTip('请选择宝宝生日');
                return;
            }
            if (this.gender.length === 0) {
                this.showTip('请选择宝宝性别');
                return;
            }
            if (this.relation.length === 0) {
                this.showTip('请选择与宝宝的关系');
                return;
            }
            axios.post('/parenting/setting', {
                "companyId": this.userInfo.companyId,
                "userId": this.userInfo.id,
                "name": this.name,
                "gender": this.gender === '男孩' ? '1' : '0',
                "birthday": this.birthday,
                "relation": this.relation,
            })
                .then(function (response) {
                    console.log(response)
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        that.$router.replace({
                            name: 'parentingHome',
                            query: {gender: that.gender === '男孩' ? '1' : '0', birthday: that.birthday,relation:that.relation,name:that.name}
                        })
                        //that.$router.back();
                    });
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
        showTip(value) {
            Dialog.alert({
                message: value,
                messageAlign: 'left',
                confirmButtonColor: 'black'
            }).then(() => {
                // on close
                //if (response.data.code === 200)
                //
            });
        }

    }
}
</script>

<style scoped>

</style>
<style lang="less" scoped>
.van-cell__title, .van-cell__value {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    flex: auto;
}

.btnGroup {
    button {
        height: 25px;
        font-size: 15px;
        margin: 0 6px;
        padding: 0 10px;
        background-color: #EEEEEE;
        border: 1px solid white;
        border-radius: 15px;
    }

    // 被选中的按钮的样式
    .active {
        //background-color: red;
        border: rgb(106, 199, 132);
        color: rgb(106, 199, 132);

    }
}
</style>