<template>
    <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
        <div style="height: 450px;background: white;">

            <div style="padding: 20px;">
                <span style="float: left;font-weight: 550;font-size: medium">{{ JSON.parse(detail.remarks).vaccine.title }}</span>
                <!--<span style="text-align: right;float: right;color: red;">￥235.00</span>-->
            </div>
            <van-divider/>

            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">生产厂家</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{
                        JSON.parse(detail.remarks).vaccine.manufacturer
                    }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">产品规格</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{
                        JSON.parse(detail.remarks).vaccine.specifications
                    }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">接种人</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{
                        detail.name
                    }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">针次</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{
                        detail.injectionTotal
                    }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">接种时间</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">{{
                        detail.date
                    }}，{{ detail.time }}</span>
            </div>

            <van-cell-group :border="false">
                <van-cell title="疫苗费用" label="疫苗费用到门诊现场支付" :value="vaccinePrice"/>
            </van-cell-group>


            <div style="background: white;margin-top: 10px;">
                <van-cell title="接种日期" icon="notes-o" :border="false" is-link :value="chooseDate"
                          @click="showPopup"/>

                <div style="margin: 0 30px;">
                    <van-calendar v-model="show" :formatter="formatter" :default-date=null title="选择接种日期"
                                  :min-date="minDate" :max-date="maxDate" :round="false" position="right"
                                  :show-confirm="false"
                                  @select="selectDate"/>
                </div>
            </div>

            <div style="background: white;margin-top: 10px;">
                <van-cell title="接种时间" icon="clock-o" :border="false" is-link :value="chooseTime"
                          @click="showActionSheet = true"/>
                <van-action-sheet :round="false" v-model="showActionSheet" :actions="actions" @select="onSelect"
                                  cancel-text="取消"
                                  description="请选择接种时间"/>

            </div>
            <div style="height: 5rem;"></div>


        </div>

        <div class="submit-bar">
            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 150px; margin: 10px 20px; " @click="confirmEdit">
                确认修改
            </van-button>
        </div>
    </div>

</template>

<script>
import Vue from 'vue';
import {Calendar, Cell, CellGroup, Dialog} from 'vant';
import {GoodsAction, GoodsActionIcon, GoodsActionButton} from 'vant';
import {Toast} from 'vant';
import axios from "axios";
import {formatDate} from "@/utils/date";

Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Cell);
Vue.use(Calendar);
Vue.use(CellGroup);
export default {
    name: "reserveEdit",
    data() {
        return {
            /*vaccineInfo: [],
            vaccinePrice:'',
            clinicInfo: [],
            dateTime:'',//接种时间
            familyName:'',
            injectionText:'',*/
            vaccinePrice: '',
            detail: [],
            show: false,
            showActionSheet: false,
            actions: [],
            chooseDate: '',
            chooseTime: '',
            minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
            maxDate: new Date(new Date().setDate(new Date().getDate() + 15)),
            //可以预约的日期
            curNums: [],
        };
    },
    beforeCreate() {
        //进入页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #f7f8fd');
    },

    beforeDestroy() {
        //退出页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #fff');
    },
    computed: {
        swipeHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕高
            return height + 'px';
        }
    },
    mounted() {
        document.title = Vue.platformTitle;
        //axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
        this.getOrderInfo(this.$route.query.id)
        //工作时间
        //this.getWorkHours()
        this.getDays();
    },
    methods: {
        submit() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/orders/save', {
                "id": this.$route.query.id,
                "productId": this.$route.query.productId,
                "companyId": userInfo.companyId,
                "userId": userInfo.id,
                "name": localStorage.getItem('familyName'),
                "phone": localStorage.getItem('familyPhone'),
                "gender": localStorage.getItem('familyGender'),
                "birthday": localStorage.getItem('familyBirthday') === 'null' ? '' : localStorage.getItem('familyBirthday'),
                "identityCard": localStorage.getItem('familyIdentityCard') === 'null' ? '' : localStorage.getItem('familyIdentityCard'),
                "provinceId": localStorage.getItem('familyProvinceId'),
                "cityId": localStorage.getItem('familyCityId'),
                "regionId": localStorage.getItem('familyRegionId'),
                "address": localStorage.getItem('familyAddress') === 'null' ? '' : localStorage.getItem('familyAddress'),
                "modelId": localStorage.getItem('chooseProductId'),
                "date": this.chooseDate,
                "time": this.chooseTime,
                "injectionTotal": localStorage.getItem('injectionValue')
            })
                .then(function (response) {
                    //console.log('预约结果')
                    //console.log(JSON.stringify(response.data))
                    if (response.data.status === 200) {
                        that.$router.replace({name: 'reSuccess', query: {id: response.data.data.id}})
                    } else {
                        Dialog.alert({
                            message: response.data.message,
                            messageAlign: 'left',
                            confirmButtonColor: 'black'
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Dialog.alert({
                        message: JSON.stringify(error),
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    });
                });

        },
        //约满日期
        getDays() {
            /*let that = this;
            axios.get('/api/vaccine/product/days/' + this.$route.query.productId
            )
                .then(function (response) {
                  that.curNums = response.data.data;
                  that.maxDate = new Date(new Date().setDate(new Date().getDate() +  response.data.data.max_appointment_days ))
                })
                .catch(function (error) {
                  console.log(error);
                });*/

            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('products/days', {
                    "companyId": userInfo.companyId,
                    "productId": this.$route.query.productId //localStorage.getItem('chooseProductId')
                }
            )
                .then(function (response) {
                    console.log('约满日期')
                    console.log(JSON.stringify(response.data.data))
                    // console.log(response.data.data.max_appointment_days)

                    that.curNums = response.data.data;
                    //that.maxDate = new Date(new Date().setDate(new Date().getDate() - 1 + response.data.data.max_appointment_days))
                    that.maxDate = new Date(new Date().setDate(new Date().getDate() - 1 + response.data.data.maxAppointmentDays))
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        dataLeftCompleting(bits, identifier, value) {
            value = Array(bits + 1).join(identifier) + value;
            return value.slice(-bits);
        },
        // 日期添加备注
        formatter(day) {
            // 当前月份的日
            //let date = day.date.getDate()
            console.log("this.curNums.date.length  = " + this.curNums.date.length)
            console.log("day  = " + JSON.stringify(day))
            console.log("new Date(this.curNums.date[i]  = " + this.curNums.date[0])
            let date = day.date;
            let dateFormat = date.getFullYear() + '-' + this.dataLeftCompleting(2, 0, (date.getMonth() + 1)) + '-' + this.dataLeftCompleting(2, 0, date.getDate());
            console.log("new Date(this.curNums.date[i]  = " + date.getFullYear() + '-' + this.dataLeftCompleting(2, 0, (date.getMonth() + 1)) + '-' + this.dataLeftCompleting(2, 0, date.getDate()))
            for (let i = 0; i < this.curNums.date.length; i++) {
                //只判断可选的日期
                if (day.type != 'disabled') {
                    day.type = this.filterDate(dateFormat, this.curNums.date) ? 'able' : 'disabled';
                    // 判断预约次数是否为0
                    if (this.curNums.date[i] == dateFormat) {
                        // 日期添加备注
                        day.topInfo = '满'
                        day.type = 'disabled'
                    }
                }
            }
            return day
        },
        //跳过后台没有返回的日期
        filterDate(date, dateList) {
            //let i = 0;
            for (let i = 0; i < dateList.length; i++) {
                if (dateList[i].date == date) {
                    return true;
                }
            }
            return false;
        },
        confirmEdit() {
            if(this.date === '' || this.time === '') {
                Dialog.alert({
                    message: '请选择接种日期和接种时间',
                    messageAlign: 'left'
                })

                return;
            }
            Dialog.confirm({
                message: '确认要修改预约信息吗？提交后无法重新修改。',
                messageAlign: 'left'
            })
                .then(() => {
                    // on confirm
                    this.submit()
                })
                .catch(() => {
                    // on cancel
                });
        },

        onClickIcon() {
            Toast('点击图标');
        },
        onClickButton() {
            Toast('点击按钮');
        },
        //订单信息
        getOrderInfo(id) {

            let that = this;
            let appid = localStorage.getItem('appid');
            axios.post('/orders/info', {
                id: id
            })
                .then(function (response) {
                    const userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
                    if (response.data.data.userId !== userInfo.id) {
                        Dialog.alert({
                            message: '非本人订单,此订单姓名为:' + response.data.data.name,
                            messageAlign: 'left'
                        })
                        that.operate = false
                    }
                    that.detail = response.data.data;
                    that.vaccinePrice = '￥' + that.detail.price;
                    that.chooseDate = that.detail.date;
                    that.chooseTime = that.detail.time;

                    if(that.detail.date === '' || that.detail.time === '') {
                        Dialog.alert({
                            message: '请选择接种日期和接种时间，并确认修改',
                            messageAlign: 'left'
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showPopup() {
            this.show = true;
        },
        selectDate(date) {

            /*console.log(formatDate(date, 'yyyy-MM-dd'))
            this.chooseDate = formatDate(date, 'yyyy-MM-dd');
            this.show = false;*/

            console.log(formatDate(date, 'yyyy-MM-dd'))
            this.chooseDate = formatDate(date, 'yyyy-MM-dd');
            this.show = false;
            let that = this;
            that.chooseTime = '';
            for (let item of this.curNums.date) {
                // 判断预约次数是否为0
                if (item.date == this.chooseDate) {
                    that.actions = [];
                    for (let times of item.times) {
                        if (times.maxTotal == times.currentTotal)
                            that.actions.push({name: times.title + '(约满)', disabled: true});
                        else
                            that.actions.push({name: times.title + ' 剩余(' + (0 + times.maxTotal - times.currentTotal) + ')'});
                    }

                }
            }
        },
        onSelect(item) {
            // 默认情况下点击选项时不会自动收起
            // 可以通过 close-on-click-action 属性开启自动收起
            this.showActionSheet = false;
            this.chooseTime = item.name.substring(0, item.name.indexOf(' 剩余'));
            //Toast(item.name);
        },
        //机构信息
        /* getClinicInfo() {
           let that = this;
           axios.get('/api/company/' + localStorage.getItem('chooseClinicId')
           )
               .then(function (response) {
                 that.clinicInfo = response.data.data;
               })
               .catch(function (error) {
                 console.log(error);
               });
         },*/
    },
}
</script>

<style scoped>
.van-grid-item__content {
    padding: 16px 1px;
}

.van-collapse-item {
    text-align: left;
}

.van-cell__title {
    text-align: left;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}
</style>