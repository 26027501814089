<template>
  <div :style="{'height':swipeHeight}"  style="background: #f7f8fd;width: 100%;">
    <span class="location-span">
<!--         <van-icon name="location-o"/>-->
         <span class="location-text" @click="familyList">家庭成员▶</span>
       </span>
    <div style="height: 100px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">
      <van-image
          style="margin-left: 30px;margin-top: 15px;float: left;margin-right: 20px;"
          round
          width="60px"
          height="60px"
          :src=userInfo.avatar
      />

      <div style="line-height: 100px;padding-left: 20px;font-size: medium;font-weight: 550;">
        {{userInfo.name}}
      </div>
    </div>

    <div style="margin: 15px;border-radius: 10px;background: white;">
      <van-sticky :offset-top="40">
        <div
            style="text-align: left;margin-left: 15px;font-weight: 550;font-size: medium;padding-top: 10px;align-items: center;line-height: 30px;">
          我的订单
        </div>
      </van-sticky>


      <van-grid :border="false" :column-num="4">

        <van-grid-item @click="goReserve">
          <van-image width="60%" src="http://vaccine.images.wxdev.top/%E6%88%91%E7%9A%84%E9%A2%84%E7%BA%A61.png"/>
          <span class="van-grid-item-text">我的预约</span>
        </van-grid-item>

        <van-grid-item to="/doctorOrder">
          <van-image width="60%" src="http://vaccine.images.wxdev.top/%E6%88%91%E7%9A%84%E8%AE%A2%E5%8D%951.png"/>
          <span class="van-grid-item-text">签约订单</span>

        </van-grid-item>

      </van-grid>

    </div>


    <div style="margin: 15px;border-radius: 10px;background: white;">
      <van-sticky :offset-top="40">
        <div
            style="text-align: left;margin-left: 15px;font-weight: 550;font-size: medium;padding-top: 10px;align-items: center;line-height: 30px;">
          机构服务
        </div>
      </van-sticky>


      <van-grid :border="false" :column-num="4">

        <van-grid-item to="/noticeList">
          <van-image width="55%" src="http://vaccine.images.wxdev.top/门诊消息通知.png"/>
          <span class="van-grid-item-text">消息公告</span>

        </van-grid-item>

        <van-grid-item to="subscribeList">
          <van-image width="60%" src="http://vaccine.images.wxdev.top/门诊我的订阅.png"/>
          <span class="van-grid-item-text">我的订阅</span>

        </van-grid-item>

        <van-grid-item to="DefaultClinic">
          <van-image width="60%" src="http://vaccine.images.wxdev.top/机构2.png"/>
          <span class="van-grid-item-text">机构设置</span>

        </van-grid-item>

        <van-grid-item to="scan">
          <van-image width="50%" src="http://vaccine.images.wxdev.top/扫一扫.png"/>
          <span class="van-grid-item-text" style="margin-top: 5px;">扫码确认</span>
        </van-grid-item>

        <van-grid-item @click="customerService">
          <van-image width="60%" src="http://vaccine.images.wxdev.top/%E8%81%94%E7%B3%BB%E5%AE%A2%E6%9C%8D.png"/>
          <span class="van-grid-item-text" >联系客服</span>
        </van-grid-item>

      </van-grid>



    </div>

    <van-tabbar route v-model="active">
      <van-tabbar-item replace to="/clinic" icon="hotel-o">
        机构首页
      </van-tabbar-item>
      <van-tabbar-item replace to="/article" icon="newspaper-o">
        健康资讯
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="friends-o">
        个人中心
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue';
import {Cell, CellGroup, Dialog} from 'vant';
import axios from "axios";

Vue.use(Cell);
Vue.use(CellGroup);
export default {
  name: "my",
  data() {
    return {

      active: 'my',
      userInfo:''

    };
  },
  computed: {
    swipeHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕高

      return height + 'px';
    }
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    this.getUserInfo();
  },
  methods: {
    scanConfirm(){

    },
    customerService () {
      Dialog.alert({
        message: '请添加客服微信[]，或直接在公众号发送消息。',
        messageAlign: 'left'
      })

    },
    goReserve () {
      this.$router.push('reserveList')
    },
    familyList() {
      this.$router.push('familyList')
    },
    getUserInfo() {
      let that = this;
      axios.defaults.headers.get['Accept'] = 'application/json'
      axios.get('/api/user')
          .then(function (response) {
            //alert('获取用户信息成功 ：' + JSON.stringify(response))
            console.log(JSON.stringify(response))
            //localStorage.setItem('userInfo' + appid, response.data.data)
            that.userInfo = response.data.data;
          })
          .catch(function (error) {
            //alert("获取用户信息失败22：" + JSON.stringify(error));
            console.log("获取用户信息失败22：" + JSON.stringify(error));
            //返回首页
            this.$router.replace({name: 'index'})

          });
    },
  }
}
</script>

<style scoped>
.van-cell__title, .van-cell__value {
  text-align: start;
}

.van-grid-item-text {
  font-size: small;
  color: #2c3e50;
}

.location-text {
  color: #565656;
  font-size: x-small;
  height: 100%;
  color: dodgerblue;
  /*vertical-align: middle;*/
}

.location-span {
  position: absolute;
  top: 38px;
  left: 75%;
  text-align: center;
  background: white;
  border-radius: 11px;
  float: right;
  border: 1px solid dodgerblue;
  /*background:rgba(250,250,250,0.5);*/
  padding: 2px 7px;
  z-index: 100;
  /*align-items: center;*/
}

.van-icon-location-o {
  font-size: x-small;
  vertical-align: middle;
}
</style>