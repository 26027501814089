<template>
    <div>
        <!--<div style="border-radius: 8px;border: 1px #eee solid;height: 90px;margin: 20px;box-shadow:2px 2px 5px #eee;">

          <div style="margin: 20px;width: 50%;float: left;height: 70px;text-align: left;vertical-align: center;">
            <div style=" ">廖马宝
              <span style="font-size: 8px;background: #78e9fe;color: #39a9ed;border-radius: 2px;padding: 1px 5px;">默认</span>
            </div>

            <div style="font-size: small;color: #565656;text-align: left;">本人/男 1987-01-28</div>
          </div>
          <div style="width: 20%;height: 90px;float: right;vertical-align: middle">
            <van-icon name="edit" style="margin-top: 35px;"/>
          </div>
        </div>-->

        <div v-for="(item, index) in familyList" :key="index"
             style="border-radius: 8px;border: 1px #eee solid;height: 90px;margin: 20px;box-shadow:2px 2px 5px #eee;">

            <div style="margin: 20px;width: 50%;float: left;height: 70px;text-align: left;vertical-align: center;">
                <div style=" ">
                    {{ item.name }}
                </div>

                <div style="font-size: small;color: #565656;text-align: left;">{{ item.relationship }}/
                    {{ item.gender === 1 ? '男' : '女' }} {{ item.birthday }}
                </div>
            </div>
            <div style="width: 10%;height: 90px;float: right;vertical-align: middle" @click="chooseFamily(item)">
                <span style="margin-top: 35px;">选择</span>
            </div>
            <div style="width: 10%;height: 90px;float: right;vertical-align: middle" @click="familyEdit(item)">
                <van-icon name="edit" style="margin-top: 35px;"/>
            </div>
        </div>

        <van-empty v-if="familyList.length === 0" description="暂无数据">
        </van-empty>

        <van-divider/>
        <div style="height: 35px;text-align: center;">
            <van-button icon="plus" plain type="info" to="/familyAdd" size="small" style="font-size: 14px;">新增家庭成员</van-button>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import {Dialog} from 'vant';
import axios from "axios";

// 全局注册
Vue.use(Dialog);
export default {
    name: "FamilyList",
    data() {
        return {
            familyList: [],
        };
    },
    mounted() {
        this.getFamily();
    },
    methods: {
        //编辑
        familyEdit(item) {
            localStorage.setItem('familyEditInfo', JSON.stringify(item))
            this.$router.push('familyEdit')
        },
        chooseFamily(familyInfo) {
            let that = this;
            if (familyInfo.identityCard == null || familyInfo.identityCard.length < 15) {
                Dialog.alert({
                    message: '请先完善预约人的身份证号信息',
                    messageAlign: 'left',
                }).then(() => {
                    // on confirm
                    localStorage.setItem('familyEditInfo', JSON.stringify(familyInfo))
                    that.$router.push('familyEdit')
                })
            }
            localStorage.setItem('familyId', familyInfo.id);
            localStorage.setItem('familyName', familyInfo.name);
            localStorage.setItem('familyPhone', familyInfo.phone);
            localStorage.setItem('familyGender', familyInfo.gender);
            localStorage.setItem('familyBirthday', familyInfo.birthday);
            localStorage.setItem('familyIdentityCard', familyInfo.identityCard);
            localStorage.setItem('familyProvinceId', familyInfo.provinceId);
            localStorage.setItem('familyCityId', familyInfo.cityId);
            localStorage.setItem('familyRegionId', familyInfo.regionId);
            localStorage.setItem('familyAddress', familyInfo.address);

            this.familyName = familyInfo.name;

            this.$router.back();
        },
        //家庭成员
        getFamily() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/families/list', {
                "userId": userInfo.id
            })
                .then(function (response) {
                    that.familyList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}

</script>

<style scoped>

</style>