<template>
    <div>
        <div style="height: 100px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">
            <van-image
                    style="margin-left: 30px;margin-top: 15px;float: left;margin-right: 20px;"
                    width="60px"
                    height="60px"
                    :src=clinicInfo.thumb
            />
            <div style="line-height: 45px;margin-top:10px;padding-left: 20px;font-size: medium;font-weight: 550;">
                {{ clinicInfo.title }}
            </div>
            <div style="font-size: small;color: #565656;">
                {{ clinicInfo.address }}
            </div>

        </div>

        <van-divider style="margin: 10px 0 0 0"/>
        <van-field style="padding-top: 10px;" label="姓名" v-model="name" required placeholder="请输入姓名"
                   input-align="right"/>

        <van-field label="手机号码" placeholder="请输入手机号码" maxlength="11" v-model="phone" input-align="right"
                   required/>
        <van-field label="身份证号" placeholder="请输入身份证号" v-model="idNumber" input-align="right" required/>
        <!--<van-field name="radio" label="性别" input-align="right" >
          <template #input>
            <van-radio-group v-model="radio" direction="horizontal">
              <van-radio name="1" icon-size="16px">男</van-radio>
              <van-radio name="2" icon-size="16px">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-cell title="出生日期" is-link @click="clickShowBirthday" :value="birthday"  />

        <van-popup v-model="showBirthday" position="bottom" :style="{ height: '30%' }">
          <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择出生日期"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmBirthday"
              @cancel="onCancelBirthday"
          />
        </van-popup>-->

<!--        <van-popup v-model="showPopup" position="bottom" :style="{ height: '30%' }">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"/>
        </van-popup>-->


<!--        <van-field readonly clickable label="现居地区" :value="valueArea" input-align="right"
                   placeholder="请选择现居地区"
                   @click="bindShow" required/>

        <van-popup v-model="showArea" position="bottom" :style="{ height: '40%' }">
            <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="bindCancel"/>
        </van-popup>-->

        <!--        <van-field label="详细地址" placeholder="请输入详细地址" v-model="address" input-align="right" required/>-->
        <van-cell title="下一年是否续点" clickable required style="height: 120px; ">
            <div style="text-align: left;width: 150px;">如果是，工作人员将会在下一年度自动帮您定点在现在定的机构</div>

            <template #right-icon>
                <van-switch v-model="address" size="18" style="margin-top: 6px;"/>
            </template>
        </van-cell>

        <van-field label="手写签名" readonly required/>
        <div v-if="showSignSrc === false">
            <div style="border:solid #bcbcbc 1px">
                <vue-esign ref="esign" :width="800" :height="360" :isCrop="isCrop" :lineWidth="lineWidth"
                           :lineColor="lineColor" :bgColor="bgColor"/>
            </div>
            <div style="height: 1rem;"></div>
            <van-button @click="handleReset" style="margin-right: 20px;height: 30px;">重写</van-button>
            <van-button @click="handleGenerate" style="margin-left: 20px;height: 30px;">确定</van-button>
        </div>
        <div v-else style="border:solid #bcbcbc 1px">
            <van-image :src="signSrc"/>
        </div>
        <div style="height: 5rem;"></div>
        <div class="submit-bar">
            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 100px; margin: 10px 20px;  " @click="addFamily">
                提交
            </van-button>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import {Dialog, Field, Switch} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Cell, CellGroup} from 'vant';
import {Picker} from 'vant';
import {Popup} from 'vant';
import {Area} from 'vant';
import Areas from '@/utils/area'
import {DatetimePicker} from 'vant';
import {formatDate} from "@/utils/date";
import axios from "axios";
import vueEsign from 'vue-esign'
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;

Vue.use(Switch);
Vue.use(vueEsign)
Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
export default {
    name: "insurance",
    data() {
        return {
            clinicInfo: [],
            areaList: Areas,
            idNumber: "",
            address: true,
            phone: "",
            name: "",
            birthday: "",
            showPopup: false,
            showArea: false,
            showBirthday: false,
            radio: '1',
            currentDate: new Date(),
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            valueArea: '', //地区值
            arrArea: [], //存放地区数组
            province: '',
            city: '',
            area: '',
            lineWidth: 6,
            lineColor: '#000000',
            bgColor: '',
            isCrop: false,
            signSrc: '',
            showSignSrc: false
        };
    },
    created() {
        let appid = this.comFun.getAppid();
        if (appid === undefined) {
            appid = this.$route.query.appid;
        }
        if (appid !== undefined) {
            localStorage.setItem('appid', appid);
            this.getCompanyInfo(appid);
        } else {
            appid = localStorage.getItem('appid');
            this.getCompanyInfo(this.activeTab, appid);
        }
        let code = this.comFun.getQueryVariable('code');
        if (localStorage.getItem('userInfo' + appid) == null) {
            if (code === false || code.length < 10) {
                const appid2 = this.$route.query.appid;
                if (localStorage.getItem('appid') === undefined) {
                    localStorage.setItem('appid', appid2);
                }
                this.comFun.getCode(appid2);
            } else {
                this.comFun.getUserInfo();
            }
        } else {
            this.comFun.initJsSDK();
        }
    },
    mounted() {
        //axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        /*if(localStorage.getItem('goClinicId') == null) {
          this.nearClinic()
        } else {

        }*/
        this.getClinicInfo();
    },
    methods: {
        //自动进入最近的机构
        nearClinic() {
            let that = this;
            //axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
            axios.get('/api/company', {
                params: {
                    "longitude": localStorage.getItem('longitude'),
                    "latitude": localStorage.getItem('latitude'),
                    "order_distance": "asc",
                    "page": 1,
                    "per_page": 1
                }
            })
                .then(function (response) {
                    //that.clinicList = response.data.data;
                    //console.log('自动进入最近的机构 = ' + val  + JSON.stringify(response.data.data))
                    //超出3公里进入平台首页
                    //if(response.data.data[0].distance <= 800) {
                    //自动进入最近的机构
                    localStorage.setItem('goClinicId', response.data.data[0].id)
                    //that.$router.push({name: 'clinic'})
                    that.getClinicInfo();
                    //}

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        handleReset() {
            this.$refs.esign.reset()
        },
        handleGenerate() {
            this.$refs.esign.generate().then(res => {
                this.signSrc = res
                this.showSignSrc = true
                console.log('签名图片。。。。。。。。。。。')
                console.log(res)
            }).catch(err => {
                console.log(err) // 画布没有签字时会执行这里 'Not Signned'
                Dialog.alert({message: '请手写签名'});
            })
        },
        //获取公司信息
        getCompanyInfo(appid) {
            let that = this;
            axios.post('/companies/appId', {
                "appId": appid
            })
                .then(function (response) {
                    localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
                    document.title = response.data.data.title;
                    that.getClinicInfo();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //机构信息
        getClinicInfo() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/companies/info', {
                    id: userInfo.companyId
                }
            )
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                    Dialog({
                        title: '医保定点有哪些好处?',
                        message: '1、报销额度不同 \n ' +
                            '&emsp;定点医院是由医保机构指定的医院，报销的额度自然会比较多，在小型医院，药费报销的比例可以达到80%，而在大型医院看病，报销的比例大概在45%到55%左右。一般情况下，医保不定点的医院是不能报销医疗费用的，药费也不能报销，因为医保卡只能在指定医院按比例报销，而不定点医院就只能自费治疗了。\n' +
                            '2、缴费方式不同 \n' +
                            '&emsp;在定点医院治疗支付医疗费用时，可以直接冲销费用，补交冲销之后的费用就行了。而在不定点医院的话，要先向保险公司申请，不然很可能不能报销，不仅如此，住院的医疗费用还得自己垫付，然后才能申请社保报销。正常情况下，定点医院是已经固定好的，不能再进行更改。\n' +
                            '3、医疗机构不同 \n' +
                            '&emsp;定点医疗机构是跟保险机构协商好的，已经签订了协议，其中包括公立的医疗机构以及医疗设施、技术较好的民营机构。参保人员在定点医疗机构发生治疗时，是可以报销的，而非定点医疗机构就不行了。\n',
                        messageAlign: 'left',
                        confirmButtonText: '我已知晓',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on confirm
                    })
                        .catch(() => {
                            // on cancel
                        });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //新增订单
        addFamily() {
            if (this.name.length === 0) {
                Dialog.alert({message: '请输入姓名'});
                return;
            }
            if (this.phone.length === 0) {
                Dialog.alert({message: '请输入手机号码'});
                return;
            }
            if (this.phone.length !== 11) {
                Dialog.alert({message: '手机号输入有误'});
                return;
            }
            if (this.idNumber.length === 0) {
                Dialog.alert({message: '请输入医保卡号'});
                return;
            }
            if (this.showSignSrc !== true) {
                Dialog.alert({message: '请确定手写签名'});
                return;
            }
            /*if (this.address.length === 0) {
                Dialog.alert({message: '请输入现居地址'});
                return;
            }*/
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/insurances/save', {
                "userId": userInfo.id,
                "name": this.name,
                "companyId": userInfo.companyId,//localStorage.getItem('goClinicId'),
                "phone": this.phone,
                "cardNumber": this.idNumber,
                "provinceId": '110000',
                "cityId": '110100',
                "regionId": '110101',
                "address": this.address === true ? '是' : '否',
                "autograph": this.signSrc
            })
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                        that.$router.back();
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onConfirm(value) {
            this.relation = value;
            this.showPopup = false;
        },
        onCancel() {
            this.showPopup = false;
        },
        clickShowBirthday() {
            this.showBirthday = true;
        },
        onConfirmBirthday(value) {
            // console.log(console.log( formatDate(value, 'yyyy-MM-dd')));
            this.birthday = formatDate(value, 'yyyy-MM-dd');
            this.showBirthday = false;
        },
        onCancelBirthday() {
            this.showBirthday = false;
        },
        clickShowPicker() {
            this.showPopup = true;
        },
        bindShow() {
            this.showArea = true;
        },
        bindCancel() {
            this.showArea = false;
        },
        //地区选择
        onAreaConfirm(val) {
            this.showArea = false;
            this.arrArea = val;
            this.valueArea = val[0].name + '-' + val[1].name + '-' + val[2].name;
            this.province = val[0].code;
            this.city = val[1].code;
            this.area = val[2].code;
        },

    }
}
</script>

<style scoped>
.van-collapse-item {
    text-align: left;
}

.van-cell {
    line-height: 34px;
}

.van-cell__title {
    text-align: left;
}

.van-cell__left-icon, .van-cell__right-icon {
    line-height: 34px;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}
.van-cell__title[data-v-0c547fda] {
    width:100px;
}
div.van-cell__title {
    width:100px;
}
</style>