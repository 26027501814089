<template>
    <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
        <div style="height: 130px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">

            <div style="line-height: 45px;  font-size: medium;font-weight: 550;">
                <!--{{ clinicInfo.title }}-->
                <van-cell center :title=clinicInfo.title to="/clinicInfo" is-link/>
            </div>
            <van-divider style="margin: 0;"/>
            <div style="width: 100%;">
                <div style="width: 100%;float:left;">
                    <div class="van-ellipsis"
                         style="margin-top: 5px;font-size: small;color: #565656;margin-left:15px;text-align: left;line-height: 15px;">
                        服务时间:{{ clinicInfo.working_hours }}
                    </div>

                    <div class="van-ellipsis"
                         style="width: 95%;font-size: small;color: #565656;margin-left:15px;text-align: left;line-height: 15px;margin-top: 10px;">
                        <div style="float: left;width: 60px;"> 机构地址:</div>
                        <span class="van-ellipsis" style="width:70%;float: left;">{{ clinicInfo.address }}  </span>
                        <div style=" width: 25px;height: 25px;float: right;vertical-align: middle" @click="openMap">
                            <van-icon name="location" size="18"/>
                        </div>

                    </div>

                    <div class="van-ellipsis"
                         style="width: 95%;font-size: small;color: #565656;margin-left:15px;text-align: left;line-height: 15px;">
                        机构电话:

                        <span style="width: 90%">{{ clinicInfo.telephone }}</span>
                        <div style="width: 25px;height: 30px;float: right;vertical-align: middle"
                             @click="call(clinicInfo.telephone)">
                            <van-icon name="phone" size="18"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div
                style="text-align: left;margin-left: 15px;font-weight: 550;font-size: large;margin-top: 10px; padding-top: 10px;align-items: center;line-height: 30px;">
            疫苗预约
        </div>

        <van-button round type="info" id="btAdult" size="small" style="margin-right:20px;"
                    v-bind:class="{ active: isAdult }" @click="adultProduct">成人疫苗
        </van-button>
        <van-button round type="info" id="btChildren" size="small" style="margin-left: 20px;"
                    v-bind:class="{ active: isChildren }" @click="childrenProduct">儿童疫苗
        </van-button>

        <div>
        <span v-for="(item, index) in vaccineList" :key="index">
            <div v-if="item.adult === adult" style="margin: 15px;border-radius: 10px;background: white;">
              <van-cell center :title=item.title @click="toAppointment(item)" :value="item.price"
                        :label=item.description is-link/>
            </div>
        </span>
            <span v-if="vaccineList === undefined || vaccineList.length <= 0">
        <van-empty description="暂无数据~"/>
      </span>
        </div>


    </div>
</template>

<script>
import Vue from 'vue';
import {SubmitBar} from 'vant';
import {Button} from 'vant';
import axios from "axios";
import wx from "weixin-js-sdk";
import {Empty} from 'vant';

Vue.use(Empty);
Vue.use(Button);
Vue.use(SubmitBar);
export default {
    name: "product",
    data() {
        return {
            vaccineInfo: [],
            clinicInfo: [],
            stock: 1,
            vaccineList: [],
            adult: 1,
            isAdult: false,
            isChildren: true
        }
    },
    mounted() {
        /*this.getClinicInfo();
        //this.getVaccineInfo();
        this.getClinicList();*/

        let appid = this.comFun.getAppid();
        if (appid === undefined) {
            appid = this.$route.query.appid;
        }
        if (appid !== undefined) {
            localStorage.setItem('appid', appid);
            this.getCompanyInfo(appid);
        } else {
            appid = localStorage.getItem('appid');
            this.getCompanyInfo(appid);
        }

        let code = this.comFun.getQueryVariable('code');
        if (localStorage.getItem('userInfo' + appid) == null) {
            if (code === false || code.length < 10) {
                const appid2 = this.$route.query.appid;
                if (localStorage.getItem('appid') === undefined) {
                    localStorage.setItem('appid' + appid2, appid2);
                }
                this.comFun.getCode(appid2);
            } else {
                this.comFun.getUserInfo();
            }
        } else {
            document.title = localStorage.getItem("companyName");
            this.comFun.initJsSDK();
        }

    },
    beforeCreate() {
        //进入页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #f7f8fd');
    },
    beforeDestroy() {
        //退出页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #fff');
    },
    computed: {
        swipeHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕高

            return height + 'px';
        }
    },
    methods: {
        //获取公司信息
        getCompanyInfo(appid) {
            let that = this;
            axios.post('/companies/appId', {
                "appId": appid
            })
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                    that.getClinicList(response.data.data.id);

                    localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
                    localStorage.setItem('companyName', response.data.data.title)
                    // that.getBanner(response.data.data.id);
                    that.document.title = response.data.data.title;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        adultProduct() {
            //document.querySelector('#btAdult').setAttribute('style','background: #bcbcbc');
            this.adult = 1;
            this.isAdult = false
            this.isChildren = true
        },
        childrenProduct() {
            //document.querySelector('#btChildren').setAttribute('style','background: #bcbcbc');
            this.adult = 0;
            this.isAdult = true
            this.isChildren = false
        },
        toAppointment(item) {
            //选择的门诊机构
            localStorage.setItem('chooseClinicId', item.companyId);
            localStorage.setItem('chooseProductId', item.id);
            localStorage.setItem('chooseVaccineId', item.vaccineId);
            //this.$router.push({name: 'step1', params: {chooseVaccineId: item.vaccineId}})
            this.$router.push({path: '/step1', query: {chooseVaccineId: item.vaccineId}})
        },
        openMap() {
            wx.openLocation({
                latitude: this.clinicInfo.latitude, // 纬度，浮点数，范围为90 ~ -90
                longitude: this.clinicInfo.longitude, // 经度，浮点数，范围为180 ~ -180。
                name: this.clinicInfo.title, // 位置名
                address: this.clinicInfo.address, // 地址详情说明
                scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
                infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
            });
        },
        call(phone) {
            //打电话
            //window.open('tel:10086', '_blank')
            window.location.href = 'tel:' + phone;
        },
        subscribeIndex() {
            localStorage.setItem("subscribeDetail", JSON.stringify(this.vaccineInfo));
            this.$router.push('subscribeIndex')
        },
        //获取产品列表
        getClinicList(companyId) {
            let that = this;
            axios.post('/products/list', {
                "companyId": companyId,
                "adult": 1
            })
                .then(function (response) {

                    that.$route.query.adult === '1' ? that.adultProduct() : that.childrenProduct();
                    //that.images.splice(0);//清空
                    that.vaccineList = response.data.data;
                    /*let arr = response.data.data;
                    for (let i = 0; i < arr.length; i++) {
                      that.images.push(arr[i].thumb);
                      console.log(arr[i].thumb);
                    }*/

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAdult() {
            let url = window.location.href;             //获取当前url
            let dz_url = (url || '').split('#')[0];                //获取#/之前的字符串
            let cs = (dz_url || '').split('?')[1];                //获取?之后的参数字符串
            let cs_arr = (cs || '').split('&');                    //参数字符串分割为数组
            let res = {};
            for (let i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
                res[(cs_arr[i] || '').split('=')[0]] = (cs_arr[i] || '').split('=')[1]
            }
            return res.adult;
        },
        //疫苗信息
        /*getVaccineInfo() {
          let that = this;
          axios.get('/api/vaccine/product/' + localStorage.getItem('chooseProductId')
          )
              .then(function (response) {
                console.log('stock = ' + response.data.data.stock)
                that.stock = response.data.data.stock;
                that.vaccineInfo = response.data.data;
              })
              .catch(function (error) {
                console.log(error);
              });
        },*/
        //机构信息
        getClinicInfo() {
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            let that = this;
            axios.post('/companies/info', {
                    companyId: userInfo.companyId
                }
            )
                .then(function (response) {
                    console.log('getClinicInfo = ' + JSON.stringify(response.data.data))
                    that.clinicInfo = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.active {
    background: #bcbcbc;
    border-color: #bcbcbc;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

.content >>> img {
    max-width: 100%;
    height: auto;
    display: flex;
}

.van-cell__value {
    color: red;
}

.van-cell__label {
    font-size: 12px;
}
</style>