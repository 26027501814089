<template>
    <div>
        <div style="height: 100px;background: rgb(255,251,235);border-radius: 8px;margin:15px; ">
            <div style="text-align: left; color: #ffcb41;font-size: small;padding: 10px 10px 0 10px;font-weight: 550;">
                温馨提示
            </div>
            <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
                {{ message }}
            </div>
        </div>
        <van-empty v-if="reverseList.length === 0" description="暂无数据">
        </van-empty>
        <span v-for="(item, index) in reverseList" :key="index">
        <div style="height: 150px;background: white;border-radius: 10px;margin:15px; box-shadow:6px 6px 9px #eee;"
             @click="goSubscribeDetail(item)">

        <div style="padding: 15px;">
          <span style="float: left;font-size: large;">{{ JSON.parse(item.remarks).vaccine.title }}</span>
          <span style="text-align: right;float: right;color: rgb(6,214,160);">{{
              item.state === 0 ? '待通知' : '已通知'
              }}</span>
        </div>
        <van-divider/>
        <div style="height: 20px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: small">订阅人</span>
          <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ item.name }}</span>
        </div>

        <div style="height: 20px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: small">订阅时间</span>
          <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ item.createdAt }}</span>
        </div>
        <div style="height: 20px;padding: 0 20px;">
          <span style="float: left;color: #bcbcbc;font-size: small">订阅门诊</span>
          <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ JSON.parse(item.remarks).company.title }}</span>
        </div>

      </div>
    </span>

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "SubscribeList",
    data() {
        return {
            reverseList: [],
            message: "机构到苗后将发送到苗通知，请留意\"预约疫苗服务\"公众号[服务进度提醒]，收到提醒后请及时预约，预约成功后即可进行接种。"
        };
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        this.getReverseList();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        goSubscribeDetail(item) {
            localStorage.setItem('subscribeDetail', JSON.stringify(item))
            this.$router.push("subscribeDetail")
        },
        getReverseList() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/queues/my', {
                "modelType": "App\\Collect",
                "userId": userInfo.id,
                "state": 1,
                "title": "_",
                "parentId": 0,
                "page": 1,
                "per_page": 20,
                "companyId": userInfo.companyId
            })
                .then(function (response) {
                    that.reverseList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>