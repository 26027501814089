<template>
    <div>
        <div style="height: 100px;background: white;">
            <div style="padding: 15px;">
                <span style="float: left;font-size: large;">{{ detail.title }}</span>
            </div>
            <van-divider/>
            <div style="height: 20px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">订阅门诊</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ clinicInfo.title }}</span>
            </div>
        </div>
        <div style="margin-top: 10px;">
            <van-collapse v-model="activeNames">
                <van-collapse-item title="选择订阅人" :value="familyName" name="2" icon="user-circle-o">
                    <van-radio-group v-model="radio1">
                        <van-cell-group :border="false">
              <span v-for="(item, index) in familyList" :key="index">
                <van-cell :title=item.name clickable :label=label(item)>
                  <template #right-icon>
                    <van-radio @click="chooseFamily(item)" :name=item.id icon-size="16px"/>
                  </template>
                </van-cell>
              </span>
                        </van-cell-group>
                    </van-radio-group>
                    <van-divider/>
                    <div style="height: 35px;text-align: center;">
                        <van-button icon="plus" plain type="info" to="/familyList" size="small">新增家庭成员
                        </van-button>
                    </div>
                </van-collapse-item>
            </van-collapse>

        </div>

        <div style="text-align: left; height: 40px; font-size: small;margin: 30px 15px;">
            <van-icon name="info-o"/>
            <span> 其他注意事项详见订阅须知</span>
        </div>

        <div class="submit-bar">

            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 55%; margin: 10px; " @click="submit">
                提交订阅
            </van-button>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import {Button, Dialog} from 'vant';
import axios from "axios";

Vue.use(Button);
export default {
    name: "SubscribeIndex",
    data() {
        return {
            radio1: 0,
            detail: [],
            clinicInfo: [],
            familyName: '',
            activeNames: ['1'],
            familyList: [],
            message: "机构到苗后将发送到苗通知，请留意\"预约疫苗服务\"公众号[服务进度提醒]，收到提醒后请及时预约，预约成功后即可进行接种。"
        }
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        console.log(localStorage.getItem('subscribeDetail'))
        this.detail = JSON.parse(localStorage.getItem("subscribeDetail"))
        this.clinicInfo = JSON.parse(localStorage.getItem("clinicInfo"))
        this.getFamily()
    },
    methods: {
        label(item) {
            let sex = item.gender === 1 ? '男' : '女';

            return item.relationship + '/' + sex + ' ' + item.birthday;
        },
        chooseFamily(familyInfo) {
            localStorage.setItem('familyId', familyInfo.id);
            localStorage.setItem('familyName', familyInfo.name);
            localStorage.setItem('familyPhone', familyInfo.phone);
            localStorage.setItem('familyGender', familyInfo.gender);
            localStorage.setItem('familyBirthday', familyInfo.birthday);
            localStorage.setItem('familyIdentityCard', familyInfo.identityCard);
            localStorage.setItem('familyProvinceId', familyInfo.provinceId);
            localStorage.setItem('familyCityId', familyInfo.cityId);
            localStorage.setItem('familyRegionId', familyInfo.regionId);
            localStorage.setItem('familyAddress', familyInfo.address);

            this.familyName = familyInfo.name;
            let that = this;
            if (familyInfo.identityCard == null || familyInfo.identityCard.length < 15) {
                Dialog.alert({
                    message: '请先完善接种人的身份证号信息',
                    messageAlign: 'left',
                }).then(() => {
                    // on confirm
                    localStorage.setItem('familyEditInfo', JSON.stringify(familyInfo))
                    that.$router.push('familyEdit')
                })
            }

            this.familyName = familyInfo.name;
        },
        //家庭成员
        getFamily() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('families/list', {
                    userId: userInfo.id
                }
            )
                .then(function (response) {
                    that.familyList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        submit() {
            if (this.familyName === '') {
                Dialog.alert({
                    message: '请选择订阅人',
                    messageAlign: 'left',
                    confirmButtonColor: 'black'
                }).then(() => {
                    // on close
                });
                return;
            }

            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/queues/save', {
                "phone": localStorage.getItem('familyPhone'),
                "name": localStorage.getItem('familyName'),
                "gender": localStorage.getItem('familyGender'),
                "birthday": localStorage.getItem('familyBirthday'),
                "identityCard": localStorage.getItem('familyIdentityCard') === 'null' ? '' : localStorage.getItem('familyIdentityCard'),
                "provinceId": localStorage.getItem('familyProvinceId'),
                "cityId": localStorage.getItem('familyCityId'),
                "regionId": localStorage.getItem('familyRegionId'),
                "address": localStorage.getItem('familyAddress') === 'null' ? '' : localStorage.getItem('familyAddress'),
                "modelId": localStorage.getItem('chooseProductId'),
                "modelType": 'App\\Collect',
                "companyId": userInfo.companyId,
                "userId": userInfo.id,
                "vaccineId": this.detail.id,
                "productId": this.detail.productId,
                "price": this.detail.price,
                "injectionTotal": 0
            })
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                        //if (response.data.code === 200)
                            that.$router.replace("index");
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>
.van-collapse-item {
    text-align: left;
}

.van-cell__title {
    text-align: left;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}
</style>