import { render, staticRenderFns } from "./ParentingSetting.vue?vue&type=template&id=87047ba8&scoped=true&"
import script from "./ParentingSetting.vue?vue&type=script&setup=true&lang=js&"
export * from "./ParentingSetting.vue?vue&type=script&setup=true&lang=js&"
import style1 from "./ParentingSetting.vue?vue&type=style&index=1&id=87047ba8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87047ba8",
  null
  
)

export default component.exports