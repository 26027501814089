<template >
    <div style="background: #EEEEEE;height: 100vh;">
        <div style="padding: 20px 20px;text-align: left;background: white;">
            <div style=" font-size: medium;color: #07c160;font-weight: bold">{{ doctorName }} <span style=" font-size: medium;">{{departmentName}}</span> <span style="float: right;font-size: small;color: orange;">挂号费：￥0</span>
            </div>
            <div style="margin-top: 10px;font-size: medium;font-weight: bold;">{{documentTitle}}</div>
        </div>

        <van-divider style="margin: 10px 0 0 0"/>
        <van-cell title="就诊日期" v-model="dateDetail"/>
        <van-cell title="预约时段" v-model="timeRange"/>
        <van-cell title="候诊区域" value=""/>

        <van-divider style="margin: 10px 0 0 0"/>
        <van-cell title="就诊人" v-model="name" to="/familyList" is-link/>
        <van-cell title="预约卡" v-model="idNumber" to="/familyList" is-link/>
        <van-cell title="初/复诊" v-model="diagnosis" @click="changeState"/>
        <van-cell title="支付方式" value="医院支付"/>

        <div style="height: 3rem;"></div>
        <!--        <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                            style="border-radius: 5px;width: 100px; margin: 10px 20px;  " @click="addFamily">
                    提交
                </van-button>-->
        <van-button type="primary" :loading="loading" style="width: 80%;left: 10%;font-size: medium;" @click="confirmOrder" block>确认预约</van-button>

        <div style="font-size: medium;color: #07c160;margin-top: 35px;"> 预约规则</div>
        <div style="height: 8rem;"></div>
        <!--        <div class="submit-bar">

                </div>-->

    </div>
</template>

<script>

import Vue from 'vue';
import {Dialog, Field} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Cell, CellGroup} from 'vant';
import {Picker} from 'vant';
import {Popup} from 'vant';
import {Area} from 'vant';
import {DatetimePicker} from 'vant';
import {formatDate} from "@/utils/date";
import axios from "axios";
import department from "./Department.vue";

Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
export default {
    name: "doctorConfirm",
    computed: {
        department() {
            return department
        }
    },
    data() {
        return {
            loading: false,
            departmentName: '',
            documentTitle: '',
            dateDetail: '',
            doctorName: '',
            workDate: '',
            selectWeekday: '',
            periodCode: '', // 1上午 2下午
            workId: "",
            startTime: "",
            endTime: "",
            timeRange: "",
            idNumber: "",
            name: "",
            showPopup: false,
            showArea: false,
            showBirthday: false,
            radio: '1',
            currentDate: new Date(),
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            valueArea: '', //地区值
            arrArea: [], //存放地区数组
            province: '',
            city: '',
            diagnosis: '初诊',
            area: ''
        };
    },
    mounted() {
        this.doctorName = this.$route.query.doctorName
        this.workDate = this.$route.query.workDate
        this.selectWeekday = this.$route.query.selectWeekday
        this.periodCode = this.$route.query.periodCode
        this.workId = this.$route.query.workId
        this.startTime = this.$route.query.startTime
        this.endTime = this.$route.query.endTime
        this.timeRange = this.startTime + '-' + this.endTime
        this.dateDetail = this.selectWeekday + ' ' + this.periodCode

        //let appid = localStorage.getItem('appid');
        //let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));

        // this.idNumber = userInfo.idNumber;

        this.name = localStorage.getItem("familyName")
        this.idNumber = localStorage.getItem("familyIdentityCard")
        document.title = localStorage.getItem("companyName");
        this.documentTitle = localStorage.getItem("companyName");
        this.departmentName = localStorage.getItem('departmentTitle');

    },
    methods: {
        changeState() {
            this.diagnosis = this.diagnosis === '初诊' ? '复诊' : '初诊';
        },
        //提交预约
        confirmOrder() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            if (this.idNumber == null || this.idNumber.length === 0) {
                Dialog.alert({
                    message: "身份证号为空，请先添加本人信息到家庭成员列表",
                    messageAlign: 'left',
                    confirmButtonColor: 'black'
                }).then(() => {
                    that.$router.push({name: 'familyAdd'})
                });
                return;
            }
            this.loading = true;
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/doctor/his', {
                "name": this.name,
                "companyId": userInfo.companyId,
                "userId": userInfo.id,
                "workId": this.workId,
                "periodCode": this.periodCode === '上午' ? 1 : 2,
                "identityCard": this.idNumber
            })
                .then(function (response) {
                    that.loading = false;
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                        that.$router.back();
                    });
                })
                .catch(function (error) {
                    that.loading = false;
                    console.log(error);
                });
        },
        onConfirm(value) {
            this.relation = value;
            this.showPopup = false;
        },
        onCancel() {
            this.showPopup = false;
        },
        clickShowBirthday() {
            this.showBirthday = true;
        },
        onConfirmBirthday(value) {
            // console.log(console.log( formatDate(value, 'yyyy-MM-dd')));
            this.birthday = formatDate(value, 'yyyy-MM-dd');
            this.showBirthday = false;
        },
        onCancelBirthday() {
            this.showBirthday = false;
        },
        clickShowPicker() {
            this.showPopup = true;
        },
        bindShow() {
            this.showArea = true;
        },
        bindCancel() {
            this.showArea = false;
        },
        //地区选择
        onAreaConfirm(val) {
            this.showArea = false;
            this.arrArea = val;
            this.valueArea = val[0].name + '-' + val[1].name + '-' + val[2].name;
            this.province = val[0].code;
            this.city = val[1].code;
            this.area = val[2].code;
        },

    }
}
</script>

<style scoped>
.van-collapse-item {
    text-align: left;
}

.van-cell {
    line-height: 34px;
}

.van-cell__title {
    text-align: left;
}

.van-cell__left-icon, .van-cell__right-icon {
    line-height: 34px;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

.van-cell__value {
    color: black;
    font-size: medium;
}
.van-cell__title {
    font-size: medium;
}
</style>