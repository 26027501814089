<template>
  <div id="app">
    <!--<img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <router-view v-wechat-title='$route.meta.title'></router-view>
<!--    <router-view></router-view>-->
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    //HelloWorld
  },
  watch: {
    $route() {
      if (window._czc) {
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
      }
    }
  },
  mounted () {
    // 友盟统计添加
    /*const script = document.createElement("script");
    script.src = "https://v1.cnzz.com/z_stat.php%3Fid%3D1280010768%26show%3Dpic1";
    script.language = "JavaScript";
    document.body.appendChild(script);*/
  }
}
</script>

<style>
/*@import "./common/font/font.css";*/
#app {
  font-family: Microsoft YaHei,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}
</style>
