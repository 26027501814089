<template>
    <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
        <div style="height: 100px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">
            <van-image
                    style="margin-left: 30px;margin-top: 15px;float: left;margin-right: 20px;"
                    width="60px"
                    height="60px"
                    :src=clinicInfo.thumb
            />
            <div class="van-ellipsis" style="line-height: 45px;margin-top:10px;font-size: medium;font-weight: bold;">
                {{ clinicInfo.title }}
            </div>
            <div style="font-size: medium;color: #565656;">
                {{ clinicInfo.address }}
            </div>

        </div>

        <div style="height: 130px;background: white;border-radius: 8px;margin:15px; ">

            <div style="padding: 20px;">
                <span style="float: left;font-weight: bold;font-size: medium">{{ vaccineInfo.title }}</span>
                <span style="text-align: right;float: right;color: red;">￥{{ vaccineInfo.price }}</span>
            </div>
            <van-divider/>
            <div style="height: 25px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: medium">生产厂家</span>
                <span style="text-align: right;float: right; font-size: medium;color: #565656;">
          {{ vaccineInfo.manufacturer }}</span>
            </div>
            <div style="height: 20px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: medium">产品规格</span>
                <span style="text-align: right;float: right; font-size: medium;color: #565656;">
          {{ vaccineInfo.specifications }}</span>
            </div>
            <div style="padding: 0 20px;background: white;border-radius: 10px;">
                <p v-html="vaccineInfo.contents" class="content"
                   style="font-size:32px;width: 100%;overflow: hidden;padding: 10px 0;"></p>
            </div>

            <div style="height: 5rem;"></div>
        </div>

        <div class="submit-bar">
      <span v-if="stock === 0 && vaccineInfo.adult === 1">
        <van-button color="#7232dd" plain style="margin: 10px; border-radius: 5px;"
                    @click="subscribeIndex">订阅到苗通知</van-button>
      </span>
            <span v-if="stock > 0 && productState === 1">
        <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                    style="border-radius: 5px;width: 55%; margin: 10px; " to="step2">
          立即预约
        </van-button>
      </span>
            <span v-if="stock === 0 && vaccineInfo.adult === 1">
        <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                    style="border-radius: 5px;width: 45%; margin: 10px; " to="queueIndex">
          立即排队
        </van-button>

                <!--        <van-button color="linear-gradient(to right, #9a9a9a, #888888)"
                                    style="border-radius: 5px;width: 45%; margin: 10px; " to="#">
                          暂时缺苗
                        </van-button>-->
      </span>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import {SubmitBar} from 'vant';
import {Button} from 'vant';
import axios from "axios";

Vue.use(Button);
Vue.use(SubmitBar);
export default {
    name: "step1",
    data() {
        return {
            vaccineInfo: [],
            clinicInfo: [],
            productState: 1,
            stock: 0
        }
    },
    mounted() {
        document.title = Vue.platformTitle;
        this.getClinicInfo();
        this.getVaccineInfo();
    },
    beforeCreate() {
        //进入页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #f7f8fd');
    },
    beforeDestroy() {
        //退出页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #fff');
    },
    computed: {
        swipeHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕高

            return height + 'px';
        }
    },
    methods: {
        subscribeIndex() {
            localStorage.setItem("subscribeDetail", JSON.stringify(this.vaccineInfo));
            localStorage.setItem("clinicInfo", JSON.stringify(this.clinicInfo));
            this.$router.push({name: 'subscribeIndex', query: {id: this.vaccineInfo.id}})
        },
        //疫苗信息
        getVaccineInfo() {
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            let that = this;
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/vaccines/info', {
                    id: that.$route.query.chooseVaccineId,
                    companyId: userInfo.companyId,
                }
            )
                .then(function (response) {
                    console.log('stock = ' + response.data.data.stock)
                    that.stock = response.data.data.stock;
                    that.productState = response.data.data.productState;
                    that.vaccineInfo = response.data.data;
                    localStorage.setItem('chooseProductId', response.data.data.productId)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //机构信息
        getClinicInfo() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/companies/info', {
                    id: userInfo.companyId
                }
            )
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

.content >>> img {
    max-width: 100%;
    height: auto;
    display: flex;
}
</style>