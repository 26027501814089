<template>
    <div>
        <div style="padding: 30px 20px;text-align: left;border-bottom: #eeeeee solid;">
            <span style="float: left;font-size: large;line-height: 10px;">医生主页</span>
        </div>

        <div style="height: 130px; ">
            <div style=" float: left; height: 100px; width: 30%; text-align:center;">
                <van-image
                        cover
                        radius="6px"
                        width="95px"
                        height="95px"
                        src="https://vaccin-image.wxdev.top/yisheng-2.png"
                />
            </div>
            <div style="width: 100%;">
                <div style="height: 100px;margin: 15px; ">
                    <div style="width: 100%; ">
                        <div style="text-align: left;float: left;">
                            <div class="van-multi-ellipsis--l2"
                                 style="font-size: medium;font-weight: 550;color: #565656;">
                                {{ this.$route.query.DoctorName }}
                            </div>
                        </div>

                        <div style="text-align: left;float: left;margin-top: 10px;width: 70%;">
                      <span style="color: coral;" class="goodAt">
                         {{ detail.subtitle }}
                      </span>
                        </div>

                        <div style="text-align: left;float: left;width: 70%; ">
                      <span class="goodAt" style="font-size: small;">
                         {{ detail.goodAt }}
                      </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-tabs v-model="active" animated>
            <van-tab title="挂号">
                <van-divider/>
<!--                <van-calendar
                        @select="selectDate"
                        :show-title="false"
                        :formatter="formatter"
                        :poppable="false"
                        :show-confirm="false"
                        :min-date="minDate" :max-date="maxDate"
                        :style="{ height: '260px' }"
                />-->
                <vue-hash-calendar :disabledDate="setDisableDate" :markDate="markDate" @click="clickDate" @change="selectDate" :isShowWeekView="true" pickerType="date" :visible="true" :disabledScroll="true" :weekStart="weekStart"/>

                <van-divider/>



<!--                <span v-for="(item, index) in timeList" :key="index" @click="openConfirm(item);">
        <div style="width: 100%;">
          <div style="height: 50px;margin: 15px; ">
            <div style="width: 100%; ">
              <div style="text-align: left;float: left;">
                <div class="van-multi-ellipsis&#45;&#45;l2" style="font-size: medium;font-weight: 550;color: #565656;">
                   {{ item.time }}
                </div>
                <div class="van-multi-ellipsis&#45;&#45;l2" style="font-size: medium;font-weight: 550;color: #565656;">
                  {{ detail.subtitle }}
                </div>
              </div>
              <div style="text-align: right;float: right;margin-top: 15px;">
                <div class="van-multi-ellipsis&#45;&#45;l2" style="font-size: medium;font-weight: 550;color: #565656;">
                  <span v-if="IsSelectedDate">余号:{{ item.num }}</span>

                  <van-tag v-if="item.num > 0" type="warning" style="font-size: medium;">￥{{
                      detail.registrationFee
                      }}</van-tag>
                    &lt;!&ndash;                  <van-tag v-else type="warning" plain style="font-size: medium;">￥{{detail.registrationFee }}</van-tag>&ndash;&gt;
                </div>
              </div>
            </div>
          </div>
        </div>
          <van-divider/>
        </span>-->


            </van-tab>
            <van-tab title="医生简介">
                <van-divider/>

                <div style="margin: 20px;">
                    <div style="text-align: left;font-size: medium;line-height: 40px;">擅长</div>
                    <div style="text-align: left;font-size: medium;color: #5e5e5e;">{{ detail.goodAt }}</div>

                </div>
                <van-divider/>
                <div style="margin: 20px;">
                    <div style="text-align: left;font-size: medium;line-height: 40px;">介绍</div>
                    <div v-html="detail.description"
                         style="text-align: left; width: 100%;overflow: hidden;color: #5e5e5e;"></div>
                </div>

            </van-tab>
            <van-tab title="科目简介">
                <van-divider/>
                <div style="margin: 20px;">
                    <div style="text-align: left;font-size: medium;line-height: 40px;">专长特色</div>
                    <div style="text-align: left;font-size: medium;color: #5e5e5e;">{{ department.feature }}</div>
                </div>
                <van-divider/>
                <div style="margin: 20px;">
                    <div style="text-align: left;font-size: medium;line-height: 40px;">简介说明</div>
                    <div v-html="department.description"
                         style="text-align: left; width: 100%;overflow: hidden;color: #5e5e5e;"></div>
                </div>
            </van-tab>
        </van-tabs>


<!--        <van-action-sheet v-model="show" title=" " style="padding-top: 30px;">
            <div class="content">
                <div style="height: 150px;">
                    <div style=" float: left; height: 100px; width: 30%; text-align:center;">
                        <van-image
                                cover
                                radius="6px"
                                width="90px"
                                height="100px"
                                src="http://vaccin-image.wxdev.top/doctor.jpg"
                        />
                    </div>
                    <div style="width: 100%;">
                        <div style="height: 100px;margin: 15px; ">
                            <div style="width: 100%; ">
                                <div style="text-align: left;float: left;">
                                    <div class="van-multi-ellipsis&#45;&#45;l2"
                                         style="font-size: medium;font-weight: 550;color: #565656;">
                                        医师:{{ detail.name }}
                                    </div>
                                </div>

                                <div style="text-align: left;float: left; width: 70%;font-size: medium;">
                      <span>
                         科室:{{ departmentTitle }}
                      </span>
                                </div>

                                <div style="text-align: left;float: left;width: 70%; ">
                      <span class="goodAt" style="font-size: medium;">
                          费用:￥{{ detail.registrationFee }}
                      </span>
                                </div>
                                <div style="text-align: left;float: left;width: 70%; ">
                      <span class="goodAt" style="font-size: medium;">
                        时段:{{ regDate }} <span style="color: coral">{{ regWeek }}</span> {{ regTime }}
                      </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <van-collapse v-model="activeNames">
                    <van-collapse-item style="margin-bottom: 30px;" title="选择就诊人" :value="familyName" name="2"
                                       icon="user-circle-o">
                        <van-radio-group v-model="radio1">
                            <van-cell-group :border="false">
              <span v-for="(item, index) in familyList" :key="index">
                <van-cell :title=item.name clickable :label=label(item)>
                  <template #right-icon>
                    <van-radio @click="chooseFamily(item)" :name=item.id icon-size="16px"/>
                  </template>
                </van-cell>
              </span>
                            </van-cell-group>
                        </van-radio-group>
                        <van-divider/>
                        <div style="height: 35px;text-align: center;">
                            <van-button icon="plus" plain type="info" to="/familyList" size="small">新增家庭成员
                            </van-button>
                        </div>
                    </van-collapse-item>
                </van-collapse>

                <van-button style="width: 100%;font-size: medium;" type="info" @click="confirmRegistration">确认预约
                </van-button>
            </div>
        </van-action-sheet>-->
        <van-popup v-model="showPopup" closeable position="bottom" :style="{ height: '36%' }" >
            <div style="height: 30px;margin-top: 15px;">{{selectWeekday}}</div>
            <van-tabs v-model="active2" @change="changeTabs" swipeable :border="true">
                <van-tab title="上午">
                    <van-row style="margin-top: 20px;" v-if="workTimeList.length > 0">
                        <van-col span="12" v-for="(item, index) in  workTimeList" :key="index">
                            <button style="margin: 10px 0;background: white;border:solid 1px gray;border-radius: 3px;" @click="goConfirm(item)">{{item.StartTime}}-{{item.EndTime}}
                            <span style="color: green;">余{{item.AvailableCount}}</span>
                            </button>
                        </van-col>
                    </van-row>
                    <van-row style="margin-top: 30px;" v-else>{{showTips}} </van-row>
                </van-tab>
                <van-tab title="下午">
                    <van-row style="margin-top: 20px;" v-if="workTimeList.length > 0">
                        <van-col span="12" v-for="(item, index) in  workTimeList" :key="index">
                            <button style="margin: 10px 0;background: white;border:solid 1px gray;border-radius: 3px;" @click="goConfirm(item)">{{item.StartTime}}-{{item.EndTime}}
                                <span style="color: green;">余{{item.AvailableCount}}</span>
                            </button>
                        </van-col>
                    </van-row>
                    <van-row style="margin-top: 30px;" v-else>{{showTips}} </van-row>
                </van-tab>
            </van-tabs>
        </van-popup>

    </div>
</template>

<script>
import {Dialog, Tab, Tabs} from 'vant';
import Vue from 'vue';
import {ActionSheet} from 'vant';
import { Popup } from 'vant';
Vue.use(ActionSheet);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);

import axios from "axios";

export default {
    name: "doctorDetail",
    mounted() {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        this.minDate = new Date(date.setDate(date.getDate()));
        this.departmentTitle = localStorage.getItem('departmentTitle');
    },
    methods: {
        setDisableDate(date) {
            let disable;
            let doctorId = this.$route.query.DoctorId;
            const _this = this;
            const record = JSON.parse(localStorage.getItem('record'));
            try {
                record.forEach( (element) => {
                    if(_this.formatDate(date) === element.WorkDate  &&  doctorId === element.DoctorId && element.AvailableCount > 0) {
                        disable = false;
                        // _this.markDate.push(element.WorkDate)
                        throw new Error('Stop Loop'); // 跳出循环
                        // return;
                    } else {
                        disable = true; //&& element.AvailableCount > 0
                        // throw new Error('Stop Loop'); // 跳出循环
                    }
                })
            }
            catch (e) {
                if (e.message === 'Stop Loop') {
                    // console.log('Loop stopped');
                }
            }
            return disable;
        },
        clickDate(date) {
            // alert(this.formatDate(date))
            this.showPopup = true;
            this.regDate = this.formatDate(date);
            this.selectWeekday = this.regDate + ' ' + this.getWeekday(date)
            this.getDoctorDetail();
        },
        changeTabs(name, title) {
            console.log(title)
            this.active2 = title === '上午' ? 0 : 1;
            this.getDoctorDetail();
        },
        goConfirm(item) {
            this.$router.push({
                name: 'doctorConfirm',
                params: {
                    index: item
                },
                query: {
                    workId: item.WorkId,
                    doctorName: this.$route.query.DoctorName,
                    selectWeekday: this.selectWeekday,
                    periodCode: this.active2 === 0 ? '上午' : '下午', // 1上午 2下午
                    startTime: item.StartTime,
                    endTime: item.EndTime,
                    //doctorId: this.$route.query.DoctorId
                }
            });
        },
        // 日期添加备注
        formatter(day) {
            // 当前月份的日
            let dateFormat = this.formatDate(day.date);
            if (this.curNums.length < 1) {
                day.type = 'disabled';
            }

            day.type = this.filterDate(dateFormat, this.curNums) ? 'able' : 'disabled';
            if (dateFormat === this.regDate) {
                day.type = 'selected'
            }
            //提前一天预约
            if (dateFormat === this.formatDate(new Date())) {
                day.type = 'disabled';
            }
            return day
        },
        dataLeftCompleting(bits, identifier, value) {
            value = Array(bits + 1).join(identifier) + value;
            return value.slice(-bits);
        },
        //跳过后台没有返回的日期
        filterDate(date, dateList) {
            //let i = 0;
            for (let i = 0; i < dateList.length; i++) {
                if (dateList[i] === date) {
                    return true;
                }
            }
            return false;
        },
        confirmRegistration() {
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            let that = this;
            if (this.familyName === '') {
                Dialog.alert({message: '请选择就诊人'})
                return;
            }
            axios.post('/registration/save', {
                "departmentId": this.detail.departmentId,
                "departmentName": this.departmentTitle,
                "doctorId": this.detail.id,
                "doctorName": this.detail.name,
                "doctorSubtitle": this.detail.subtitle,
                "companyId": userInfo.companyId,
                "userId": userInfo.id,
                "name": localStorage.getItem('familyName'),
                "phone": localStorage.getItem('familyPhone'),
                "gender": localStorage.getItem('familyGender'),
                "birthday": localStorage.getItem('familyBirthday') === 'null' ? '' : localStorage.getItem('familyBirthday'),
                "identityCard": localStorage.getItem('familyIdentityCard') === 'null' ? '' : localStorage.getItem('familyIdentityCard'),
                "provinceId": localStorage.getItem('familyProvinceId'),
                "cityId": localStorage.getItem('familyCityId'),
                "regionId": localStorage.getItem('familyRegionId'),
                "address": localStorage.getItem('familyAddress') === 'null' ? '' : localStorage.getItem('familyAddress'),
                "modelId": localStorage.getItem('chooseProductId'),
                "date": this.regDate,
                "time": this.regTime,
                "week": this.regWeek,
                "price": this.detail.registrationFee,
            })
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                        that.$router.replace({name: 'registrationDetail', query: {id: response.data.data.id}})
                    });

                })
                .catch(function (error) {
                    console.log(error);
                    Dialog.alert({
                        message: JSON.stringify(error),
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    });
                });

        },
        openConfirm(item) {
            if (item.num <= 0) {
                return
            }
            if (this.regDate === '') {
                Dialog.alert({message: '请选择日期'})
                return
            }

            this.regWeek = this.getWeek(this.regDate);

            localStorage.setItem('regTime', item.time)
            this.regTime = item.time;
            this.show = true;
        },
        getWeek(date) {
            let weekArrayList = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六',]
            let index = new Date(date).getDay()
            return weekArrayList [index]
        },
        getWeekday(date) {
            const weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
            const weekday = new Date(date).getDay();
            return weekdays[weekday];
        },
        label(item) {
            let sex = item.gender === 1 ? '男' : '女';

            return item.relationship + '/' + sex + ' ' + item.birthday;
        },
        chooseFamily(familyInfo) {
            let that = this;
            if (familyInfo.identityCard == null || familyInfo.identityCard.length < 15) {
                Dialog.alert({
                    message: '请先完善预约人的身份证号信息',
                    messageAlign: 'left',
                }).then(() => {
                    // on confirm
                    localStorage.setItem('familyEditInfo', JSON.stringify(familyInfo))
                    that.$router.push('familyEdit')
                })
            }
            localStorage.setItem('familyId', familyInfo.id);
            localStorage.setItem('familyName', familyInfo.name);
            localStorage.setItem('familyPhone', familyInfo.phone);
            localStorage.setItem('familyGender', familyInfo.gender);
            localStorage.setItem('familyBirthday', familyInfo.birthday);
            localStorage.setItem('familyIdentityCard', familyInfo.identityCard);
            localStorage.setItem('familyProvinceId', familyInfo.provinceId);
            localStorage.setItem('familyCityId', familyInfo.cityId);
            localStorage.setItem('familyRegionId', familyInfo.regionId);
            localStorage.setItem('familyAddress', familyInfo.address);

            this.familyName = familyInfo.name;
        },
        //家庭成员
        getFamily() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('families/list', {
                    userId: userInfo.id
                }
            )
                .then(function (response) {
                    that.familyList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDoctorDetail() {
            let that = this;
            this.workTimeList = [];
            this.showTips = '正在查询，请稍后...'
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/doctor/work', {
                // "modelType": "App\\Queues",
                // "userId": userInfo.id,
                //"date": this.regDate,
                // "id": this.$route.query.index,
                "companyId": userInfo.companyId,
                "deptId": this.$route.query.deptId,
                "workDate": this.regDate,
                "periodCode": this.active2 === 0 ? 1 : 2,
                "doctorId": this.$route.query.DoctorId
            })
                .then(function (response) {
                    if(response.data.status !== 200) {
                        // alert(response.data.message)
                        that.showTips = response.data.message;
                        return;
                    }
                    that.workTimeList = response.data.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //将num左补0为len长度的字符串
        lpadNum(num, len) {
            let l = num.toString().length;
            while (l < len) {
                num = "0" + num;
                l++;
            }
            return num;
        },
        //将传入的Date格式化为"yyyyMMdd HH:mm:ss.SSS"
        formatDate(d) {
            let year = d.getFullYear();
            let month = d.getMonth() + 1;
            let day = d.getDate();

            return year + '-' + this.lpadNum(month, 2) + '-' + this.lpadNum(day, 2);
        },
        selectDate(date) {
            console.log(date)
            /*alert(this.formatDate(date))
            this.showPopup = true;
            this.regDate = this.formatDate(date);
            this.getDoctorDetail();*/
        },
    },
    created() {
        // 默认今天在第一天
        const date = new Date();
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        this.weekStart = days[date.getDay()];
    },
    data() {
        return {
            showTips: '正在查询，请稍后...',
            markDate: [],
            workTimeList: [],
            selectWeekday: '',
            active2: 0,
            weekStart: '',
            showPopup: false,
            IsSelectedDate: false,
            timeList: [],
            //可以预约的日期
            curNums: [],
            departmentTitle: '',
            department: [],
            departmentList: [],
            active: '0',
            radio1: 0,
            familyName: '',
            show: false,
            activeNames: ['1'],
            familyList: [],
            selectedDate: null,
            regWeek: '',
            regDate: '',
            regTime: '',
            minDate: new Date(2023, 0, 25),
            maxDate: new Date(2023, 1, 10),
            detail: [],
            doctorName: '',
            workDate: '',
            workWeek: '',
            periodCode: '', // 1上午 2下午
            workId: "",
            startTime: "",
            endTime: "",
        };
    }
}
</script>

<style scoped>
.goodAt {
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.van-popup--bottom {
    border-radius: 0;
}

.van-popup--round {
    border-radius: 0;
}

.van-tag--warning.van-tag--plain {
    color: #CCCCCC;
}
</style>