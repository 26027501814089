<template style="background: #EEEEEE;">
  <div style=" margin: 0 0 200px 0;">
    <van-grid :column-num="3">
      <van-grid-item icon="add-o" text="添加血压数据" @click="AddPressure(0)"/>
      <van-grid-item icon="add-o" text="添加血糖数据" @click="AddPressure(1)"/>
      <van-grid-item icon="upgrade" to="upload" text="上传处方数据"/>
      <van-grid-item icon="bar-chart-o" @click="goDetail(0)" text="最新数据"/>
      <van-grid-item icon="add-o" to="addCholesterol" text="添加胆固醇数据"/>
      <van-grid-item icon="add-o" to="addUric" text="添加尿酸数据"/>
    </van-grid>

    <div>
      <van-cell title="血压" is-link @click="goDetail(0)"/>
      <canvas id="line-chart"></canvas>
    </div>

    <div>
      <van-cell title="血糖" is-link @click="goDetail(1)"/>
      <canvas id="line-chart2"></canvas>
    </div>
    <van-divider/>

    <div>
      <van-cell title="最新数据" is-link @click="goDetail(0)"/>
      <van-cell title="血压" v-model="pressure"/>
      <van-cell title="血糖" v-model="sugar"/>
      <van-cell title="尿酸" v-model="uric"/>
      <van-cell title="胆固醇" v-model="cholesterol"/>
    </div>

    <van-divider/>

    <div style="margin-top: 20px;">
      <van-cell to="upload" title="最新图片" is-link/>
      <van-uploader style="margin-top: 20px;" v-model="preview1" v-if="preview1.length > 0"  :deletable="false" :max-count="1"/>

    </div>
  </div>
</template>
<script>
import axios from "axios";
import Chart from 'chart.js';
import comFun from '../../utils/jssdk';
import Vue from "vue";
Vue.prototype.comFun = comFun;

export default {
  name: "Report",
  data() {
    return {
      preview1: [],
      pressure: '暂无数据',
      sugar: '暂无数据',
      uric: '暂无数据',
      cholesterol: '暂无数据',
      labels1: [],
      labels2: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      userInfo: [],
    };
  },
    mounted() {
        document.title = "血压血糖管理";
        this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));
        let that = this;
        let intervalId = setInterval(() => {
            //TODO 第一次进入如果没有缓存不会一直执行？？？
            if (that.userInfo != null) {
                that.getPressure()
                clearInterval(intervalId);
            }
        }, 1000);
    },
  created() {
    let appid = this.comFun.getAppid();
    if (appid === undefined) {
      appid = this.$route.query.appid;
    }
    if (appid !== undefined) {
      localStorage.setItem('appid', appid);
      //this.getCompanyInfo(appid);
    } else {
      appid = localStorage.getItem('appid');
      //this.getCompanyInfo(appid);
    }
    let code = this.comFun.getQueryVariable('code');
    if (localStorage.getItem('userInfo' + appid) == null) {
      if (code === false || code.length < 10) {
        const appid2 = this.$route.query.appid;
        if (localStorage.getItem('appid') === undefined) {
          localStorage.setItem('appid', appid2);
        }
        this.comFun.getCode(appid2);
      } else {
        this.getUserInfo();
      }
    } else {
      this.comFun.initJsSDK();
    }
  },
  methods: {
      getUserInfo() {
          let code = this.comFun.getQueryVariable('code');
          let appid = this.comFun.getQueryVariable('appid');
          //alert('code = ' + code)
          let that = this;
          //请求文章列表
          axios.post('/wechat/user', {
              "appId": appid,
              "code": code
          })
              .then(function (response) {
                  //alert(JSON.stringify(response.data.data))
                  localStorage.setItem('userInfo' + appid, JSON.stringify(response.data.data))
                  localStorage.setItem('companyId', JSON.stringify(response.data.data.companyId))
                  localStorage.setItem('userId', JSON.stringify(response.data.data.id))
                  that.userInfo = response.data.data;
                  that.initConfig = true;
                  that.comFun.initJsSDK();
                  //that.getClinicInfo();
              })
              .catch(function (error) {
                  alert(error.message)
              });

      },
    showImage() {
      const that = this;
      axios.post('/chronic/image', {
        "userId": this.userInfo.id,
        "companyId": this.userInfo.companyId,
        "type": '1,2,3',
        "limit":10
      })
          .then(function (response) {
            response.data.data.forEach(function (element) {
                  that.preview1.push({url: element.url, isImage: true})
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    showPressure() {
      const ctx = document.getElementById('line-chart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.labels1,
          datasets: [{
            label: '高压',
            data: this.data1,
            fill: false,
            borderColor: 'rgb(200, 150, 150)',
            tension: 0.1
          }, {
            label: '低压',
            data: this.data2,
            fill: false,
            borderColor: 'rgb(150, 200, 150)',
            tension: 0.1
          }, {
            label: '心率',
            data: this.data3,
            fill: false,
            borderColor: 'rgb(150, 150, 200)',
            tension: 0.1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true
              }
            }]
          }
        }
      });
    },
    showSugar() {
      const ctx2 = document.getElementById('line-chart2').getContext('2d');
      new Chart(ctx2, {
        type: 'line',
        data: {
          labels: this.labels2,
          datasets: [{
            label: '血糖',
            data: this.data4,
            fill: false,
            borderColor: 'rgb(150, 150, 200)',
            tension: 0.1
          }]
        },
        options: {
          pointDot: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero:true
              }
            }]
          }
        }
      });
    },
    AddPressure(index) {
      this.$router.push({
        name: 'addBloodPressure',
        params: {
          index: index
        },
        query: {
          index: index
        }
      });
    },
    goDetail(index) {
      this.$router.push({
        name: 'myData',
        params: {
          index: index
        },
        query: {
          index: index
        }
      });
    },
    //获取血压数据
    getPressure() {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 1,
        "startDate": '2024-01-01'
      })
          .then(function (response) {
            response.data.data.forEach(function (element) {
              that.labels1.push(element.dateTime.substring(5));
              that.data1.push(element.value1);
              that.data2.push(element.value2);
              that.data3.push(element.value3);
            })

            that.showPressure();
            //最新数据
            if (that.data1.length > 0 && that.data2.length > 0)
              that.pressure = that.data1.at(that.data1.length - 1) + '/' + that.data2.at(that.data2.length - 1) + ' mmHg'

            that.getSugar();

          })
          .catch(function (error) {
            //alert(error)
            console.log("请求失败 " + JSON.stringify(error));
            //返回首页
            //this.$router.replace({name: 'index'})

          });
    },
    //获取血糖数据
    getSugar() {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 2,
        "startDate": '2024-01-01'
      })
          .then(function (response) {
            response.data.data.forEach(function (element) {
              that.labels2.push(element.dateTime.substring(5));
              that.data4.push(element.value1);
            })

            that.showSugar();
            if (that.data4.length > 0)
              that.sugar = that.data4.at(that.data4.length - 1) + ' mmol/L';


            that.getLast();

          })
          .catch(function (error) {
            //alert(error)
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
    //获取最新数据
    getLast() {
      const that = this;
      axios.post('/chronic/index2', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
      })
          .then(function (response) {
            if (response.data.data.length > 0) {
              that.uric = response.data.data[0] != null ? response.data.data[0].value1 + ' umol/L': '暂无数据';
              that.cholesterol = response.data.data[1].value1 + ' mmol/L';
            } else {
              alert('暂无数据')
            }
            that.showImage();
          })
          .catch(function (error) {
            //alert(error)
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
  }

}

</script>
<style scoped>
.van-cell__value {
  color: #111111;
  font-size: large;
}

.van-grid-item-sub-text {
  color: #cecece;
  font-size: small;
  white-space: nowrap
}

.van-grid-item-text {

  color: #555555;
  white-space: nowrap;
  font-weight: 500;
}

</style>