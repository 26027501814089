<template>
  <div>
    <div v-for="(item,index) in dataList" :key="index">
    <van-cell :title="item.date" />
      <van-row v-for="(child,index2) in item.child" :key="index2">
        <van-col span="6"><van-icon name="clock" />{{child.time}}</van-col>
        <van-col span="6">{{child.value1}} </van-col>
        <van-col span="6">μmol/L</van-col>
        <van-col span="6">{{ uricRange(child.value1)}}</van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "allUricData",
  data() {
    return {
      dataList: [],
      fileList: [],
      activeTab: 0,
      type: '',
      userInfo: [],
    };
  },
  mounted() {
    document.title = '所有尿酸数据'
    this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));
    setTimeout(() => {
      this.getData()
    }, 200);
  },
  methods: {
    /*女性尿酸的正常值范围为89μmo1/L-357μmol/L,;男性尿酸的正常值是149μmol/L-416μmol/L*/
    uricRange(str) {
      if(str > 416) {
        return '偏高';
      }
      if(str < 89) {
        return '偏低';
      }else {
        return '正常';
      }
    },
    //获取血压数据
    getData() {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 3,
        "limit": 300,
        "startDate": '2024-01-01'
      })
          .then(function (response) {
            let tempDate = '';
            //先组装第一层列表date
            response.data.data.forEach(function (element) {
              if (element.date !== tempDate) {
                tempDate = element.date;
                that.dataList.push(element);
              }
            })
            //将第二层元素组装到child字段里面
            for (let i = 0; i < that.dataList.length; i++) {
              if(that.dataList[i].child === undefined) {
                that.dataList[i].child = [];
              }
              response.data.data.forEach(function (element) {
                if (element.date === that.dataList[i].date) {
                  that.dataList[i].child.push(element);
                }
              })
            }
          })
          .catch(function (error) {
            alert(error)
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
  }

}
</script>
<style scoped>
.van-icon-like::before {
  color: red;
}
.van-col--6 {
  line-height: 2;
}
.van-cell {
  background: #EEEEEE;
}
</style>