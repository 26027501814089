<template >
  <div style="background: #f7f8fd;width: 100%;">
    <div style="height: 70px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">

      <div style="line-height: 70px;padding-left: 20px;font-size: medium;font-weight: 550;">
        系统公告
      </div>
    </div>
    <span v-for="(item, index) in reverseList" :key="index">
         <div style="line-height: 30px;text-align: center;font-size: medium;margin-top: 20px;">
            {{item.created_at}}
          </div>

        <div style="height: 160px;background: white;border-radius: 6px;margin:5px 15px; box-shadow:6px 6px 9px #eee;" >


        <div style="padding: 20px; text-align: left;">
          <span  style="text-align: left;width: 100%; font-size: large;">
            {{item.title}}</span>

        </div>

        <div class="van-multi-ellipsis--l3" style="overflow: hidden; text-align: left;height: 40px; padding: 10px 20px;">
            {{item.description | ellipsis}}
         </div>
        <van-divider style="margin: 0;"/>
        <van-cell title="查看详情" is-link @click="goNoticeDetail(item)" />
      </div>
    </span>

  </div>
</template>

<script>
import Vue from 'vue';
import { Panel } from 'vant';
import { Cell, CellGroup } from 'vant';
import axios from "axios";

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Panel);
export default {
  name: "noticeList",
  data() {
    return {
      reverseList: [],

    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 32) {
        return value.slice(0, 32) + '...'
      }
      return value
    },
  },
  beforeCreate() {
    //进入页面设置背景
    document.querySelector('body').setAttribute('style','background: #f7f8fd');
  },
  beforeDestroy() {
    //退出页面设置背景
    document.querySelector('body').setAttribute('style','background: #fff');
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    this.getReverseList();
  },
  computed: {
    swipeHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕高

      return height + 'px';
    }
  },
  methods:{
    goNoticeDetail(item){
      this.$router.push({name:'noticeDetail', query:{id: item.id}})
    },
    //针次
    injection(inject){
      let status;
      this.injectionNumber.forEach(function(element) {
        console.log('element = ' + JSON.stringify(element))
        if(inject === element.value) {
          status = element.text
        }
      });

      return status;
    },

    getReverseList() {
      let that = this;
      axios.get('/api/aggregate',{
        params: {
          "model_type":"notice",
          "page":1,
          "per_page":1,
          "company_ids":localStorage.getItem('goClinicId')
        }
      })
          .then(function (response) {
            that.reverseList = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },

  }
}
</script>

<style scoped>

</style>