<template>
    <div>
        <van-tabs v-model="activeTab"  @click="onTabChange">
            <van-tab title="要点">
                <span v-for="(item, index) in articleList" :key="index" @click="goDetail(item.id);">
              <div style="width: 100%;">
                <div style="height: 80px;margin: 15px; ">
                  <div style="width: 70%;float: left;">
                    <div style="text-align: left;">
                      <div class="van-multi-ellipsis--l2" style="font-size: medium;font-weight: 550;color: #565656;">
                        {{ item.title }}
                      </div>
                    </div>
                    <div style="text-align: left;float: left;margin-top: 20px;">
                      <span style="font-size: small;color: steelblue;">
                        #{{ item.subtitle }}
                      </span>
                      <span style="font-size: small;margin-left: 20px;color: #565656;">
                        {{ item.createdAt }}
                      </span>
                    </div>
                  </div>
                  <div style=" float: right; height: 30px; width: 30%; text-align: end;">
                    <van-image
                        cover
                        width="100px"
                        height="80px"
                        :src=item.thumb
                    />
                  </div>
                </div>
                <van-divider/>
              </div>
            </span>
            </van-tab>
            <van-tab title="0-1岁">
                <div v-for="(item,index) in dataList" :key="index">
                    <van-cell style="background: #EEEEEE;" :title="item.title"/>
                    <div v-for="(child,index2) in item.child" :key="index2">
                        <span @click="goList(child.id)" style="width: 33%;display: block;float: left;margin: 10px 0 ;"> {{ child.title }}</span>
                    </div>
                </div>
            </van-tab>
            <van-tab title="1-3岁">
                <div v-for="(item,index) in dataList" :key="index">
                    <van-cell style="background: #EEEEEE;" :title="item.title"/>
                    <div v-for="(child,index2) in item.child" :key="index2">
                        <span @click="goList(child.id)"  style="width: 33%;display: block;float: left;margin: 10px 0 ;"> {{ child.title }}</span>
                    </div>
                </div>
            </van-tab>
            <van-tab title="3-6岁">
                <div v-for="(item,index) in dataList" :key="index">
                    <van-cell style="background: #EEEEEE;" :title="item.title"/>
                    <div v-for="(child,index2) in item.child" :key="index2">
                        <span @click="goList(child.id)"  style="width: 33%;display: block;float: left;margin: 10px 0 ;"> {{ child.title }}</span>
                    </div>
                </div>
            </van-tab>
        </van-tabs>


    </div>


</template>

<script>
import Vue from 'vue';
import {Divider} from 'vant';
import axios from "axios";

Vue.use(Divider);
export default {
    name: "parentingCategory",
    data() {
        return {
            articleList: [],
            dataList: [
                {
                    "parent": "成长须知",
                    "child": [{"title": "0～2个月"}, {"title": "3～5个月"}, {"title": "6～8个月"}, {"title": "9～11个月"}]
                },
                {
                    "parent": "母乳喂养",
                    "child": [{"title": "哺乳方法"}, {"title": "饥饱判断"}, {"title": "背奶"}, {"title": "断奶"}]
                }
            ],
            activeTab: 0,
        }
    },
    mounted() {
        //TODO 获取用户所选的生日范围
        document.title = '全部文章分类'
        this.getRecommend('0-1岁')
    },

    methods: {
        onTabChange(index, title) {
            console.log('title ============================ ');
            console.log(title);

            switch (index) {
                case 1:
                    this.category(title)
                    break;
                case 2:
                    this.category(title)
                    break;
                case 3:
                    this.category(title)
                    break;
                default:
                    //TODO 获取用户所选的生日范围
                    this.getRecommend('0-1岁')
            }
        },
        goDetail(itemId) {
            this.$router.push({name: 'parentingArticle', query: {id: itemId}})
        },
        goList(itemId) {
            this.$router.push({name: 'parentingList', query: {id: itemId}})
        },
        //文章列表
        getRecommend(tag) {
            let that = this;
            //let appid = localStorage.getItem('appid');
            //let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/parenting/recommend', {
                "tag": tag,
                "limit": 100,
            })
                .then(function (response) {
                    that.articleList = response.data.data;
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
        //分类列表
        category(tag) {
            let that = this;
            this.dataList = [];
            //let appid = localStorage.getItem('appid');
            //let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/parentingCategory/list', {
                "tag": tag
            })
                .then(function (response) {
                    let tempDate = '';
                    //先组装第一层列表date
                    response.data.data.forEach(function (element) {
                        if (element.parentId === 0 && element.title !== tempDate) {
                            tempDate = element.title;
                            that.dataList.push(element);
                        }
                    })
                    //将第二层元素组装到child字段里面
                    for (let i = 0; i < that.dataList.length; i++) {
                        if(that.dataList[i].child === undefined) {
                            that.dataList[i].child = [];
                        }
                        response.data.data.forEach(function (element) {
                            if (element.parentId === that.dataList[i].id) {
                                that.dataList[i].child.push(element);
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>
.van-grid-item__content--square {
    border-radius: 15px;
}

.van-image__img {
    width: 76%;
    vertical-align: middle;
}

.van-grid-item-sub-text {
    color: #cecece;
    font-size: small;
    white-space: nowrap
}

.van-grid-item-text {
    font-size: 0.87rem;
    color: #555555;
    white-space: nowrap;
    font-weight: 500;
}
</style>