<template>
  <div>
    <van-image
        width="100%"
        height="100%"
        src="http://vaccine.images.wxdev.top/分享0414.jpg"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import {Image as VanImage} from 'vant';
Vue.use(VanImage);
export default {
  name: "Share",
  methods: {
    share() {
      //this.$router.replace({name:"https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIxMjIwNDY1MQ==&scene=124&uin=&key=&devicetype=Windows+10+x64&version=63010043&lang=zh_CN&a8scene=7&fontgear=2"});
      //this.$router.back()
    }
  },
  mounted: function () {
    this.share();
  }
}
</script>

<style scoped>

</style>