<template>
  <div   style="background: white">
    <div style="height: 170px; ">
      <div style="height: 170px;background: rgb(245,249,250);border-radius: 8px;margin:15px; ">
        <div style="text-align: left;  ;font-size: medium;padding: 10px 10px 0 10px;font-weight: 550;">
          为什么要扫码确认？
        </div>
        <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
          若完成接种后未确认，预期系统会取消预约单，导致后续针次无法正常接种。
        </div>
        <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
          便于及时接收后续服务通知提醒。
        </div>
        <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
          保证接种/体检记录的准确性。
        </div>
      </div>

      <div style="margin-top: 10px;">
        <div style="height: 100px;background: rgb(245,249,250);border-radius: 8px;margin:15px; ">
          <div style="text-align: left;  font-size: medium;padding: 10px 10px 0 10px;font-weight: 550;">
            如何扫码确认？
          </div>
          <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
            完成接种/体检筛查后，点击下方扫码按钮扫码机构提供的确认二维码，确认对应预约单。
          </div>
        </div>
      </div>

      <div style="margin-top: 65px;">
        <van-image @click="scan" width="135" height="135" src="http://vaccin-image.wxdev.top/scan11.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Collapse, CollapseItem} from 'vant';
import {Form} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Calendar} from 'vant';
import {ActionSheet} from 'vant';
//import {Toast} from 'vant';
import axios from "axios";
import {Dialog} from 'vant';
import { Image as VanImage } from 'vant';
import wx from 'weixin-js-sdk'

Vue.use(VanImage);
// 全局注册
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Calendar);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(Collapse);
Vue.use(CollapseItem);
export default {
  name: "scan",
  data() {
    return {
      radio1: 0,
      radio2: '1',
      activeNames: ['1'],
      show: false,
      showActionSheet: false,
      actions: [],
      vaccineInfo: [],
      clinicInfo: [],
      familyList: [],
      message: '如果排队成功将会收到通知,收到通知后请及时打开排队信息填写预约接种时间。',
      familyName:'',
      injectionNumber:[
            {value:1,text:"第一针"},{value:2,text:"第二针"},{value:3,text:"第三针"},{value:4,text:"第四针"},{value:5,text:"第五针"},
            {value:6,text:"第六针"},{value:7,text:"第七针"},{value:8,text:"第八针"},{value:9,text:"第九针"},{value:10,text:"第十针"},
            {value:11,text:"第十一针"},{value:12,text:"第十二针"},{value:13,text:"第十三针"},{value:14,text:"第十四针"},{value:15,text:"第十五针"}
          ],
      injectionTotal:1
    };
  },
  mounted() {
    //进入页面清空缓存
    localStorage.removeItem('injectionValue')
    localStorage.removeItem('familyId')

    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");

    //this.getClinicInfo();
    //this.getVaccineInfo();
    //this.getFamily();

    //工作时间
    //this.getWorkHours()
  },
  computed: {
    swipeHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕高
      return height + 'px';
    }
  },
  methods: {
    scan(){
      //let that = this;
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          //alert("扫码结果 = "+ JSON.stringify(res))
          //that.$router.push(result)
          //http://p.eshekang.com/#/reserveDetail?id=1631
          window.location.href = location.href.split('#')[0] + result;
        }
      });
    },
    chooseInjection(injectionValue,injectionText) {
      localStorage.setItem('injectionValue',injectionValue);
      localStorage.setItem('injectionText',injectionText);
    },
    chooseFamily(familyInfo){
      localStorage.setItem('familyId',familyInfo.id);
      localStorage.setItem('familyName',familyInfo.name);
      localStorage.setItem('familyGender',familyInfo.gender);
      localStorage.setItem('familyBirthday',familyInfo.birthday);
      localStorage.setItem('familyIdentityCard',familyInfo.identity_card);
      localStorage.setItem('familyProvinceId',familyInfo.province.id);
      localStorage.setItem('familyCityId',familyInfo.city.id);
      localStorage.setItem('familyRegionId',familyInfo.region.id);
      localStorage.setItem('familyAddress',familyInfo.address);

      this.familyName = familyInfo.name;
    },
    //提交
    queue() {
      if(localStorage.getItem('familyId') === null || localStorage.getItem('familyId') === undefined) {
        Dialog.alert({
          message: '请选择接种人',
        });
        return ;
      }
      if(localStorage.getItem('injectionValue') === null || localStorage.getItem('familyId') === undefined) {
        Dialog.alert({
          message: '请选择针次',
        });
        return ;
      }
      let that = this;
      axios.post('/api/queue/product',{
        "name": localStorage.getItem('familyName'),
        "gender": localStorage.getItem('familyGender'),
        "birthday": localStorage.getItem('familyBirthday'),
        "identity_card": localStorage.getItem('familyIdentityCard') === 'null'? '': localStorage.getItem('familyIdentityCard'),
        "province_id": localStorage.getItem('familyProvinceId'),
        "city_id": localStorage.getItem('familyCityId'),
        "region_id": localStorage.getItem('familyRegionId'),
        "address": localStorage.getItem('familyAddress') === 'null' ? '' : localStorage.getItem('familyAddress') ,
        "model_id": localStorage.getItem('chooseVaccineId'),

        "injection_total":localStorage.getItem('injectionValue')
      })
          .then(function (response) {
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
              confirmButtonColor: 'black'
            }).then(() => {
              // on close
              //if(response.data.code === 200)
                that.$router.back();
            });

          })
          .catch(function (error) {
            console.log(error);
          });

    },
    label(item) {
      let sex = item.gender === 1 ? '男' : '女';

      return item.relationship + '/' + sex + ' ' + item.birthday;
    },
    //疫苗信息
    getVaccineInfo() {
      let that = this;
      axios.get('/api/vaccine/product/' + localStorage.getItem('chooseVaccineId')
      )
          .then(function (response) {
            that.vaccineInfo = response.data.data;
            if (that.vaccineInfo.description != null) {
              //that.message = that.vaccineInfo.description;
            }
            //针次
            that.injectionTotal = that.vaccineInfo.vaccine.injection_total;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //机构信息
    getClinicInfo() {
      let that = this;
      axios.get('/api/company/' + localStorage.getItem('chooseClinicId')
      )
          .then(function (response) {
            that.clinicInfo = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //家庭成员
    getFamily() {
      let that = this;
      axios.get('/api/family/'
      )
          .then(function (response) {
            that.familyList = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //工作时间
    getWorkHours() {
      let that = this;
      axios.get('/api/vaccine/product/' + localStorage.getItem('chooseVaccineId')
      )
          .then(function (response) {
            //let {name: '8:00 - 11:00'}, {name: '14:00 - 16:30'}
            //遍历对象
            let arr = response.data.data.company.working_hours.time;
            //alert(JSON.stringify( arr  ))
            for (let i = 0; i < arr.length; i++) {
              /*if () {
                # TODO 如果还没约完次数
              }*/
              console.log(JSON.stringify( arr[i]))
              that.actions.push({ name: arr[i].between});
            }

            //that.actions = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    showPopup() {
      this.show = true;
    },

    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.showActionSheet = false;
      this.chooseTime = item.name;
      //Toast(item.name);
    },
  }
}
</script>

<style scoped>
.van-collapse-item {
  text-align: left;
}

.van-cell__title {
  text-align: left;
}

.van-radio-group--horizontal {
  background: white;
  height: 45px;
}

.van-radio__label {
  margin: 10px;
}

/*.van-radio__icon {
  margin-left: 20px;
}*/
.van-radio--horizontal {
  margin: 10px 15px;
}

.submit-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}

.van-dialog {
  border-radius: 6px;
}
</style>