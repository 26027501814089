<template>
    <div class="main container">
        <div class="news_details">
            <div style="text-align: left;margin: 0 15px;">
                <h2>{{ detail.title }}</h2>
                <span style="width:100%;text-align: left;font-size: small;">发布时间: {{ detail.createdAt }}</span>
                <div class="news_content">
                    <p v-html="detail.content" class="content" style="font-size:32px;width: 100%;overflow: hidden;"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from "axios";

export default {
    name: "parentingArticle",
    data() {
        return {
            radio1: 0,
            detail: [],
            title: '文章详情',
        }
    },
    mounted() {
        document.title = this.title;
        this.getArticleInfo();
    },
    methods:{
        getArticleInfo() {
            let that = this;
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/parenting/info', {
                id: this.$route.query.id,
            })
                .then(function (response) {
                    document.title = response.data.data.title;
                    that.detail = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>