<template>
    <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
        <div style="height: 450px;background: white;">

            <div style="padding: 20px;">
                <span style="float: left;font-weight: 550;font-size: medium">{{ JSON.parse(detail.remarks).vaccine.title }}</span>
                <!--<span style="text-align: right;float: right;color: red;">￥235.00</span>-->
            </div>
            <van-divider/>

            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">生产厂家</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ JSON.parse(detail.remarks).vaccine.manufacturer }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">产品规格</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ JSON.parse(detail.remarks).vaccine.specifications }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">接种人</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ detail.name }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">针次</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ detail.injectionTotal }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">创建时间</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ detail.createdAt }}</span>
            </div>

            <van-cell-group :border="false">
                <van-cell title="疫苗费用" label="疫苗费用到门诊现场支付" :value="vaccinePrice"/>
            </van-cell-group>


            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">接种门诊</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ JSON.parse(detail.remarks).company.title }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">门诊地址</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ JSON.parse(detail.remarks).company.address }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: 14px">门诊电话</span>
                <span style="text-align: right;float: right; font-size: 14px;color: #565656;">{{ JSON.parse(detail.remarks).company.telephone }}</span>
            </div>

            <div style="padding: 20px;background: white;">
                <span style="float: left;font-weight: 550;font-size: medium">接种须知</span>
            </div>

            <van-cell-group :border="false">
                <van-cell title="什么情况下不能接种或需要推迟接种？" to="Warning" style="color: #39a9ed;"/>
            </van-cell-group>

            <div style="margin: 5px 0 0 0 ;padding:25px;background: white;">
                <span style="float: left;font-weight: 550;font-size: medium">排队说明</span>
            </div>
            <div style="padding:20px;background: white;float: left;margin-bottom: 60px;font-size: 14px;text-align: left;">
                {{ message }}
            </div>

        </div>

        <div class="submit-bar" style="border:solid;border-width:thin 0 0 0;">

            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 100px; margin: 10px 20px;" @click="confirmCancel">
                取消排队
            </van-button>


        </div>
    </div>

</template>

<script>
import Vue from 'vue';
import {Cell, CellGroup, Dialog} from 'vant';
import {GoodsAction, GoodsActionIcon, GoodsActionButton} from 'vant';
import {Toast} from 'vant';
import axios from "axios";

Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Cell);
Vue.use(CellGroup);
export default {
    name: "queueDetail",
    data() {
        return {
            /*vaccineInfo: [],
            vaccinePrice:'',
            clinicInfo: [],
            dateTime:'',//接种时间
            familyName:'',
            injectionText:'',*/
            vaccinePrice: '',
            message: '如果排队成功将会收到通知,收到通知后请及时打开排队信息填写预约接种时间。',
            detail: []
        };
    },
    beforeCreate() {
        //进入页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #f7f8fd');
    },
    beforeDestroy() {
        //退出页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #fff');
    },
    computed: {
        swipeHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕高
            return height + 'px';
        }
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        this.getOrderInfo(this.$route.query.id)
    },
    methods: {
        goReverseEdit(id) {
            this.$router.push({name: 'reserveEdit', query: {id: id}})
        },
        confirmVaccinate() {
            Dialog.confirm({
                message: '确认已经完成疫苗接种吗？确认后将不能再次修改。',
                messageAlign: 'left'
            })
                .then(() => {
                    // on confirm
                    this.confirmReserve()
                })
                .catch(() => {
                    // on cancel
                });
        },
        confirmCancel() {
            Dialog.confirm({
                message: '确认要取消该疫苗排队吗？取消后无法及时接收到苗通知。',
                messageAlign: 'left'
            })
                .then(() => {
                    // on confirm
                    this.deleteReserve()
                })
                .catch(() => {
                    // on cancel
                });
        },
        confirmReserve() {
            axios.post('/api/order/product/state/' + this.$route.query.id + '/2', {})
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        cancelReserve() {
            axios.post('/api/order/product/state/' + this.$route.query.id + '/-3', {})
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        deleteReserve() {
            let that = this;
            axios.post('/queues/del', {
                id: this.$route.query.id
            })
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                    }).then(() => {
                        // on confirmqueue
                        that.$router.back()
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onClickIcon() {
            Toast('点击图标');
        },
        onClickButton() {
            Toast('点击按钮');
        },
        //订单信息
        getOrderInfo(id) {
            let that = this;
            axios.post('/queues/info', {
                id: id
            })
                .then(function (response) {
                    that.detail = response.data.data;
                    that.vaccinePrice = '￥' + that.detail.price;
                    that.getVaccineInfo(response.data.data.modelId);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //疫苗信息
        getVaccineInfo(productId) {
            //alert(localStorage.getItem('chooseProductId'))
            let that = this;

            axios.post('/products/info', {
                    id: productId
                }
            )
                .then(function (response) {
                    //that.vaccineInfo = response.data.data.vaccine;
                    //that.productInfo = response.data.data.product;
                    if (response.data.data.product.queuingDescription != null) {
                        that.message = response.data.data.product.queuingDescription;
                    }
                    Dialog.alert({
                        title: '排队说明',
                        message: that.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //机构信息
        /* getClinicInfo() {
           let that = this;
           axios.get('/api/company/' + localStorage.getItem('chooseClinicId')
           )
               .then(function (response) {
                 that.clinicInfo = response.data.data;
               })
               .catch(function (error) {
                 console.log(error);
               });
         },*/
    },
}
</script>

<style scoped>
.van-grid-item__content {
    padding: 16px 1px;
}

.van-collapse-item {
    text-align: left;
}

.van-cell__title {
    text-align: left;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}
</style>