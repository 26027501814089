<template>
  <div>
    <div style="height: 50px;background: white;">
      <div style="padding: 15px;">
        <span style="float: left;font-size: large;">{{ detail.title }}</span>
       </div>
      <van-divider/>
    </div>
    <div >
      <div style="text-align: left; font-size: medium; padding: 10px;margin: 10px;">
        <p v-html="detail.content" class="content" style="width: 100%;overflow: hidden;" ></p>
      </div>
    </div>

    <div style="height: 20px;padding: 0 20px;margin-top: 40px;">
         <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ detail.company.title }}</span>
    </div>
    <div style="height: 20px;padding: 0 20px;">
         <span style="text-align: right;float: right; font-size: small;color: #565656;">
            {{ detail.created_at }}</span>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import {Button} from 'vant';
import axios from "axios";

Vue.use(Button);
export default {
  name: "NoticeDetail",
  data() {
    return {
      detail: [],
      message: "机构到苗后将发送到苗通知，请留意\"预约疫苗服务\"公众号[服务进度提醒]，收到提醒后请及时预约，预约成功后即可进行接种。"
    }
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");

    this.getNoticeDetail()
  },
  methods:{
    getNoticeDetail() {
      let that = this;
      axios.get('/api/aggregate/' + this.$route.query.id)
          .then(function (response) {
            that.detail = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
  }
}
</script>

<style lang="less">
.van-dialog {
  border-radius: 8px;
  background: white url(../../assets/dialogLogo.png) no-repeat ;
}
</style>