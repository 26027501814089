<template>
  <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
    <div style="height: 483px;background: white;">

      <div style="padding: 20px;">
        <span style="float: left;font-weight: 550;font-size: medium">就诊信息</span>
        <!--<span style="text-align: right;float: right;color: red;">￥{{JSON.parse(detail.remarks).product.price}}</span>-->
      </div>
      <van-divider/>

      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">就诊人</span>
        <span
            style="text-align: right;float: right; font-size: medium;color: #565656;">{{
            detail.name
          }}</span>
      </div>
      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">就诊序号</span>
        <span
            style="text-align: right;float: right; font-size: medium;color: coral;">
            {{ detail.number }}
           </span>
      </div>
      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">候诊时间</span>
        <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{ detail.date }} {{ detail.time }}</span>
      </div>
      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">就诊科室</span>
        <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{ detail.departmentName }}</span>
      </div>
      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">手机号</span>
        <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{ detail.phone }}</span>
      </div>
      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">医生姓名</span>
        <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{ detail.doctorName }} / {{ detail.doctorSubtitle }} </span>
      </div>
      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">创建时间</span>
        <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{ detail.createdAt }}</span>
      </div>

      <van-cell-group :border="false">
        <van-cell title="费用" label="费用请到门诊现场支付" :value=vaccinePrice />
      </van-cell-group>


      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">机构名称</span>
        <span
            style="text-align: right;float: right; font-size: medium;color: #565656;">{{
            company.title
          }}</span>
      </div>

      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">科室地址</span>
        <span
            style="text-align: right;float: right; font-size: medium;color: #565656;">{{
            company.address
          }}</span>
      </div>

      <div style="height: 30px;padding: 0 20px;">
        <span style="float: left;color: #bcbcbc;font-size: small">门诊电话</span>
        <span
            style="text-align: right;float: right; font-size: medium;color: #565656;">
            {{ company.telephone }}
          </span>
      </div>

<!--      <div style="margin: 20px 0 0 0 ;padding:20px;background: white;">
        <span style="float: left;font-weight: 550;font-size: medium">接种须知</span>
      </div>

      <van-cell-group :border="false">
        <van-cell title="什么情况下不能接种或需要推迟接种？" to="Warning" style="color: #39a9ed;"/>
      </van-cell-group>
    </div>-->

    <div class="submit-bar" v-if="operate">
        <span v-if="detail.state === -10">
        <van-button disabled color="linear-gradient(to right, #8dc3f9, #1989fa)"
                    style="border-radius: 5px;width: 100px; margin: 10px 20px;" >
          订单已删除
        </van-button>
      </span>
      <span v-else-if="detail.state === -3 || detail.state === 2">
        <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                    style="border-radius: 5px;width: 100px; margin: 10px 20px;" @click="deleteReserve">
          删除订单
        </van-button>
      </span>
      <span v-else>
        <van-grid :border="false" :column-num="2">
<!--          <van-grid-item>
            <van-button style="height: 36px;padding: 16px 10px;" plain @click="goReverseEdit(detail)">
              修改时间
            </van-button>
          </van-grid-item>-->
          <van-grid-item>
            <van-button style="height: 36px;padding: 16px 10px;" plain @click="confirmCancel">
              取消预约
            </van-button>
          </van-grid-item>
          <van-grid-item>
            <van-button style="height: 36px;white-space:nowrap;padding: 16px 10px;"
                        color="linear-gradient(to right, #8dc3f9, #1989fa)" @click="confirmVaccinate">
              确认已就诊
            </van-button>
          </van-grid-item>
        </van-grid>
      </span>

  </div>
  </div>
  </div>

</template>

<script>
import Vue from 'vue';
import {Cell, CellGroup, Dialog} from 'vant';
import {GoodsAction, GoodsActionIcon, GoodsActionButton} from 'vant';
import {Toast} from 'vant';
import axios from "axios";

Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Cell);
Vue.use(CellGroup);
export default {
  name: "registrationDetail",
  data() {
    return {
      operate: true,
      /*vaccineInfo: [],
      vaccinePrice:'',
      clinicInfo: [],
      dateTime:'',//接种时间
      familyName:'',
      injectionText:'',*/
      vaccinePrice: '',
      detail: [],
      company: [],
    };
  },
  beforeCreate() {
    //进入页面设置背景
    document.querySelector('body').setAttribute('style', 'background: #f7f8fd');
  },
  beforeDestroy() {
    //退出页面设置背景
    document.querySelector('body').setAttribute('style', 'background: #fff');
  },
  computed: {
    swipeHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕高
      return height + 'px';
    }
  },
  mounted() {
    axios.defaults.headers.common.Authorization = localStorage.getItem("token");
    this.getOrderInfo(this.$route.query.id)
  },
  methods: {
    goReverseEdit(detail) {
      this.$router.push({name: 'reserveEdit', query: {id: detail.id, productId: detail.product.id}})
    },
    confirmVaccinate() {
      Dialog.confirm({
        message: '确认已经完成就诊吗？确认后将不能再次修改。',
        messageAlign: 'left'
      })
          .then(() => {
            // on confirm
            this.confirmReserve()
          })
          .catch(() => {
            // on cancel
          });
    },
    confirmCancel() {
      Dialog.confirm({
        message: '确认要取消该预约吗？。',
        messageAlign: 'left'
      })
          .then(() => {
            // on confirm
            this.cancelReserve()
          })
          .catch(() => {
            // on cancel
          });
    },
    confirmReserve() {
      let that = this;
      axios.post('/registration/state', {
        state: '2',
        id: this.$route.query.id
      })
          .then(function (response) {
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
            })
            that.getOrderInfo(that.$route.query.id)
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    cancelReserve() {
      let that = this;
      axios.post('/registration/state', {
        state: '-3',
        id: this.$route.query.id
      })
          .then(function (response) {
            if (response.data.status === 200) {
              Dialog.alert({
                message: "取消成功",
                messageAlign: 'left',
              })/*.then(() => {
                //that.$router.go(0)
              })*/
              that.getOrderInfo(that.$route.query.id)
            } else {
              Dialog.alert({
                message: response.data.message,
                messageAlign: 'left',
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    deleteReserve() {
      let that = this;
      axios.post('/registration/del',
          {
            id: this.$route.query.id
          })
          .then(function (response) {
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
            }).then(() => {
              // on confirm
              that.$router.back()
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    onClickIcon() {
      Toast('点击图标');
    },
    onClickButton() {
      Toast('点击按钮');
    },
    //订单信息
    getOrderInfo(id) {
      let that = this;
      axios.post('/registration/info', {
        id: id
      })
          .then(function (response) {
            /*const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (response.data.data.userId !== userInfo.id) {
              Dialog.alert({message: '非本人订单,此订单姓名为:' + response.data.data.name, messageAlign: 'left'})
              that.operate = false
            }*/
            that.detail = response.data.data.detail;
            that.company = response.data.data.company;
            //that.vaccinePrice = '￥' + that.detail.price;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //机构信息
    /* getClinicInfo() {
       let that = this;
       axios.get('/api/company/' + localStorage.getItem('chooseClinicId')
       )
           .then(function (response) {
             that.clinicInfo = response.data.data;
           })
           .catch(function (error) {
             console.log(error);
           });
     },*/
  },
}
</script>

<style scoped>
.van-grid-item__content {
  padding: 16px 1px;
}

.van-collapse-item {
  text-align: left;
}

.van-cell__title {
  text-align: left;
}

.submit-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}
</style>