<template>
  <div>
    <van-search
        v-model="keyword"
        shape="round"
        background="#ffffff"
        placeholder="搜索门诊"
        @search="onSearch"
    />

    <span class="location-span">
         <van-icon name="location-o"/>
         <span class="location-text" @click="goArea">{{curCity}}</span>
       </span>
    <div style=" align-items: start;background:white;">

      <div
          style="text-align: left;margin-left: 15px;font-weight: 600;font-size: large; align-items: center;line-height: 30px;">
        请选择默认机构
      </div>

      <van-divider/>
      <div style="width: 100%;">
        <span v-for="(item, index) in clinicList" :key="index">
          <div style="height: 80px;margin: 15px;" @click="toAppointment(item)">

          <div style="width: 70%;float: left;">
            <div style="text-align: left; ">

              <div class="van-ellipsis" style="font-size: medium;font-weight: 550;color: #565656;">
                {{ item.title }}
              </div>
            </div>

            <div style="text-align: left;float: left;margin-top: 20px;">
              <div class="van-ellipsis" style="font-size: small;color: #565656;">
                {{ item.address }}
              </div>
              <span class="van-ellipsis" style="font-size: small; color: #565656;">
                  {{ item.working_hours}}
                </span>
            </div>
          </div>
          <div
              style=" float: right; line-height: 60px; width: 25%; text-align: end;font-size: small;color: #cecece;white-space:nowrap;overflow:hidden;">
            {{ towNumber(item.distance) }}
          </div>

        </div>
          <van-divider/>
        </span>


        <!--<van-divider/>
        <div style="height: 80px;margin: 15px; " @click="toClinicIndex">
          <div style="width: 70%;float: left;">
            <div style="text-align: left;float: left;">

              <div class="van-ellipsis" style="font-size: medium;font-weight: 550;color: #565656;">
                广州市中山大学第三附属医院
              </div>
            </div>

            <div style="text-align: left;float: left;margin-top: 20px;">
              <div style="font-size: small;color: #565656;">
                广州市天河区中山大道121号地铁岗顶站B出口
              </div>
              <span style="font-size: x-small; color: #565656;">
                  接种时间：周一：09：00 - 16：30
                </span>
            </div>
          </div>
          <div
              style=" float: right; line-height: 60px; width: 25%; text-align: end;font-size: xx-small;color: #cecece;">
            距您1.4公里
          </div>
        </div>-->
      </div>
<!--      <van-divider/>-->

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import {Dialog, Search} from 'vant';
import axios from "axios";

Vue.use(Search);

export default {
  name: "DefaultClinic",
  data() {
    return {
      clinicList: [],
      userInfo:[],
      curCity:'',
      keyword:''
    }
  },
  computed: {
    // computed

  },
  mounted() {
    this.curCity = localStorage.getItem('chooseCityName') ?? '广州市';
    //this.userInfo = localStorage.getItem('userInfo' + appid)
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    this.getClinicList('');
  },
  methods: {
    goArea() {
      this.$router.push('area')
    },
    onSearch() {
      this.getClinicList(this.keyword);
    },
    towNumber(val) {
      if (val == null)
        return '--'

      if (val > 1000) {
        let temp = val * 0.001;
        return '距您' + temp.toFixed(1) + '公里';
      }
      else {
        return '距您' + val.toFixed(0) + '米';
      }
    },
    toAppointment(item) {
      //选择的门诊机构
      /*localStorage.setItem('defaultClinicId',id);
      this.$router.back()*/
      Dialog.confirm({
        message: '确定要将'+ item.title +'设为默认机构吗？',
        messageAlign: 'left'
      })
          .then(() => {
            // on confirm
            this.defaultClinic(item.id)
          })
          .catch(() => {
            // on cancel
          });
    },
    defaultClinic(id) {
      let that = this;
      axios.put('/api/user/default/company', {
          "company_id":id,
      })
          .then(function (response) {
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
            })  .then(() => {
              // on confirm
              localStorage.setItem('goClinicId',id)
              that.$router.back()
            })
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //获取门诊列表
    getClinicList(title) {
      let that = this;
      //axios.defaults.headers.get['Accept'] = 'application/json'
      //axios.defaults.headers.get['ContentType'] = 'application/json'

      axios.get('/api/company', {
        params: {
          "longitude": localStorage.getItem('longitude'),
          "latitude":  localStorage.getItem('latitude'),
          "order_distance":"asc",
          "title":title
        }
      })
          .then(function (response) {
            //that.images.splice(0);//清空
            that.clinicList = response.data.data;
            /*let arr = response.data.data;
            for (let i = 0; i < arr.length; i++) {
              that.images.push(arr[i].thumb);
              console.log(arr[i].thumb);
            }*/
          })
          .catch(function (error) {
            console.log(error);
          });
    },
  }
}
</script>

<style scoped>
.location-text {
  color: #565656;
  font-size: x-small;
  height: 100%;
  /*vertical-align: middle;*/
}

.location-span {
  position: absolute;
  top: 58px;
  left: 80%;
  text-align: center;
  background: white;
  border-radius: 11px;
  float: right;
  border: 1px solid #bcbcbc;
  /*background:rgba(250,250,250,0.5);*/
  padding: 0 5px;

  z-index: 100;
  /*align-items: center;*/
}

.van-icon-location-o {
  font-size: x-small;
  vertical-align: middle;
}
</style>