<template>
  <div>

    <van-sticky >
      <div
          style="text-align: left;margin-left: 15px;font-weight: 550;font-size: medium;margin-top: 10px; padding-top: 10px;align-items: center;line-height: 30px;">
        健康资讯
      </div>
    </van-sticky>
    <van-divider/>


    <van-tabs @click="onTabChange">

      <span v-for="(item, index) in articleClassList" :key="index">
         <van-tab :title=item.title>
            <span v-for="(item, index) in articleList" :key="index" @click="goNewsDetail(item.id);">
              <div style="width: 100%;">
                <div style="height: 80px;margin: 15px; ">
                  <div style="width: 70%;float: left;">
                    <div style="text-align: left;float: left;">

                      <div class="van-multi-ellipsis--l2" style="font-size: medium;font-weight: 550;color: #565656;">
                        {{ item.title }}
                      </div>
                    </div>

                    <div style="text-align: left;float: left;margin-top: 20px;">
                      <span style="font-size: small;color: steelblue;">
                        #{{ item.category.title }}
                      </span>
                      <span style="font-size: small;margin-left: 20px;color: #565656;">
                        {{ item.created_at }}
                      </span>
                    </div>
                  </div>
                  <div style=" float: right; height: 30px; width: 30%; text-align: end;">
                    <van-image
                        cover
                        radius="6px"
                        width="80px"
                        height="80px"
                        :src=item.thumb
                    />
                  </div>
                </div>
                <van-divider/>

        </div>
            </span>
         </van-tab>
      </span>


    </van-tabs>

    <van-tabbar route v-model="active">
      <van-tabbar-item replace to="/clinic" icon="hotel-o">
        机构主页
      </van-tabbar-item>
      <van-tabbar-item replace to="/article" icon="newspaper-o">
        健康资讯
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="friends-o">
        个人中心
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue';
import {Toast} from 'vant';
import {Sticky} from 'vant';
import axios from "axios";

Vue.use(Sticky);
export default {
  name: "article",
  data() {
    return {
      active: 'article',
      articleClassList: [],
      articleList: [],
      activeTab: 0
    };
  },
  mounted() {
    this.getVaccineCategory();
  },
  methods: {
    goNewsDetail(itemId) {
      //localStorage.setItem('newsId',itemId)
      //this.$router.push({name: 'newsDetail'})
      this.$router.push({name: 'newsDetail', query: {id: itemId}})
    },
    onClick(name, title) {
      Toast(title);
    },
    //tab切换事件
    onTabChange(index, title) {
      //Toast(index);
      //Toast(title);
      //console.log('title ============================ ');
      console.log(title);

      //获取疫苗分类id，获取产品列表
      let articleClass = this.articleClassList[index];

      //alert(vaccineClass['id']);

      //console.log('================ ' + JSON.stringify(articleClass));

      //this.categoryId = vaccineClass['id'];
      let that = this;
      //axios.defaults.headers.common['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZCIsImlzcyI6ImdkIiwiZXhwIjoxNjA5NDI5Njc1LCJpYXQiOjE2MDg1NjU2NzUsInJvbCI6IjEifQ.HNe3_MH4dAobi6i3dlDNWqRe_jwz1VC8rLMHAfCxHXcENp8dpRd8cIBIu4heB8IADYhoLRy2vIynY9ddrfV5oQ";
      //请求文章列表
      axios.get('/api/aggregate/', {
        params: {
          "model_type": "article",
          "category_ids": articleClass['id'],
          "page": 1,
          "per_page": 10,
          //"company_ids": localStorage.getItem('goClinicId')
        }
      })
          .then(function (response) {
            console.log(response);
            //that.images.splice(0);//清空
            that.articleList = response.data.data;
            /*for (let i = 0; i < arr.length; i++) {
              that.images.push(arr[i].thumb);
              console.log(arr[i].thumb);
            }*/
            console.log(response);
          })
          .catch(function (error) {
            console.log(123123)
            console.log(error);
          });
    },
    //获取文章分类
    getVaccineCategory() {
      let that = this;
      axios.get('/api/aggregate', {
        params: {
          "model_type": "articleCategory",
          "page": 1,
          "per_page": 20,
          "company_ids": 0
        }
      })
          .then(function (response) {
            console.log(response);
            //that.images.splice(0);//清空
            that.articleClassList = response.data.data;
            that.activeTab = that.$route.params.index ?? 0;//TODO 重新返回界面的时候再次选中之前的活动Tab
            that.onTabChange(that.activeTab, 'title');
            /*for (let i = 0; i < arr.length; i++) {
              that.images.push(arr[i].thumb);
              console.log(arr[i].thumb);
            }*/
            console.log(response);
          })
          .catch(function (error) {
            console.log(123123)
            console.log(error);
          });
    },
  },
}
</script>

<style scoped>
.van-divider {
  margin: 16px 0 0 0;
}
</style>