export default {

    //转换日期
    chanceDate(str) {
        switch (str) {
            case '最近60天':
                var lastWeek1 = new Date();
                return this.formatDate(new Date(lastWeek1.setDate(lastWeek1.getDate() - 60)))
            case '最近半个月':
                var lastWeek2 = new Date();
                return this.formatDate(new Date(lastWeek2.setDate(lastWeek2.getDate() - 15)))
            case '最近一周':
                var lastWeek3 = new Date();
                return this.formatDate(new Date(lastWeek3.setDate(lastWeek3.getDate() - 7)))
            default:
                //最近一个月
                var lastWeek4 = new Date();
                return this.formatDate(new Date(lastWeek4.setDate(lastWeek4.getDate() - 30)))
        }
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    },
}