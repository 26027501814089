<template>
    <div>
        <div class="background-image">
            <div class="title">
                欢迎访问育儿知识库
            </div>
            <div class="sub-title">
                为了给你提供优质的育儿建议，请填写以下信息
            </div>
        </div>
        <div>
            <van-cell-group>
                <van-field label="宝宝小名" input-align="right" v-model="name" placeholder="请输入"/>
                <van-field label="宝宝生日" input-align="right" v-model="birthday" @focus="showBirthday=true"
                           placeholder="请选择"/>
                <van-cell title="宝宝性别" input-align="right" v-model="gender">
                    <div class="btnGroup">
                        <button
                            v-for="(item, index) in genders"
                            :key="index"
                            @click="selectGender(index)"
                            :class="activeIndex1 === index ? 'active' : ''"
                        >
                            {{ item.title }}
                        </button>
                    </div>
                </van-cell>


                <van-cell title="我是宝宝的" input-align="right" v-model="relation">
                    <div class="btnGroup">
                        <button
                            v-for="(item, index) in relations"
                            :key="index"
                            @click="selectRelation(index)"
                            :class="activeIndex2 === index ? 'active' : ''"
                        >
                            {{ item.title }}
                        </button>
                    </div>
                    <van-popup v-model="showPicker" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="columns"
                            @cancel="showPicker = false"
                            @confirm="onConfirm"
                        />
                    </van-popup>
                </van-cell>
            </van-cell-group>

            <van-popup v-model="showBirthday" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
                <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    @confirm="confirmDate"
                    @cancel="showBirthday = false"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                />

            </van-popup>
            <van-button @click="saveSetting" style="width: 80%;margin:35px 10%;" type="primary" block>确认</van-button>
        </div>
    </div>
</template>

<script>
import {formatDate} from "@/utils/date";
import axios from "axios";
import {Dialog} from "vant";

export default {
    name: "parentingStart",
    data() {
        return {
            genders: [{"title": "男孩"}, {"title": "女孩"}],
            relations: [{"title": "妈妈"}, {"title": "爸爸"}, {"title": "其他"}],
            activeIndex1: [],
            activeIndex2: [],
            showBirthday: false,
            currentDate: '',
            minDate: new Date(2018, 0, 1),
            maxDate: new Date(),
            name: '',
            birthday: '',
            gender: '',
            relation: '',
            showPicker: false,
            columns: ['奶奶', '外婆', '爷爷', '外公', '姥姥', '姥爷'],
            userInfo: [],
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));

        //获取儿童信息
        if(this.userInfo != null) {
            this.childInfo();
        }
    },
    created() {
        let appid = this.comFun.getAppid();
        if (appid === undefined) {
            appid = this.$route.query.appid;
        }
        if (appid !== undefined) {
            localStorage.setItem('appid', appid);
            //this.getCompanyInfo(appid);
        } else {
            appid = localStorage.getItem('appid');
            //this.getCompanyInfo(appid);
        }
        let code = this.comFun.getQueryVariable('code');
        if (localStorage.getItem('userInfo' + appid) == null) {
            if (code === false || code.length < 10) {
                const appid2 = this.$route.query.appid;
                if (localStorage.getItem('appid') === undefined) {
                    localStorage.setItem('appid', appid2);
                }
                this.comFun.getCode(appid2);
            } else {
                this.getUserInfo();
            }
        } else {
            this.comFun.initJsSDK();
        }
    },
    methods: {
        getUserInfo() {
            let code = this.comFun.getQueryVariable('code');
            let appid = this.comFun.getQueryVariable('appid');
            //alert('code = ' + code)
            let that = this;
            //请求文章列表
            axios.post('/wechat/user', {
                "appId": appid,
                "code": code
            })
                .then(function (response) {
                    //alert(JSON.stringify(response.data.data))
                    localStorage.setItem('userInfo' + appid, JSON.stringify(response.data.data))
                    localStorage.setItem('companyId', JSON.stringify(response.data.data.companyId))
                    localStorage.setItem('userId', JSON.stringify(response.data.data.id))
                    that.userInfo = response.data.data;
                    that.initConfig = true;
                    that.comFun.initJsSDK();
                    //that.getClinicInfo();
                    that.childInfo();

                })
                .catch(function (error) {
                    alert(error.message)
                });

        },
        showPickers(value) {
            console.log('onConfirm(value) => ' + value)
        },
        onConfirm(value) {
            console.log('onConfirm(value) => ' + value)
            this.relation = value;
            this.relations[2].title = value;
            this.showPicker = false;
        },
        onCancel() {

        },
        onChange(value,index) {
            console.log('onChange(value) => ' + value[index])
        },
        childInfo() {
            let that = this;
            axios.post('/parenting/child', {
                "companyId": this.userInfo.companyId,
                "userId": this.userInfo.id,
            })
                .then(function (response) {
                    if (response.data.data != null) {
                        that.$router.replace({name: 'parentingHome',
                            query: {gender: response.data.data.gender,birthday:response.data.data.birthday,relation: response.data.data.relation,name:response.data.data.name}})
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        confirmDate(value) {
            this.birthday = formatDate(value, 'yyyy-MM-dd');
            this.showBirthday = false
        },
        selectGender(index) {
            // alert(index + " - " + index2);
            this.activeIndex1 = index
            this.gender = this.genders[index].title
        },
        selectRelation(index) {
            this.activeIndex2 = index
            this.relation = this.relations[index].title
            if(index === 2) {
                this.showPicker = true;
            }
        },
        saveSetting() {
            if (this.name.length === 0) {
                this.showTip('请输入宝宝小名');
                return;
            }
            if (this.birthday.length === 0) {
                this.showTip('请选择宝宝生日');
                return;
            }
            if (this.gender.length === 0) {
                this.showTip('请选择宝宝性别');
                return;
            }
            if (this.relation.length === 0) {
                this.showTip('请选择与宝宝的关系');
                return;
            }
            let that = this;
            //let appid = localStorage.getItem('appid');
            //let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/parenting/setting', {
                "companyId": this.userInfo.companyId,
                "userId": this.userInfo.id,
                "name": this.name,
                "gender": this.gender === '男孩' ? '1' : '0',
                "birthday": this.birthday,
                "relation": this.relation,
            })
                .then(function (response) {
                    if(response.data.status === 200) {
                        that.$router.push({name: 'parentingHome',
                            query: {gender: that.gender === '男孩' ? '1' : '0',birthday:that.birthday,relation: that.relation,name:that.name}})
                    }
                    else {
                        alert("操作失败，请联系系统管理员")
                    }

                })
                .catch(function (error) {
                    alert("操作失败，请联系系统管理员 " + error)

                });
        },
        showTip(value) {
            Dialog.alert({
                message: value,
                messageAlign: 'left',
                confirmButtonColor: 'black'
            }).then(() => {
                // on close
                //if (response.data.code === 200)
                //
            });
        }

    }
}
</script>

<style scoped>
.background-image {
    height: 150px; /* 设置div的高度 */
    background: rgb(106, 199, 132);
    /*background-image: url('https://vaccin-image.wxdev.top/WechatIMG22.jpg');*/ /* 设置背景图片 */
    background-size: cover; /* 背景图片覆盖整个div区域 */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat;
    width: 100%; /* 或者具体宽度 */

}

.sub-title {
    margin: 10px 10px;
    color: white;
    font-size: small;
    text-align: left;
}

.title {
    padding-top: 70px;
    margin-left: 10px;
    color: white;
    font-weight: bold;
    font-size: larger;
    text-align: left;
}


</style>
<style lang="less" scoped>
.van-cell__title, .van-cell__value {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    flex: auto;
}

.btnGroup {
    button {
        height: 25px;
        font-size: 15px;
        margin: 0 6px;
        padding: 0 10px;
        background-color: #EEEEEE;
        border: 1px solid white;
        border-radius: 15px;
    }

    // 被选中的按钮的样式
    .active {
        //background-color: red;
        border: rgb(106, 199, 132);
        color: rgb(106, 199, 132);

    }
}
</style>