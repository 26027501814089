<template>
  <van-grid :border="true" :column-num="2" :gutter="10">
    <van-grid-item v-for="(item, index) in productList" :key="index" @click="productDetail(item.id)">
      <van-image
          :src="item.picture"
      />
      <van-cell-group inset :border="false" style="text-align: left; width: 100%;">
        <span class="productTitle"  > {{item.title}}</span>

        <span class="van-price" style="color: orangered; " > ￥ {{item.price}}</span>
      </van-cell-group>

    </van-grid-item>

  </van-grid>
</template>

<script>
import Vue from 'vue';
import VueAxios from "vue-axios";
import axios from "axios";



Vue.use(VueAxios, axios)
export default {
  name: "ProductList",
  data() {
    return {
      active: 'news',
      articleClassList: [],
      articleList: [],
      activeTab: 0,
      productList: []
    };
  },
  mounted: function () {
    document.title="护理上门"
    this.getVaccineCategory()
  },
  methods: {
    productDetail(itemId) {
      this.$router.push({name: 'ProductDetail', query: {id: itemId}})
    },

    goNewsDetail(itemId) {
      //localStorage.setItem('newsId',itemId)
      //this.$router.push({name: 'newsDetail'})
      this.$router.push({name: 'newsDetail', query: {id: itemId}})
    },
    //获取产品列表
    getVaccineCategory() {
      let that = this;
      axios.post('/mall/product_list', {

          "companyId":"62",
          "categoryId":"2"

      })
          .then(function (response) {
            console.log(response);
            //that.images.splice(0);//清空
            that.productList = response.data.data;


            console.log(response);
          })
          .catch(function (error) {
            console.log(123123)
            console.log(error);
          });
    },
  },
}
</script>

<style scoped>
.van-divider {
  margin: 6px 0 0 0;
}
/* CSS样式 */
.productTitle {
  display: -webkit-box; /* 必须设置display属性为-webkit-box */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  -webkit-line-clamp: 2; /* 限制显示两行 */
  -webkit-box-orient: vertical; /* 垂直方向上的换行 */
  text-align: left;
}
.van-price {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  align-items: start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: start;
}
</style>