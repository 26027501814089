<template>
    <div>
        <div style="padding:20px;text-align: left;height: 40px">
            <span style="float: left;font-size: medium">请先完善个人信息，务必填写与身份证上一致的信息，接种需要核实。</span>
        </div>

        <van-divider style="margin: 10px 0 0 0"/>

        <van-field style="padding-top: 10px;font-size: medium;" label="姓名" v-model="name" required placeholder="请填写接种人的真实姓名"
                   input-align="right"/>
        <van-field style="padding-top: 10px;font-size: medium;" label="手机号" v-model="phone" required placeholder="请填写您的手机号"
                   input-align="right"/>
        <van-field style="font-size: medium;" name="radio" label="性别" input-align="right">
            <template #input>
                <van-radio-group v-model="radio" direction="horizontal">
                    <van-radio name="1" icon-size="16px">男</van-radio>
                    <van-radio name="2" icon-size="16px">女</van-radio>
                </van-radio-group>
            </template>
        </van-field>

        <van-cell style="font-size: medium;" title="出生日期" is-link @click="clickShowBirthday" :value="birthday" required/>

        <van-popup v-model="showBirthday" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
            <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    title="选择出生日期"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirmBirthday"
                    @cancel="onCancelBirthday"
            />
        </van-popup>


        <van-cell style="font-size: medium;" is-link @click="clickShowPicker" :value="relation" title="与本人关系" required>
        </van-cell>

        <van-popup v-model="showPopup" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"/>
        </van-popup>

        <van-field style="font-size: medium;" label="身份证号" placeholder="请输入身份证号" v-model="idNumber" input-align="right" required/>

        <van-field style="font-size: medium;" readonly required clickable label="现居地区" :value="valueArea" input-align="right"
                   placeholder="请选择现居地区"
                   @click="bindShow"/>

        <van-popup v-model="showArea" position="bottom" :style="{ height: '30%',paddingBottom:'100px'}">
            <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="bindCancel"/>
        </van-popup>

        <van-field style="font-size: medium;" label="详细地址" placeholder="请输入详细地址" v-model="address" input-align="right"/>
        <div style="height: 5rem;"></div>
        <div class="submit-bar">
            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 100px; margin: 10px 20px;  " @click="addFamily">
                提交
            </van-button>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import {Dialog, Field} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Cell, CellGroup} from 'vant';
import {Picker} from 'vant';
import {Popup} from 'vant';
import {Area} from 'vant';
import Areas from '@/utils/area'
import {DatetimePicker} from 'vant';
import {formatDate} from "@/utils/date";
import axios from "axios";
import {idcard} from '@/utils/idcard'

Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
export default {
    name: "Add",
    data() {
        return {
            areaList: Areas,
            idNumber: "",
            address: "",
            name: "",
            phone: "",
            birthday: "",
            relation: '请选择与本人关系',
            showPopup: false,
            showArea: false,
            showBirthday: false,
            radio: '1',
            columns: ['本人', '父母', '子女', '夫妻', '亲属', '朋友', '其他'],
            currentDate: new Date(),
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            valueArea: '', //地区值
            arrArea: [], //存放地区数组
            province: '',
            city: '',
            area: '',
            familyEditInfo: []
        };
    },
    mounted() {
        this.familyEditInfo = JSON.parse(localStorage.getItem('familyEditInfo'))


        this.name = this.familyEditInfo.name;
        this.phone = this.familyEditInfo.phone;
        this.radio = this.familyEditInfo.gender === 1 ? '1' : '2';
        this.birthday = this.familyEditInfo.birthday;
        this.relation = this.familyEditInfo.relationship;
        this.idNumber = this.familyEditInfo.identityCard;
        this.province = this.familyEditInfo.provinceName;
        this.city = this.familyEditInfo.cityName;
        this.area = this.familyEditInfo.regionName;

        this.valueArea = this.familyEditInfo.provinceName + '-' + this.familyEditInfo.cityName + '-' + this.familyEditInfo.regionName;
        this.province = this.familyEditInfo.provinceId;
        this.city = this.familyEditInfo.cityId;
        this.area = this.familyEditInfo.regionId;

        this.address = this.familyEditInfo.address;
    },
    methods: {
        //新增家庭成员
        addFamily() {
            if (this.name.length === 0) {
                Dialog.alert({message: '请输入家庭成员姓名'});
                return;
            }
            if (this.phone.length === 0) {
                Dialog.alert({message: '请填写您的手机号码'});
                return;
            } else if (this.phone.length !== 11) {
                Dialog.alert({message: '手机号输入有误'});
                return;
            }
            if (this.birthday.length === 0) {
                Dialog.alert({message: '请选择家庭成员生日'});
                return;
            }
            if (this.relation === '请选择与本人关系') {
                Dialog.alert({message: '请选择与本人关系'});
                return;
            }
            if (this.province.length === 0) {
                Dialog.alert({message: '请选择家庭成员现居地区'});
                return;
            }
            let message = idcard(this.idNumber);
            if (message !== true) {
                Dialog.alert({
                    message: message,
                    messageAlign: 'left',
                    confirmButtonColor: 'black'
                })
                return;
            }
            /*alert('name = ' + this.name + '   gender = ' + this.radio + '   birthday = ' +
                this.birthday + '   idNumber = ' + this.idNumber + '   address = ' + this.address +
                '   gender = ' + this.radio + '   province = ' + this.province + '   city = ' + this.city
                + '   area = ' + this.area+ '   relation = ' + this.relation);*/

            /*if (this.name.length >= 1) {
              return;
            }
            */
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/families/save', {
                "id": this.familyEditInfo.id,
                "userId": userInfo.id,
                "name": this.name,
                "phone": this.phone,
                "gender": this.radio,
                "birthday": this.birthday,
                "identityCard": this.idNumber,
                "provinceId": this.province,
                "cityId": this.city,
                "regionId": this.area,
                "address": this.address,
                "default": 1,
                "relationship": this.relation
            })
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        //if (response.data.code === 200)
                            that.$router.back()
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onConfirm(value) {
            this.relation = value;
            this.showPopup = false;
        },
        onCancel() {
            this.showPopup = false;
        },
        clickShowBirthday() {
            this.showBirthday = true;
        },
        onConfirmBirthday(value) {
            // console.log(console.log( formatDate(value, 'yyyy-MM-dd')));
            this.birthday = formatDate(value, 'yyyy-MM-dd');
            this.showBirthday = false;
        },
        onCancelBirthday() {
            this.showBirthday = false;
        },
        clickShowPicker() {
            this.showPopup = true;
        },
        bindShow() {
            this.showArea = true;
        },
        bindCancel() {
            this.showArea = false;
        },
        //地区选择
        onAreaConfirm(val) {
            this.showArea = false;
            this.arrArea = val;
            this.valueArea = val[0].name + '-' + val[1].name + '-' + val[2].name;
            this.province = val[0].code;
            this.city = val[1].code;
            this.area = val[2].code;
        },

    }
}
</script>

<style scoped>
.van-collapse-item {
    text-align: left;
}

.van-cell {
    line-height: 34px;
}

.van-cell__title {
    text-align: left;
}

.van-cell__left-icon, .van-cell__right-icon {
    line-height: 34px;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

</style>