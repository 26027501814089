<template>
  <div>
    <div style="text-align: center;margin: 40px;">
      <span style="margin: 20px;font-size: large">绑定手机号</span>

    </div>
    <div>
      <van-cell-group>

        <van-field
            v-model="phone"
            label="手机号"
            clearable
            placeholder="请输入手机号"
            maxlength="11"
        />

        <van-field
            v-model="sms"
            center
            clearable
            maxlength="6"
            label="短信验证码"
            placeholder="短信验证码"
        >
          <template #button>
            <van-button size="small" @click="getCode" plain type="info"  >{{content}}</van-button>
          </template>
        </van-field>
      </van-cell-group>

    </div>

    <div class="submit-bar">
      <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                  style="border-radius: 5px;width: 150px; margin: 10px 20px; " @click="submit">
        确认提交
      </van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Dialog, Field} from 'vant';
import axios from "axios";

Vue.use(Field);
export default {
  name: "bindPhone",
  data() {
    return {
      phone: '',
      sms: '',
      content: '获取验证码',
      totalTime: 60,
      canClick: true
    };
  },
  mounted() {
    axios.defaults.headers.get['Accept'] = 'application/json'
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
  },
  methods:{
    //提交
    submit() {
      if(this.phone.length < 11 || this.sms.length < 4) {
        Dialog.alert({
          message: '请输入正确的手机号码和验证码',
          messageAlign: 'left',
        })
        return ;
      }
      axios.put('/api/user',{
        "phone":this.phone,
        "code":this.sms
      })
          .then(function (response) {
            console.log("提交修改手机号")
            console.log(JSON.stringify(response.data.data))
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
            })
                .then(() => {
                  // on confirm
                  this.$route.back();
                })
          })
          .catch(function (error) {

            console.log("请求失败 ：" + JSON.stringify(error));
            //返回首页
            //this.$router.replace({name: 'index'})

          });
    },
    //获取验证码
    getCode() {
      if(this.phone.length == 0) {
        Dialog.alert({
          message: '请输入手机号码',
        })
        return ;
      }
      if(this.phone.length != 11) {
        Dialog.alert({
          message: '手机号码有误，请检查后输入',
          messageAlign: 'left',
        })
        return ;
      }
      this.countDown();
      axios.post('/api/sms',{
        "phone":this.phone
      })
          .then(function (response) {
            console.log("获取验证码")
            console.log(JSON.stringify(response.data.data))
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
            })
                .then(() => {
                  // on confirm
                })
          })
          .catch(function (error) {

            console.log("请求失败 " + JSON.stringify(error));
            //返回首页
            //this.$router.replace({name: 'index'})

          });
    },
    countDown () {
      if (!this.canClick) return  //改动的是这两行代码
      this.canClick = false
      this.content = this.totalTime + 's后重新发送'
      let clock = window.setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {
          window.clearInterval(clock)
          this.content = '重新获取'
          this.totalTime = 60
          this.canClick = true  //这里重新开启
        }
      },1000)
    }
  }
}
</script>

<style scoped>
.disabled{
  background-color: #ddd;
  border-color: #ddd;
  color:#57a3f3;
  cursor: not-allowed;
}
</style>