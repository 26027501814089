<template>
    <div>
        <div style="height: 40px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">

            <div style="line-height: 40px;padding-left: 20px;font-size: medium;font-weight: 550;">
                挂号记录
            </div>

            <span v-for="(item, index) in reverseList" :key="index">
              <div style="height: 170px;background: white;border-radius: 10px;margin:15px; box-shadow:6px 6px 9px #eee;"
                   @click="goReverseDetail(item)">

              <div style="padding: 20px;">
                <span style="float: left;font-size: large;">{{ item.departmentName }}</span>
                <span style="text-align: right;float: right;color: green;">{{ getState(item.state) }} </span>
              </div>
              <van-divider/>
              <div style="height: 20px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">就诊人</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">
                  {{ item.name }}</span>
              </div>

              <div style="height: 20px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">就诊时间</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">
                  {{ item.date }} {{ item.week }} {{ item.time }}</span>
              </div>
              <div style="height: 20px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">医生姓名</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">
                  {{ item.doctorName }}</span>
              </div>
              <div style="height: 20px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: small">创建时间</span>
                <span style="text-align: right;float: right; font-size: small;color: #565656;">
                  {{ item.createdAt }}</span>
              </div>
            </div>
          </span>
            <van-empty v-if="reverseList.length === 0" description="暂无数据">
            </van-empty>

        </div>


    </div>
</template>

<script>
import Vue from 'vue';
import {Panel} from 'vant';
import {Cell, CellGroup} from 'vant';
import axios from "axios";

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Panel);
export default {
    name: "registrationList",
    data() {
        return {
            activeTab: 0,
            reverseList: [],
            queueList: [],
            injectionNumber: [
                {value: 1, text: "第一针"}, {value: 2, text: "第二针"}, {value: 3, text: "第三针"}, {
                    value: 4,
                    text: "第四针"
                }, {
                    value: 5,
                    text: "第五针"
                },
                {value: 6, text: "第六针"}, {value: 7, text: "第七针"}, {value: 8, text: "第八针"}, {
                    value: 9,
                    text: "第九针"
                }, {
                    value: 10,
                    text: "第十针"
                },
                {value: 11, text: "第十一针"}, {value: 12, text: "第十二针"}, {value: 13, text: "第十三针"}, {
                    value: 14,
                    text: "第十四针"
                }, {value: 15, text: "第十五针"}
            ],
        };
    },
    beforeCreate() {
        //进入页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #f7f8fd');
    },
    beforeDestroy() {
        //退出页面设置背景
        document.querySelector('body').setAttribute('style', 'background: #fff');
    },
    mounted() {
        axios.defaults.headers.common.Authorization = localStorage.getItem("token");
        this.getReverseList();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getState(state) {
            var str = '正常';
            switch (state) {
                case 0:
                    str = '正常';
                    break;
                case 1:
                    str = '预约成功';
                    break;
                case 2:
                    str = '已完成';
                    break;
                case -3:
                    str = '用户取消';
                    break;
            }
            return str;
        },
        //针次
        injection(inject) {
            let status;
            this.injectionNumber.forEach(function (element) {
                console.log('element = ' + JSON.stringify(element))
                if (inject === element.value) {
                    status = element.text
                }
            });

            return status;
        },
        goReverseDetail(item) {

            this.$router.push({name: 'registrationDetail', query: {id: item.id}})
        },
        getReverseList() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/registration/list', {
                "modelType": "App\\Product",
                "userId": userInfo.id,
                "state": 1,
                "title": "_",
                "parentId": 0,
                "page": 1,
                "per_page": 20,
                "companyId": userInfo.companyId
            })
                .then(function (response) {
                    that.reverseList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        onTabChange(index, title) {
            console.log('index+title', index + title)
            this.getNatList();
        }
    }
}
</script>

<style scoped>

</style>