<template>
    <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
        <div style="padding-bottom:10px;background: white;">

            <div style="padding: 20px;">
                <span style="float: left; font-size: large">{{ productInfo.title }}</span>
                <span style="text-align: right;float: right;color: red;">￥{{ productInfo.price }}</span>
            </div>
            <van-divider/>
            <div style="height: 60px ;padding: 0 20px;margin: 20px 0">
                <span style="float: left;color: #bcbcbc;font-size: small"></span>
                <span style="text-align: left;float: right; font-size: medium;color: #565656;">{{
                    productInfo.description
                    }}</span>
            </div>


            <div style="min-height: 80px;background: rgb(255,251,235);border-radius: 8px;margin:15px; ">
                <div style="text-align: left; color: #ffcb41;font-size: small;padding: 10px 10px 0 10px;font-weight: 550;">
                    预约说明
                </div>
                <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
                    {{ message }}
                </div>
            </div>
        </div>

        <div style="margin-top: 10px;">
            <van-collapse v-model="activeNames">
                <van-collapse-item title="选择预约人" :value="familyName" name="2" icon="user-circle-o">
                    <van-radio-group v-model="radio1">
                        <van-cell-group :border="false">
              <span v-for="(item, index) in familyList" :key="index">
                <van-cell :title=item.name clickable :label=label(item)>
                  <template #right-icon>
                    <van-radio @click="chooseFamily(item)" :name=item.id icon-size="16px"/>
                  </template>
                </van-cell>
              </span>
                        </van-cell-group>
                    </van-radio-group>
                    <van-divider/>
                    <div style="height: 35px;text-align: center;">
                        <van-button icon="plus" plain type="info" to="/familyList" size="small">新增家庭成员
                        </van-button>
                    </div>
                </van-collapse-item>
            </van-collapse>

            <!--      <div style="background: white;height: 120px;" v-if="vaccineInfo.adult === 1">
                    <van-cell title="选择针次" icon="records" :border="false"/>

                    <van-radio-group v-model="radio2" direction="horizontal" icon-size="16px">
                      <span v-for="(item, index) in  injectionNumber.slice(0 , injectionTotal)" :key="index">
                        <van-radio @click="chooseInjection(item.value,item.text)" :name=item.value
                                   style="font-size: small">{{ item.text }}</van-radio>
                      </span>
                      &lt;!&ndash;           <van-radio name="2" style="font-size: small">第二针</van-radio>
                                <van-radio name="3" style="font-size: small">第三针</van-radio>
                                <van-radio name="4" style="font-size: small">第四针</van-radio>
                                <van-radio name="5" style="font-size: small">第五针</van-radio>&ndash;&gt;

                    </van-radio-group>
                  </div>-->


        </div>

        <div style="background: white;margin-top: 10px;">
            <van-cell title="预约日期" icon="notes-o" :border="false" is-link :value="chooseDate" @click="showPopup"/>

            <div style="margin: 0 30px;">
                <van-calendar v-model="show" :formatter="formatter" :default-date=null title="选择日期"
                              :min-date="minDate"
                              :max-date="maxDate" :round="false" position="right" :show-confirm="false"
                              @select="selectDate"/>
            </div>
        </div>

        <div style="background: white;margin-top: 10px;">
            <van-cell title="预约时间" icon="clock-o" :border="false" is-link :value="chooseTime"
                      @click="showActionSheet = true"/>
            <van-action-sheet :round="false" v-model="showActionSheet" :actions="actions" @select="onSelect"
                              cancel-text="取消"
                              description="请选择时间"/>

        </div>
        <div style="height: 5rem;"></div>

        <div class="submit-bar">
            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 150px; margin: 10px 20px; " @click="bindPhone">
                下一步
            </van-button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {Collapse, CollapseItem} from 'vant';
import {Form} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Calendar} from 'vant';
import {formatDate} from '@/utils/date'
import {ActionSheet} from 'vant';
//import {Toast} from 'vant';
import axios from "axios";
import {Dialog} from 'vant';

// 全局注册
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Calendar);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(Collapse);
Vue.use(CollapseItem);
export default {
    name: "nucleinStep2",
    data() {
        return {
            radio1: 0,
            radio2: '1',
            activeNames: ['1'],
            show: false,
            showActionSheet: false,
            actions: [],
            vaccineInfo: [],
            productInfo: [],
            clinicInfo: [],
            familyList: [],
            message: '预约成功到门诊请出示：“预约信息”，“本人身份证”，半年内体检报告，接种完成请点：“确认已完成接种”',
            chooseDate: '',
            chooseTime: '',
            familyName: '',
            injectionNumber: [{value: 1, text: "第一针"}, {value: 2, text: "第二针"}, {value: 3, text: "第三针"}, {
                value: 4,
                text: "第四针"
            }, {value: 5, text: "第五针"}],
            injectionTotal: 1,
            minDate: new Date(new Date().setDate(new Date().getDate())),
            maxDate: new Date(new Date().setDate(new Date().getDate())),
            //可以预约的日期
            curNums: [],
        };
    },
    mounted() {
        // document.title = Vue.platformTitle;
        //进入页面清空缓存
        localStorage.removeItem('injectionValue')
        localStorage.removeItem('familyId')

        axios.defaults.headers.common.Authorization = localStorage.getItem("token");

        // this.getClinicInfo();
        this.getVaccineInfo();
        this.getFamily();

        //工作时间
        //this.getWorkHours()
        this.getDays();
    },
    computed: {
        swipeHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕高
            return height + 'px';
        }
    },
    methods: {
        // 日期添加备注
        formatter(day) {
            // 当前月份的日
            //let date = day.date.getDate()
            console.log("this.curNums.date  = " + JSON.stringify(this.curNums.date))
            console.log("day.date  = " + day.date)
            console.log("new Date(this.curNums.date[i]  = " + this.curNums.date[0])
            let date = day.date;
            let dateFormat = date.getFullYear() + '-' + this.dataLeftCompleting(2, 0, (date.getMonth() + 1)) + '-' + this.dataLeftCompleting(2, 0, date.getDate());
            console.log("new Date(this.curNums.date[i]  = " + date.getFullYear() + '-' + this.dataLeftCompleting(2, 0, (date.getMonth() + 1)) + '-' + this.dataLeftCompleting(2, 0, date.getDate()))
            if (this.curNums.date.length < 1) {
                //this.maxDate = 0;
                day.type = 'disabled';
            }
            for (let item of this.curNums.date) {
                console.log('item.date ===================================== ' + item.date)

                //for (let i = 0; i < this.curNums.date.length; i++) {
                //只判断可选的日期
                if (day.type != 'disabled') {
                    day.type = this.filterDate(dateFormat, this.curNums.date) ? 'able' : 'disabled';
                    // 判断预约次数是否为0
                    if (item.date == dateFormat) {
                        console.log('item.date ===================================== ' + item.date)
                        console.log('dateFormat ===================================== ' + dateFormat)

                        // 日期添加备注
                        if (item.maxTotal == item.currentTotal) {
                            day.topInfo = '满'
                            day.type = 'disabled'
                        }

                    }
                }


            }
            return day
        },
        //跳过后台没有返回的日期
        filterDate(date, dateList) {
            //let i = 0;
            for (let i = 0; i < dateList.length; i++) {
                if (dateList[i].date == date) {
                    return true;
                }
            }
            return false;
        },
        dataLeftCompleting(bits, identifier, value) {
            value = Array(bits + 1).join(identifier) + value;
            return value.slice(-bits);
        },
        //injectionValue,injectionText
        chooseInjection() {
            localStorage.setItem('injectionValue', '1');
            localStorage.setItem('injectionText', '1');
        },
        chooseFamily(familyInfo) {
            let that = this;
            if (familyInfo.identityCard == null || familyInfo.identityCard.length < 15) {
                Dialog.alert({
                    message: '请先完善预约人的身份证号信息',
                    messageAlign: 'left',
                }).then(() => {
                    // on confirm
                    localStorage.setItem('familyEditInfo', JSON.stringify(familyInfo))
                    that.$router.push('familyEdit')
                })
            }
            localStorage.setItem('familyId', familyInfo.id);
            localStorage.setItem('familyName', familyInfo.name);
            localStorage.setItem('familyPhone', familyInfo.phone);
            localStorage.setItem('familyGender', familyInfo.gender);
            localStorage.setItem('familyBirthday', familyInfo.birthday);
            localStorage.setItem('familyIdentityCard', familyInfo.identityCard);
            localStorage.setItem('familyProvinceId', familyInfo.provinceId);
            localStorage.setItem('familyCityId', familyInfo.cityId);
            localStorage.setItem('familyRegionId', familyInfo.regionId);
            localStorage.setItem('familyAddress', familyInfo.address);

            this.familyName = familyInfo.name;
        },
        //是否已经绑定手机
        bindPhone() {
            this.step3();
            /*let that = this;
            axios.get('/api/user'
            )
                .then(function (response) {
                  let phone = response.data.data.phone;
                  if(phone != null && phone !== 'null' && phone.length === 11 ) {
                    that.step3();
                  } else {
                    Dialog.alert({
                      message: '请先绑定您的手机号',
                      messageAlign: 'left',
                    }).then(() => {
                      // on confirm
                      that.$router.push('bindPhone')
                    })
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  alert(error)
                });*/
        },
        //下一步
        step3() {
            if (localStorage.getItem('familyId') === null || localStorage.getItem('familyId') === undefined) {
                Dialog.alert({
                    message: '请选预约人',
                });
                return;
            }
            /*if (localStorage.getItem('injectionValue') === null || localStorage.getItem('injectionValue') === undefined) {
              if (this.vaccineInfo.adult === 1) {
                Dialog.alert({
                  message: '请选择针次',
                });
                return;
              } else {
                localStorage.setItem('injectionValue', '1')
              }

            }*/

            if (this.chooseDate.length === 0) {
                Dialog.alert({
                    message: '请选择预约日期',
                });
                return;
            }
            if (this.chooseTime.length === 0) {
                Dialog.alert({
                    message: '请选择预约时间',
                });
                return;
            }

            localStorage.setItem('chooseDate', this.chooseDate);
            localStorage.setItem('chooseTime', this.chooseTime);

            this.$router.push({
                name: 'nucleinStep3'
            });

        },
        label(item) {
            let sex = item.gender === 1 ? '男' : '女';

            return item.relationship + '/' + sex + ' ' + item.birthday;
        },
        //疫苗信息
        getVaccineInfo() {
            //alert(localStorage.getItem('chooseProductId'))
            let that = this;

            axios.post('/items/info', {
                    id: localStorage.getItem('chooseNucleinId')
                }
            )
                .then(function (response) {
                    that.productInfo = response.data.data;
                    localStorage.setItem('productInfo', JSON.stringify(response.data.data));
                    if (that.productInfo.appointmentDescription != null) {
                        that.message = that.productInfo.appointmentDescription;
                    }
                    if (that.productInfo.description != null) {
                        localStorage.setItem('reSuccessMessage', that.productInfo.description);
                    }
                    Dialog.alert({
                        title: '预约说明',
                        message: that.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                    });
                    //针次
                    that.injectionTotal = that.vaccineInfo.injectionTotal;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //机构信息
        getClinicInfo() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/companies/info', {
                    id: userInfo.companyId
                }
            )
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //家庭成员
        getFamily() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('families/list', {
                    "userId": userInfo.id
                }
            )
                .then(function (response) {
                    that.familyList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //工作时间
        getWorkHours() {
            //let that = this;
            axios.get('/api/vaccine/product/' + localStorage.getItem('chooseProductId')
            )
                .then(function (response) {
                    //alert(JSON.stringify( response.data  ))
                    //let {name: '8:00 - 11:00'}, {name: '14:00 - 16:30'}
                    //遍历对象
                    let arr = response.data.data.company.working_hours.time;
                    //alert(JSON.stringify( arr  ))
                    for (let i = 0; i < arr.length; i++) {
                        /*if () {
                          # TODO 如果还没约完次数
                        }*/
                        console.log(JSON.stringify(arr[i]))
                        //that.actions.push({ name: arr[i].between});
                        //that.actions.push({ name: arr[i].between,disabled: true});
                    }

                    //that.actions = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },//约满日期
        getDays() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('items/days', {
                    "companyId": userInfo.companyId,
                    "productId": localStorage.getItem('chooseNucleinId')
                }
            )
                .then(function (response) {
                    console.log('约满日期')
                    console.log(JSON.stringify(response.data.data))
                    // console.log(response.data.data.max_appointment_days)

                    that.curNums = response.data.data;
                    //that.maxDate = new Date(new Date().setDate(new Date().getDate() - 1 + response.data.data.max_appointment_days))
                    that.maxDate = new Date(new Date().setDate(new Date().getDate() - 1 + response.data.data.maxAppointmentDays))
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showPopup() {
            this.show = true;
        },
        selectDate(date) {

            console.log(formatDate(date, 'yyyy-MM-dd'))
            this.chooseDate = formatDate(date, 'yyyy-MM-dd');
            this.show = false;
            let that = this;
            that.chooseTime = '';
            for (let item of this.curNums.date) {
                console.log('selectDate  item.date ===================================== ' + item.date)
                console.log('selectDate this.chooseDate===================================== ' + this.chooseDate)
                // 判断预约次数是否为0
                if (item.date == this.chooseDate) {
                    that.actions = [];
                    for (let times of item.times) {
                        if (times.maxTotal == times.currentTotal)
                            that.actions.push({name: times.title + '(约满)', disabled: true});
                        else
                            that.actions.push({name: times.title + ' 剩余(' + (0 + times.maxTotal - times.currentTotal) + ')'});
                    }

                }
            }

        },
        onSelect(item) {
            // 默认情况下点击选项时不会自动收起
            // 可以通过 close-on-click-action 属性开启自动收起
            this.showActionSheet = false;
            this.chooseTime = item.name.substring(0, item.name.indexOf(' 剩余'));
            //Toast(item.name);
        },
    }
}
</script>

<style>

.van-calendar__top-info {
    text-align: right;
    margin-right: 6px;
    color: red;
}

.van-collapse-item {
    text-align: left;
}

.van-cell__title {
    text-align: left;
}

.van-radio-group--horizontal {
    background: white;
    height: 45px;
}

.van-radio__label {
    margin: 10px;
}

/*.van-radio__icon {
  margin-left: 20px;
}*/
.van-radio--horizontal {
    margin: 10px 15px;
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

/*弹出框样式*/
.van-dialog {
    border-radius: 6px;
    font-family: PingFangZhongcu, Avenir, Helvetica, Arial, sans-serif;
}
</style>