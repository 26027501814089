<template>
    <div style="background: #f7f8fd;">
        <van-skeleton title :row="7" :loading="loading">
            <van-swipe :autoplay="3000" :show-indicators=false>
                <van-swipe-item v-for="(item, index) in banner" :key="index" @click="goAdDetail(item)">
                    <van-image :style="{'height':swipeHeight,'width':imageWidth}" fit="fill" :src="item.thumb"/>
                </van-swipe-item>
            </van-swipe>
        </van-skeleton>


        <div
                style="background: white;height: 100px; margin: -35px 15px 0 15px;border-radius: 4px;z-index: 2; position: absolute;box-shadow: 2px 2px 5px 2px #eee;"
                :style="{'width':mainWidth}">

            <!--      <div style="width: 100%;">
                    <div style="width: 80%;float:left;">
                      <div class="van-multi-ellipsis&#45;&#45;l2"
                           style="margin-top: 12px;font-size: small;color: #565656;margin-left:15px;text-align: left;line-height: 20px;height: 30px;">
                        上班时间：{{ clinicInfo.workingHours }}
                      </div>
                      <div class="van-multi-ellipsis&#45;&#45;l2"
                           style="font-size: small;color: #565656;margin-left:15px;text-align: left;line-height: 20px;height: 30px;">
                        机构地址：{{ clinicInfo.address }} &lt;!&ndash; TODO 导航到机构&ndash;&gt;
                      </div>
                    </div>

                    <div style="width: 20%;float: left;">
                      <van-icon size="20" color="#555555" name="phone" @click="call(clinicInfo.telphone)" style="margin-top: 12px;margin-left: 10px;"/>
                    </div>

                    <div style="width: 20%;float: left;">
                      <van-icon size="20" color="#555555" name="location" @click="openMap" style="margin-top: 2px;margin-left: 10px;"/>
                    </div>

                  </div>-->

            <div style="width: 100%; position: relative;float: left; border-radius: 5px;margin-top: 20px;">
                <van-grid clickable :column-num="3" style="margin-top: -10px;padding: 0;border:0;background: #efefef;"
                          :border="false">
                    <van-grid-item icon="hotel-o" text="机构简介" to="/clinicInfo"/>
                    <van-grid-item icon="idcard" text="机构环境" @click="getEnvImages"/>
                    <van-grid-item icon="location-o" text="机构地址" @click="showLocation"/>
                </van-grid>
            </div>

            <van-image-preview v-model="showImagePreview " :images="envImages" @change="onChange">
                <!--<template v-slot:index>第{{ index }}页</template>-->
            </van-image-preview>

        </div>
        <div ref="container" style="padding-bottom: 15px; background:#fefefc;margin-top: 80px;">
            <van-skeleton title :row="6" :loading="loading">
                <van-grid square :border="false">
                    <!-- <div v-for="(item, index) in vaccineList" :key="index">
                       <p>{{item.token1}}</p>
                     </div>-->
<!--                    <van-grid-item @click="registration">
                        <van-image height="60%" width="50%"
                                   src="http://vaccin-image.wxdev.top/registration2.png"/>
                        <span class="van-grid-item-text">挂号预约</span>
                        <span class="van-grid-item-sub-text">专家在线挂号</span>
                    </van-grid-item>-->
                    <van-grid-item v-for="(item, index) in  vaccineList.slice(0 , 6)" :key="index"
                                   @click="goVaccine(index)">

                        <van-image height="70%" width="70%" :src="item.icon"/>
                        <span class="van-grid-item-text">{{ item.title }}</span>
                        <span class="van-grid-item-sub-text">{{ item.subtitle }}</span>
                    </van-grid-item>


                    <van-grid-item @click="showPopup">
                        <van-image height="65%" width="65%"
                                   src="http://vaccin-image.wxdev.top/%E5%85%A8%E9%83%A8.png"/>
                        <span class="van-grid-item-text">全部</span>
                        <span class="van-grid-item-sub-text">查看全部疫苗</span>
                    </van-grid-item>
                </van-grid>
            </van-skeleton>

        </div>
        <!-- 广告位 -->

        <div style="margin-top: 10px;background: white;align-items: center;">
      <span v-for="(item, index) in ad" :key="index">
        <van-image style="margin: 10px 0;"
                   width="100%"
                   fit="contain"
                   :src=item.thumb
                   @click="goAdDetail(item)"

        />
      </span>

            <!--      </van-sticky>-->

        </div>

        <div style="width: 100%;align-items: start;background:white;padding-bottom: 5px;">
            <!--      <van-sticky :offset-top="40">-->
            <div
                    style="text-align: left;margin-left: 15px;font-weight: 550;font-size: large;padding-top: 10px;align-items: center;line-height: 30px;">
                健康服务
            </div>
            <!--      </van-sticky>-->
            <van-skeleton title :row="6" :loading="loading">
                <div :style="{'height':sideBarHeight}" style="margin: 15px;">
                    <div :style="{'width':sideBarWidth,'height':sideBarHeight}" style="height: 100%;float: left; "
                         ref="serve">
                        <van-image @click="toDoctorList"
                                   style="border-radius: 10px; "
                                   :style="{'width':sideBarWidth,'height':sideBarHeight ,'border-radius': borderRadius}"
                                   src="http://vaccin-image.wxdev.top/RoundCorner.png"
                        />
                    </div>
                    <div style="height: 100%; width: 100%;">
                        <div style="height: 50%; text-align: end;">
                            <van-image @click="toClinicList"

                                       :style="{'width':rightBarWidth,'height':rightBarHeight}"
                                       src="http://vaccin-image.wxdev.top/insurance2.png"
                            />


                        </div>
                        <div style="height: 50%; text-align: end;" @click="toDepartment">
                            <van-image

                                    style="margin-top: 3px;"
                                    :style="{'width':rightBarWidth,'height':rightBarHeight}"
                                    src="http://vaccin-image.wxdev.top/registration33.png"
                            />
                        </div>
                    </div>
                </div>
            </van-skeleton>
        </div>

      <div v-if="userId == 1 || userId == 21 || userId == 3" style="width: 100%;align-items: start;background:white;padding-bottom: 5px;margin-top: 10px;">
        <!--      <van-sticky :offset-top="40">-->
        <div
            style="text-align: left;margin-left: 15px;font-weight: 550;font-size: large;padding-top: 10px;align-items: center;line-height: 30px;">
          健康管理
        </div>
        <div ref="container" style="padding-bottom: 15px; background:#fefefc;margin-top: 20px;">
          <van-grid square :border="false">
            <van-grid-item @click="goSection('report')" >
              <van-image height="55%" width="50%"
                         src="https://vaccin-image.wxdev.top/icon-xieya.png"/>
              <span class="van-grid-item-text">血压管理</span>
              <span class="van-grid-item-sub-text">健康自查</span>
            </van-grid-item>

            <van-grid-item  @click="goSection('ProductList')">
              <van-image height="55%" width="50%"
                         src="https://vaccin-image.wxdev.top/hushi.png"/>
              <span class="van-grid-item-text">护理上门</span>
              <span class="van-grid-item-sub-text">服务到家</span>
            </van-grid-item>

            <van-grid-item @click="goSection('parentingStart')">
              <van-image height="55%" width="50%"
                         src="https://vaccin-image.wxdev.top/yuerzhishi.png"/>
              <span class="van-grid-item-text">育儿知识</span>
              <span class="van-grid-item-sub-text">宝宝健康宝典</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
        <div style=" align-items: start;background:white;">

            <div
                    style="text-align: left;margin-left: 15px;font-weight: 550;font-size: large;margin-top: 10px; padding-top: 10px;align-items: center;line-height: 30px;">
                健康资讯
            </div>
            <van-tabs @click="onTabChange">

      <span v-for="(item, index) in articleClassList" :key="index">
         <van-tab :title=item.title>
            <span v-for="(item, index) in articleList" :key="index" @click="goNewsDetail(item);">
              <div style="width: 100%;">
                <div style="height: 80px;margin: 15px; ">
                  <div style="width: 70%;float: left;">
                    <div style="text-align: left;float: left;">

                      <div class="van-multi-ellipsis--l2" style="font-size: medium;font-weight: 550;color: #565656;">
                        {{ item.title }}
                      </div>
                    </div>

                    <div style="text-align: left;float: left;margin-top: 20px;">
                      <span style="font-size: small;color: steelblue;">
                        #{{ item.subtitle }}
                      </span>
                      <span style="font-size: small;margin-left: 20px;color: #565656;">
                        {{ item.createdAt }}
                      </span>
                    </div>
                  </div>
                  <div style=" float: right; height: 30px; width: 30%; text-align: end;">
                    <van-image
                            cover
                            radius="6px"
                            width="80px"
                            height="80px"
                            :src=item.thumb
                    />
                  </div>
                </div>
                <van-divider/>

        </div>
            </span>
         </van-tab>
      </span>


            </van-tabs>

        </div>
        <van-tabbar route v-model="active">
            <van-tabbar-item replace to="/index" icon="wap-home">
                首页
            </van-tabbar-item>
            <!--<van-tabbar-item replace to="/news" icon="cart-o">
              在线商城
            </van-tabbar-item>-->
            <van-tabbar-item replace to="/user" icon="friends-o">
                个人中心
            </van-tabbar-item>
        </van-tabbar>

        <van-popup v-model="show" position="right" :overlay="false" :style="{ height:'100%', width: '100%' }">

            <div style="width: 100%;height: 50px;">

                <div
                        style="width: 80%;float: left;text-align: left;margin-left: 15px;font-weight: 600;font-size: large; margin-top: 3px; padding-top: 10px;align-items: center;line-height: 30px;">
                    全部疫苗
                </div>

                <div @click="hidePopup"
                     style=" font-size: small;color:#565656;margin-top: 5px;text-align: right;margin-right: 20px; line-height: 50px; ">
                    返回
                </div>
            </div>
            <van-divider style="margin: 6px 0"/>
            <div ref="container" style="padding-bottom: 15px;border-radius: 7px;margin:10px;background:#fefefc">
                <van-sticky :container="container">
                    <van-grid square :border="false">
                        <van-grid-item v-for="(item, index) in  vaccineList.slice(0 , 6)" :key="index" replace
                                       @click="goVaccine(index)">
                            <van-image height="75%" :src="item.icon"/>
                            <span class="van-grid-item-text">{{ item.title }}</span>
                            <span class="van-grid-item-sub-text">{{ item.subtitle }}</span>
                        </van-grid-item>


                    </van-grid>


                </van-sticky>
            </div>

        </van-popup>
        <div style="height: 5rem;"></div>


    </div>
</template>
<script>
import Vue from 'vue';
import {Swipe, SwipeItem, Skeleton, Dialog} from 'vant';
import {Icon} from 'vant';
import {Image as VanImage} from 'vant';
import {Col, Row} from 'vant';
import {Tab, Tabs} from 'vant';
import {Tabbar, TabbarItem} from 'vant';
import {Card} from 'vant';
import {Divider} from 'vant';
import {Popup} from 'vant';
import {Notify} from 'vant';
import comFun from '../../utils/jssdk';
import {ImagePreview} from 'vant';

Vue.prototype.comFun = comFun;
import axios from 'axios'
import VueAxios from 'vue-axios'
import {Sticky} from 'vant';
// import wx from "weixin-js-sdk";

Vue.use(ImagePreview);
Vue.use(Sticky);
Vue.use(VueAxios, axios)
Vue.use(Skeleton);

Vue.use(Popup);
Vue.use(Divider);
Vue.use(Card);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon);
export default {
    name: "index",
    data() {
        return {
            showImagePreview: false,
            clinicInfo: [],
            imageWidth: '100%',
            container: '',
            loading: true,
            initConfig: false,
            envImages: [],
            images: [
                'http://vaccin-image.wxdev.top/o_1g4ehvo9i1gmm1em115l31os6192t8.jpg',
            ],
            vaccineList: [
                {
                    "title": "新冠疫苗",
                    "subtitle": "新冠疫苗",
                    "icon": "http://vaccin-image.wxdev.top/o_1f36vt2ivm61pu21dsd1hos1m31d.png"
                },
                {
                    "title": "成人疫苗",
                    "subtitle": "九价进口疫苗",
                    "icon": "http://vaccin-image.wxdev.top/o_1g4et85irj5q7so8guas49mgd.png"
                },
                {
                    "title": "儿童疫苗",
                    "subtitle": "儿童分时预约",
                    "icon": "http://vaccin-image.wxdev.top/o_1f3a0ove1up714q91uc313tm158jd.png"
                }
            ],
            activeTab: 0,
            borderRadius: '10px',
            active: 'home',
            articleList: [],
            ad: [],
            show: false,
            banner: [],
            curCity: '',
            token: '',
            userId: '',
            articleClassList: [],

        };
    },
    mounted() {
        /*****************************************pro******************************************/
        let appid = this.comFun.getAppid();

        if (appid === undefined) {
            appid = this.$route.query.appid;
        }

        if (appid !== undefined) {
            localStorage.setItem('appid', appid);
            this.getCompanyInfo(appid);
        } else {
            appid = localStorage.getItem('appid');
            this.getCompanyInfo(appid);
        }

        let code = this.comFun.getQueryVariable('code');
        if (localStorage.getItem('userInfo' + appid) == null) {
            if (code === false || code.length < 10) {
                const appid = this.$route.query.appid;
                this.comFun.getCode(appid);
            } else {
                this.getUserInfo();
            }
        } else {
            this.comFun.initJsSDK();

        }
      this.userId = localStorage.getItem('userId');
      document.title = localStorage.getItem("companyName");
        /*****************************************pro******************************************/
    },
    created: function () {

        /*****************************************test******************************************/
        /*let appid = 'wx3a43bfbd24012c3b';
        localStorage.setItem("companyId", '62')
        localStorage.setItem("userId", '1')
        this.getCompanyInfo(appid);*/
        /*****************************************test******************************************/
    },

    methods: {
        //跳转到指定版块
        goSection(session) {
            let appid = localStorage.getItem('appid');
            this.$router.push({name: session,
                query: {appid: appid}})
        },
        //获取环境图片
        getEnvImages() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/aggregates/list', {
                "modelType": "environment",
                "state": 1,
                "title": '_',
                "parentId": 0,
                "page": 1,
                "per_page": 10,
                "companyId": userInfo.companyId
            })
                .then(function (response) {
                    that.envImages.splice(0);//清空
                    let data = response.data.data;
                    for (let i = 0; i < data.length; i++) {
                        that.envImages.push(data[i].thumb)
                    }
                    if (data.length > 0) {
                        that.showImagePreview = true
                    } else {
                        Dialog.alert({
                            title: '~~',
                            message: '暂未上传环境图片',
                            messageAlign: 'left',
                            confirmButtonColor: 'black'
                        }).then(() => {
                            // on close
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showLocation() {
            let that = this;
            Dialog({
                title: '机构地址',
                message: this.clinicInfo.address,
                messageAlign: 'left',
                confirmButtonText: '地图导航',
                cancelButtonText: '取消',
                showCancelButton: true,
                confirmButtonColor: 'black'
            }).then(() => {
                // on confirm
                that.openMap();
            })
                .catch(() => {
                    // on cancel

                });
        },
        call(phone) {
            //打电话
            //window.open('tel:10086', '_blank')
            window.location.href = 'tel:' + phone;
        },
        openMap() {
            this.comFun.openMap(this.clinicInfo);
        },
        //机构信息
        getClinicInfo() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/companies/info', {
                    id: userInfo.companyId
                }
            )
                .then(function (response) {
                    localStorage.setItem('companyName', response.data.data.title)
                    that.document.title = response.data.data.title;
                    that.clinicInfo = response.data.data;
                    //that.getCompanyInfo(response.data.data.appId);//首页轮播
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getUserInfo() {
            let code = this.comFun.getQueryVariable('code');
            let appid = this.comFun.getQueryVariable('appid');
            //alert('code = ' + code)
            let that = this;
            //请求文章列表
            axios.post('/wechat/user', {
                "appId": appid,
                "code": code
            })
                .then(function (response) {
                    //alert(JSON.stringify(response.data.data))
                    localStorage.setItem('userInfo' + appid, JSON.stringify(response.data.data))
                    localStorage.setItem('companyId', JSON.stringify(response.data.data.companyId))
                    localStorage.setItem('userId', JSON.stringify(response.data.data.id))
                    that.initConfig = true;
                    that.comFun.initJsSDK();
                    //that.getClinicInfo();
                    that.getBanner(response.data.data.companyId)
                })
                .catch(function (error) {
                    alert(error.message)
                });

        },
        goNewsDetail(item) {
            if (item.url !== null) {
                window.location.href = item.url;
            } else {
                this.$router.push({name: 'newsDetail', query: {id: item.id}})

            }
        },
        //tab切换事件
        onTabChange(index, title) {
            console.log('title ============================ ');
            console.log(title);

            //获取疫苗分类id，获取产品列表
            let articleClass = this.articleClassList[index];

            //alert(vaccineClass['id']);

            console.log('================ ' + JSON.stringify(articleClass));

            //this.categoryId = vaccineClass['id'];
            let that = this;
            //axios.defaults.headers.common['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZCIsImlzcyI6ImdkIiwiZXhwIjoxNjA5NDI5Njc1LCJpYXQiOjE2MDg1NjU2NzUsInJvbCI6IjEifQ.HNe3_MH4dAobi6i3dlDNWqRe_jwz1VC8rLMHAfCxHXcENp8dpRd8cIBIu4heB8IADYhoLRy2vIynY9ddrfV5oQ";
            //请求文章列表
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/aggregates/list', {
                "modelType": "article",
                "title": "_",
                "state": 1,
                "parentId": articleClass['id'],
                "category_ids": articleClass['id'],
                "page": 1,
                "per_page": 10,
                "companyId": userInfo.companyId,
                "company_ids": 0
            })
                .then(function (response) {
                    //that.images.splice(0);//清空
                    that.articleList = response.data.data;
                    /*for (let i = 0; i < arr.length; i++) {
                      that.images.push(arr[i].thumb);
                      console.log(arr[i].thumb);
                    }*/
                    document.title = localStorage.getItem("companyName");
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
        //获取文章分类
        getArticleCategory() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/aggregates/list', {
                "modelType": "articleCategory",
                "state": 1,
                "title": "_",
                "parentId": 0,
                "page": 1,
                "per_page": 20,
                "companyId": userInfo.companyId,
                "company_ids": 0
            })
                .then(function (response) {
                    //console.log(response);
                    //that.images.splice(0);//清空
                    that.articleClassList = response.data.data;
                    that.activeTab = that.$route.params.index ?? 0;//TODO 重新返回界面的时候再次选中之前的活动Tab
                    that.onTabChange(that.activeTab, 'title');
                    /*for (let i = 0; i < arr.length; i++) {
                      that.images.push(arr[i].thumb);
                      console.log(arr[i].thumb);
                    }*/
                    //console.log(response);
                    //that.getArticleList();
                    Vue.platformTitle = localStorage.getItem("companyName");
                    document.title = localStorage.getItem("companyName");
                })
                .catch(function (error) {
                    console.log(error)
                });
        },
        goArea() {
            this.f('area')
        },
        //广告详情
        goAdDetail(item) {
            window.location.href = item.url;
            //this.$router.push({name: 'newsDetail', query: {id: item.id}})
        },
        goArticleDetail(id) {
            //localStorage.setItem('newsId',id)
            this.$router.push({name: 'newsDetail', query: {id: id}})
            //this.$router.push({name: 'newsDetail'})
        },

        //获取公司信息
        getCompanyInfo(appid) {
            let that = this;
            axios.post('/companies/appId', {
                "appId": appid
            })
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                    localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
                    localStorage.setItem('companyName', response.data.data.title)
                    that.getBanner(response.data.data.id);
                    that.document.title = response.data.data.title;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //获取广告
        getAd() {
            let that = this;
            axios.post('/aggregates/list', {
                "modelType": "advertisement",
                "state": 1,
                "parentId": 0,
                "code": "index.middle",
                "page": 1,
                "per_page": 1,
                "company_ids": 0
            })
                .then(function (response) {
                    that.ad = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //跳转疫苗分类
        goVaccine(index) {
            this.$router.push({
                name: 'vaccine',
                params: {
                    index: index
                },
                query: {
                    index: index
                }
            });
        },
        showPopup() {
            this.$router.push({name: 'index'})
            this.show = true;
        },
        registration() {
            //部门列表
            this.$router.push({name: 'department'})
        },
        hidePopup() {
            this.show = false;
        },
        onChange(index) {
            Notify({type: 'primary', message: index});
        },
        toClinicList() {
            this.$router.push({name: 'insurance'})
        },
        toNucleinList() {
            this.$router.push({name: 'nucleinStep1'})
        },
        //在线挂号
        toDepartment() {
            this.$router.push({name: 'department'})
        },
        toDoctorList() {
            this.$router.push({name: 'doctorList'})
        },
        //获取banner
        getBanner(companyId) {
            let that = this;
            axios.defaults.headers.get['Accept'] = 'application/json'
            axios.defaults.headers.get['ContentType'] = 'application/json'
            axios.defaults.headers.common.Authorization = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZCIsImlzcyI6ImdkIiwiZXhwIjoxNjU4NzM5NzIxLCJpYXQiOjE2NTc4NzU3MjEsInJvbCI6IjEifQ.nM1kd5u8PGNFu75cwLkMY2cO1_4P020UXxpsQOa33f--mli_0ajcRq2PIYwDVYx872p3XFHmvrk9KTX5bjozqg';
            axios.post('/aggregates/list', {
                "modelType": "advert",
                "code": "index.banner",
                "title": "_",
                "state": 1,
                "parentId": 1,
                "page": 1,
                "per_page": 2,
                "companyId": companyId,
                "company_ids": 0
            })
                .then(function (response) {
                    //that.getAd();
                    that.loading = false;
                    that.getVaccineCategory();
                    that.images.splice(0);//清空
                    let arr = response.data.data;
                    console.log('banner')
                    console.log(JSON.stringify(arr))
                    that.banner = arr;
                    for (let i = 0; i < arr.length; i++) {
                        that.images.push(arr[i].thumb);
                        console.log(arr[i].thumb);
                    }
                    if (that.banner.length === 0) {
                        that.banner.push({thumb: that.clinicInfo.thumb, url: ''});
                    }

                    that.document.title = localStorage.getItem("companyName");
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //获取疫苗分类
        getVaccineCategory() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/aggregates/list', {
                "modelType": "vaccineCategory",
                "state": 1,
                "parentId": 0,
                "title": "_",
                "order_new": true,
                "page": 1,
                "per_page": 7,
                "companyId": userInfo.companyId,
                "company_ids": 0
            })
                .then(function (response) {
                    console.log(response);
                    //that.images.splice(0);//清空
                    that.vaccineList = response.data.data;
                    /*for (let i = 0; i < arr.length; i++) {
                      that.images.push(arr[i].thumb);
                      console.log(arr[i].thumb);
                    }*/
                    // console.log(response);
                    that.getArticleCategory();
                    that.document.title = localStorage.getItem("companyName");
                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
    },
    computed: {
        mainWidth: function () {
            let width = document.documentElement.clientWidth;//屏幕宽
            return (width - 30) + 'px';
        },
        swipeHeight: function () {
            let height = document.body.clientWidth * 0.45;//轮播图高

            return height + 'px';
        }, sideBarHeight: function () {
            let width = (document.body.clientWidth - 30) * 0.433;//左图宽
            let height = width / 0.9527;//左图高
            return (height - 5) + 'px';
        },
        sideBarWidth: function () {
            let width = (document.body.clientWidth - 30) * 0.433;//左图宽
            return (width - 5) + 'px';
        },
        rightBarHeight: function () {
            let width = (document.body.clientWidth - 30) * 0.433;//左图宽
            let height = (width / 0.9527) / 2;//右图高
            return (height - 5) + 'px';

        },
        rightBarWidth: function () {
            let temp = (document.body.clientWidth - 30) * 0.433;//右图宽
            let width = document.body.clientWidth - 30 - temp;
            return width + 'px';
        }
    }
}
</script>

<style scoped>
.van-grid-item__content--square {
    border-radius: 15px;
}

.van-image__img {
    width: 76%;
    vertical-align: middle;
}

.van-grid-item-sub-text {
    color: #cecece;
    font-size: small;
    white-space: nowrap
}

.van-grid-item-text {
    font-size: 0.87rem;
    color: #555555;
    white-space: nowrap;
    font-weight: 500;
}

.location-text {
    color: white;
    font-size: small;
    height: 100%;
    /*vertical-align: middle;*/
}

.location-span {
    position: absolute;
    top: 10px;
    left: 10px;
    text-align: center;
    background: white;
    border-radius: 11px;
    /*background:rgba(250,250,250,0.5);*/
    padding: 0 3px;
    background: hsla(0, 0%, 100%, .3);
    z-index: 100;
    /*align-items: center;*/
}

html {
    font-family: Microsoft YaHei, Tahoma, SimSun, Verdana;
    -webkit-text-size-adjust: none;
    height: 100%;
    width: 100%;
    font-size: 10px;
}

.van-icon-location-o {
    font-size: x-small;
    vertical-align: middle;
}
</style>