<template>
  <div>
    <div style="height: 100px;text-align: start;background: rgb(252,252,252);box-shadow:2px 2px 5px #eee;">
      <van-image
          style="margin-left: 30px;margin-top: 15px;float: left;margin-right: 20px;"
          width="60px"
          height="60px"
          :src=clinicInfo.thumb
      />
      <div style="line-height: 45px;margin-top:10px;padding-left: 20px;font-size: medium;font-weight: 550;">
        {{ clinicInfo.title }}
      </div>
      <div style="font-size: small;color: #565656;">
        {{ clinicInfo.address }}
      </div>

    </div>

    <van-divider style="margin: 10px 0 0 0"/>
    <van-field style="padding-top: 10px;" label="姓名" v-model="name" required placeholder="请输入姓名"
               input-align="right"/>

    <van-field label="手机号码" placeholder="请输入手机号码" maxlength="11" v-model="phone" input-align="right"
               required/>
    <van-field label="身份证号" placeholder="请输入身份证号" v-model="idNumber" input-align="right" required/>
    <!--<van-field name="radio" label="性别" input-align="right" >
      <template #input>
        <van-radio-group v-model="radio" direction="horizontal">
          <van-radio name="1" icon-size="16px">男</van-radio>
          <van-radio name="2" icon-size="16px">女</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-cell title="出生日期" is-link @click="clickShowBirthday" :value="birthday"  />

    <van-popup v-model="showBirthday" position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择出生日期"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirmBirthday"
          @cancel="onCancelBirthday"
      />
    </van-popup>-->

    <!--        <van-popup v-model="showPopup" position="bottom" :style="{ height: '30%' }">
                <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"/>
            </van-popup>-->


    <!--        <van-field readonly clickable label="现居地区" :value="valueArea" input-align="right"
                       placeholder="请选择现居地区"
                       @click="bindShow" required/>

            <van-popup v-model="showArea" position="bottom" :style="{ height: '40%' }">
                <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="bindCancel"/>
            </van-popup>-->

    <!--        <van-field label="详细地址" placeholder="请输入详细地址" v-model="address" input-align="right" required/>-->


    <div style="height: 5rem;"></div>
    <div class="submit-bar">
      <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                  style="border-radius: 5px;width: 150px; margin: 10px 20px;  " @click="prepayOrder">
        确认并立即支付
      </van-button>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import {Dialog, Field, Switch} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Cell, CellGroup} from 'vant';
import {Picker} from 'vant';
import {Popup} from 'vant';
import {Area} from 'vant';
import Areas from '@/utils/area'
import {DatetimePicker} from 'vant';
import {formatDate} from "@/utils/date";
import axios from "axios";
import vueEsign from 'vue-esign'
import comFun from '../../utils/jssdk';

Vue.prototype.comFun = comFun;
import wx from "weixin-js-sdk";

Vue.use(Switch);
Vue.use(vueEsign)
Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
export default {
  name: "payment",
  data() {
    return {
      clinicInfo: [],
      areaList: Areas,
      idNumber: "",
      address: true,
      phone: "",
      name: "",
      birthday: "",
      showPopup: false,
      showArea: false,
      showBirthday: false,
      radio: '1',
      currentDate: new Date(),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      valueArea: '', //地区值
      arrArea: [], //存放地区数组
      province: '',
      city: '',
      area: '',
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
      isCrop: false,
      signSrc: '',
      showSignSrc: false
    };
  },
  created() {
    let appid = this.comFun.getAppid();
    if (appid === undefined) {
      appid = this.$route.query.appid;
    }
    if (appid !== undefined) {
      localStorage.setItem('appid', appid);
      this.getCompanyInfo(appid);
    } else {
      appid = localStorage.getItem('appid');
      this.getCompanyInfo(this.activeTab, appid);
    }
    let code = this.comFun.getQueryVariable('code');
    if (localStorage.getItem('userInfo' + appid) == null) {
      if (code === false || code.length < 10) {
        const appid2 = this.$route.query.appid;
        if (localStorage.getItem('appid') === undefined) {
          localStorage.setItem('appid', appid2);
        }
        this.comFun.getCode(appid2);
      } else {
        this.comFun.getUserInfo();
      }
    } else {
      this.comFun.initJsSDK();
    }
  },
  mounted() {
    //axios.defaults.headers.common.Authorization = localStorage.getItem("token");
    /*if(localStorage.getItem('goClinicId') == null) {
      this.nearClinic()
    } else {

    }*/
    if (window.location.href.indexOf('?#') < 0) {
      window.location.href = window.location.href.replace('#', '?#');
    }
    this.getClinicInfo();
  },
  methods: {
    //自动进入最近的机构
    nearClinic() {
      let that = this;
      //axios.defaults.headers.common['Authorization'] = "Bearer " + this.token;
      axios.get('/api/company', {
        params: {
          "longitude": localStorage.getItem('longitude'),
          "latitude": localStorage.getItem('latitude'),
          "order_distance": "asc",
          "page": 1,
          "per_page": 1
        }
      })
          .then(function (response) {
            //that.clinicList = response.data.data;
            //console.log('自动进入最近的机构 = ' + val  + JSON.stringify(response.data.data))
            //超出3公里进入平台首页
            //if(response.data.data[0].distance <= 800) {
            //自动进入最近的机构
            localStorage.setItem('goClinicId', response.data.data[0].id)
            //that.$router.push({name: 'clinic'})
            that.getClinicInfo();
            //}

          })
          .catch(function (error) {
            console.log(error);
          });
    },
    handleReset() {
      this.$refs.esign.reset()
    },
    handleGenerate() {
      this.$refs.esign.generate().then(res => {
        this.signSrc = res
        this.showSignSrc = true
        console.log('签名图片。。。。。。。。。。。')
        console.log(res)
      }).catch(err => {
        console.log(err) // 画布没有签字时会执行这里 'Not Signned'
        Dialog.alert({message: '请手写签名'});
      })
    },
    //获取公司信息
    getCompanyInfo(appid) {
      let that = this;
      axios.post('/companies/appId', {
        "appId": appid
      })
          .then(function (response) {
            localStorage.setItem('companyId', JSON.stringify(response.data.data.id))
            document.title = response.data.data.title;
            that.getClinicInfo();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //机构信息
    getClinicInfo() {
      let that = this;
      let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      axios.defaults.headers.common.Authorization = localStorage.getItem("token");
      axios.post('/companies/info', {
            id: userInfo.companyId
          }
      )
          .then(function (response) {
            that.clinicInfo = response.data.data;

          })
          .catch(function (error) {
            console.log(error);
          });
    },
    payment(data) {
      wx.chooseWXPay({
        appId: data.appId,
        timestamp: data.timeStamp,
        nonceStr: data.nonceStr,
        package: data.packageVal,
        signType: data.signType,
        paySign: data.paySign,
        success: function () {
          console.log('支付成功')
        },
        cancel: function () {
          alert('取消支付')
        },
        fail: function (res) {
          console.log("fail", res);
          alert('支付失败')
        }
      })
    },
    //获取预支付订单
    prepayOrder() {
      let _this = this;
      let appid = localStorage.getItem('appid');
      let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
      axios.post('/payment/wechat', {
        "userId": userInfo.id,
        "companyId": userInfo.companyId,
      })
          .then(function (response) {
            _this.payment(response.data.data);
          })
          .catch(function (error) {
            alert(error.messages);
          });
    },
    onConfirm(value) {
      this.relation = value;
      this.showPopup = false;
    },
    onCancel() {
      this.showPopup = false;
    },
    clickShowBirthday() {
      this.showBirthday = true;
    },
    onConfirmBirthday(value) {
      // console.log(console.log( formatDate(value, 'yyyy-MM-dd')));
      this.birthday = formatDate(value, 'yyyy-MM-dd');
      this.showBirthday = false;
    },
    onCancelBirthday() {
      this.showBirthday = false;
    },
    clickShowPicker() {
      this.showPopup = true;
    },
    bindShow() {
      this.showArea = true;
    },
    bindCancel() {
      this.showArea = false;
    },
    //地区选择
    onAreaConfirm(val) {
      this.showArea = false;
      this.arrArea = val;
      this.valueArea = val[0].name + '-' + val[1].name + '-' + val[2].name;
      this.province = val[0].code;
      this.city = val[1].code;
      this.area = val[2].code;
    },

  }
}
</script>

<style scoped>
.van-collapse-item {
  text-align: left;
}

.van-cell {
  line-height: 34px;
}

.van-cell__title {
  text-align: left;
}

.van-cell__left-icon, .van-cell__right-icon {
  line-height: 34px;
}

.submit-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}

.van-cell__title[data-v-0c547fda] {
  width: 100px;
}

div.van-cell__title {
  width: 100px;
}
</style>