<template>
    <div :style="{'height':swipeHeight}" style="background: #f7f8fd">
        <div style="background: white;padding-bottom: 10px;">

            <div style="padding: 20px;">
                <span style="float: left; font-size: large;font-weight: bold">{{ vaccineInfo.title }}</span>
                <span style="text-align: right;float: right;font-size: large;color: red;">￥{{ productInfo.price }}</span>
            </div>
            <van-divider/>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: medium">生产厂家</span>
                <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{
                    productInfo.manufacturer
                    }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: medium">产品规格</span>
                <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{
                    productInfo.specifications
                    }}</span>
            </div>
            <div style="height: 30px;padding: 0 20px;">
                <span style="float: left;color: #bcbcbc;font-size: medium">接种门诊</span>
                <span style="text-align: right;float: right; font-size: medium;color: #565656;">{{
                    clinicInfo.title
                    }}</span>
            </div>

            <!--<div style="padding-left: 12px;">
              <van-image src="http://vaccine.images.wxdev.top/排队关注二维码.jpg" />
            </div>-->

            <div style="min-height: 80px;background: rgb(255,251,235);border-radius: 8px;margin:15px; ">
                <div style="text-align: left; color: #ffcb41;font-size: small;padding: 10px 10px 0 10px;font-weight: 550;">
                    排队说明
                </div>
                <div style="text-align: left; font-size: small;color: #bcbcbc;padding: 10px;">
                    {{ message }}
                </div>
            </div>
        </div>

        <div style="margin-top: 10px;">
            <van-collapse v-model="activeNames">
                <van-collapse-item style="font-size: medium;" title="选择接种人" :value="familyName" name="2" icon="user-circle-o">
                    <van-radio-group v-model="radio1">
                        <van-cell-group :border="false">
              <span v-for="(item, index) in familyList" :key="index">
                <van-cell style="font-size: medium;" :title=item.name clickable :label=label(item)>
                  <template #right-icon>
                    <van-radio @click="chooseFamily(item)" :name=item.id icon-size="16px"/>
                  </template>
                </van-cell>
              </span>
                        </van-cell-group>
                    </van-radio-group>
                    <van-divider/>
                    <div style="height: 35px;text-align: center;">
                        <van-button icon="plus" plain type="info" to="/familyList" size="small" style="font-size: 14px;">新增家庭成员</van-button>
                    </div>
                </van-collapse-item>
            </van-collapse>

            <div style="background: white;height: 120px;">
                <van-cell style="font-size: medium;" title="选择针次" icon="records" :border="false"/>

                <van-radio-group v-model="radio2" direction="horizontal" icon-size="16px">
          <span v-for="(item, index) in  injectionNumber.slice(0 , injectionTotal)" :key="index">
            <van-radio @click="chooseInjection(item.value,item.text)" :name=item.value
                       style="font-size: medium">{{ item.text }}</van-radio>
          </span>
                    <!--<van-radio name="2" style="font-size: small">第二针</van-radio>
                    <van-radio name="3" style="font-size: small">第三针</van-radio>
                    <van-radio name="4" style="font-size: small">第四针</van-radio>
                    <van-radio name="5" style="font-size: small">第五针</van-radio>-->

                </van-radio-group>
            </div>


        </div>

        <div style="height: 5rem;"></div>

        <div class="submit-bar">
            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 150px; margin: 10px 20px; " @click="queue">
                提交排队信息
            </van-button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {Collapse, CollapseItem} from 'vant';
import {Form} from 'vant';
import {RadioGroup, Radio} from 'vant';
import {Calendar} from 'vant';
import {ActionSheet} from 'vant';
//import {Toast} from 'vant';
import axios from "axios";
import {Dialog} from 'vant';

// 全局注册
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Calendar);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(Collapse);
Vue.use(CollapseItem);
export default {
    name: "queueIndex",
    data() {
        return {
            radio1: 0,
            radio2: '1',
            activeNames: ['1'],
            show: false,
            showActionSheet: false,
            actions: [],
            vaccineInfo: [],
            productInfo: [],
            clinicInfo: [],
            familyList: [],
            message: '如果排队成功将会收到通知,收到通知后请及时打开排队信息填写预约接种时间。',
            familyName: '',
            injectionNumber: [
                {value: 1, text: "第一针"}, {value: 2, text: "第二针"}, {value: 3, text: "第三针"}, {
                    value: 4,
                    text: "第四针"
                }, {value: 5, text: "第五针"},
                {value: 6, text: "第六针"}, {value: 7, text: "第七针"}, {value: 8, text: "第八针"}, {
                    value: 9,
                    text: "第九针"
                }, {value: 10, text: "第十针"},
                {value: 11, text: "第十一针"}, {value: 12, text: "第十二针"}, {value: 13, text: "第十三针"}, {
                    value: 14,
                    text: "第十四针"
                }, {value: 15, text: "第十五针"}
            ],
            injectionTotal: 1
        };
    },
    mounted() {
        //进入页面清空缓存
        localStorage.removeItem('injectionValue')
        localStorage.removeItem('familyId')

        axios.defaults.headers.common.Authorization = localStorage.getItem("token");

        this.getClinicInfo();
        this.getVaccineInfo();
        this.getFamily();

        //工作时间
        //this.getWorkHours()
    },
    computed: {
        swipeHeight: function () {
            let height = document.documentElement.clientHeight;//屏幕高
            return height + 'px';
        }
    },
    methods: {
        chooseInjection(injectionValue, injectionText) {
            localStorage.setItem('injectionValue', injectionValue);
            localStorage.setItem('injectionText', injectionText);
        },
        chooseFamily(familyInfo) {

            let that = this;
            if (familyInfo.identityCard == null || familyInfo.identityCard.length < 15) {
                Dialog.alert({
                    message: '请先完善接种人的身份证号信息',
                    messageAlign: 'left',
                }).then(() => {
                    // on confirm
                    localStorage.setItem('familyEditInfo', JSON.stringify(familyInfo))
                    that.$router.push('familyEdit')
                })
            }

            localStorage.setItem('familyId', familyInfo.id);
            localStorage.setItem('familyName', familyInfo.name);
            localStorage.setItem('familyPhone', familyInfo.phone);
            localStorage.setItem('familyGender', familyInfo.gender);
            localStorage.setItem('familyBirthday', familyInfo.birthday);
            localStorage.setItem('familyIdentityCard', familyInfo.identityCard);
            localStorage.setItem('familyProvinceId', familyInfo.provinceId);
            localStorage.setItem('familyCityId', familyInfo.cityId);
            localStorage.setItem('familyRegionId', familyInfo.regionId);
            localStorage.setItem('familyAddress', familyInfo.address);

            this.familyName = familyInfo.name;
        },
        //提交
        queue() {
            if (localStorage.getItem('familyId') === null || localStorage.getItem('familyId') === undefined) {
                Dialog.alert({
                    message: '请选择接种人',
                });
                return;
            }
            if (localStorage.getItem('injectionValue') === null || localStorage.getItem('familyId') === undefined) {
                Dialog.alert({
                    message: '请选择针次',
                });
                return;
            }
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('/queues/save', {
                "phone": localStorage.getItem('familyPhone'),
                "name": localStorage.getItem('familyName'),
                "gender": localStorage.getItem('familyGender'),
                "birthday": localStorage.getItem('familyBirthday'),
                "identityCard": localStorage.getItem('familyIdentityCard') === 'null' ? '' : localStorage.getItem('familyIdentityCard'),
                "provinceId": localStorage.getItem('familyProvinceId'),
                "cityId": localStorage.getItem('familyCityId'),
                "regionId": localStorage.getItem('familyRegionId'),
                "address": localStorage.getItem('familyAddress') === 'null' ? '' : localStorage.getItem('familyAddress'),
                "modelId": localStorage.getItem('chooseProductId'),
                "modelType": 'App\\Queues',
                "companyId": userInfo.companyId,
                "userId": userInfo.id,
                "vaccineId": this.vaccineInfo.id,
                "price": this.productInfo.price,
                "productId": this.productInfo.id,

                "injectionTotal": localStorage.getItem('injectionValue')
            })
                .then(function (response) {
                    Dialog.alert({
                        message: response.data.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close

                        that.$router.replace('index?appid=' + localStorage.getItem('appid'));
                        /*if (response.data.code === 200) {
                            that.$router.replace({name: 'quSubmitSuccess', query: {id: response.data.data.id}})
                        }*/
                        //that.$router.back();
                    });

                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        label(item) {
            let sex = item.gender === 1 ? '男' : '女';

            return item.relationship + '/' + sex + ' ' + item.birthday;
        },
        //疫苗信息
        getVaccineInfo() {
            //alert(localStorage.getItem('chooseProductId'))
            let that = this;

            axios.post('/products/info', {
                    id: localStorage.getItem('chooseProductId')
                }
            )
                .then(function (response) {
                    that.vaccineInfo = response.data.data.vaccine;
                    that.productInfo = response.data.data.product;
                    if (that.productInfo.queuingDescription != null) {
                        that.message = that.productInfo.queuingDescription;
                    }
                    if (that.productInfo.queuingSubmitDescription != null) {
                        localStorage.setItem('queuingSubmitDescription', that.productInfo.queuingSubmitDescription);
                    }
                    Dialog.alert({
                        title: '排队说明',
                        message: that.message,
                        messageAlign: 'left',
                        confirmButtonColor: 'black'
                    }).then(() => {
                        // on close
                    });
                    //针次
                    that.injectionTotal = that.vaccineInfo.injectionTotal;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //机构信息
        getClinicInfo() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.defaults.headers.common.Authorization = localStorage.getItem("token");
            axios.post('/companies/info', {
                    id: userInfo.companyId
                }
            )
                .then(function (response) {
                    that.clinicInfo = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //家庭成员
        getFamily() {
            let that = this;
            let appid = localStorage.getItem('appid');
            let userInfo = JSON.parse(localStorage.getItem("userInfo" + appid));
            axios.post('families/list', {
                    "userId": userInfo.id
                }
            )
                .then(function (response) {
                    that.familyList = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //工作时间
        /*getWorkHours() {
          let that = this;
          axios.get('/api/vaccine/product/' + localStorage.getItem('chooseProductId')
          )
              .then(function (response) {
                //let {name: '8:00 - 11:00'}, {name: '14:00 - 16:30'}
                //遍历对象
                let arr = response.data.data.company.working_hours.time;
                //alert(JSON.stringify( arr  ))
                for (let i = 0; i < arr.length; i++) {
                  /!*if () {
                    # TODO 如果还没约完次数
                  }*!/
                  console.log(JSON.stringify( arr[i]))
                  that.actions.push({ name: arr[i].between});
                }

                //that.actions = response.data.data;
              })
              .catch(function (error) {
                console.log(error);
              });
        },*/
        showPopup() {
            this.show = true;
        },

        onSelect(item) {
            // 默认情况下点击选项时不会自动收起
            // 可以通过 close-on-click-action 属性开启自动收起
            this.showActionSheet = false;
            this.chooseTime = item.name;
            //Toast(item.name);
        },
    }
}
</script>

<style scoped>
.van-collapse-item {
    text-align: left;
}

.van-cell__title {
    text-align: left;
}

.van-radio-group--horizontal {
    background: white;
    height: 45px;
}

.van-radio__label {
    margin: 10px;
}

/*.van-radio__icon {
  margin-left: 20px;
}*/
.van-radio--horizontal {
    margin: 10px 15px;
}
.van-cell__title, .van-collapse-item {
    font-size: medium;
}
.van-cell__title {
    font-size: medium;
}
.van-collapse-item {
    font-size: medium;
}
.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

.van-dialog {
    border-radius: 6px;
}
</style>