<template>
  <div>
    <div style="width: 100%;">
      <span v-for="(item, index) in clinicList" :key="index" @click="toClinicIndex(item)">

        <div style="height: 80px;margin: 15px; ">
          <div style="width: 70%;float: left;">
            <div style="text-align: left; ">

              <div class="van-multi-ellipsis--l1" style="font-size: medium;font-weight: 550;color: #565656;">
                {{item.title}}
              </div>
            </div>

            <div style="width: 100%;align-items: start;text-align: left; margin-top: 10px;font-size: small;">
                  {{item.address}}
            </div>
            <div class="van-multi-ellipsis--l2" style="text-align: left;font-size: small;color: #565656;">
                  {{item.working_hours}}
            </div>
          </div>
          <div style=" float: right; height: 30px; width: 30%; text-align: end;">
            <van-image
                cover
                radius="6px"
                width="80px"
                height="80px"
                :src=item.thumb
            />
          </div>
        </div>
        <van-divider/>
      </span>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import {Search} from 'vant';
import axios from "axios";

Vue.use(Search);

export default {
  name: "Collection",
  data() {
    return {
      clinicList: []
    }
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    this.getClinicList();
  },
  methods: {
    toAppointment() {
      this.$router.push({name: 'step1'})
    },
    toClinicIndex(item) {
      localStorage.setItem('goClinicId',item.id)
      this.$router.push({name: 'clinic'})
    },
    //获取收藏列表
    getClinicList() {
      let that = this;
      //axios.defaults.headers.get['Accept'] = 'application/json'
      //axios.defaults.headers.get['ContentType'] = 'application/json'
      axios.get('/api/collection/company', {
        params: {
          "longitude":localStorage.getItem('longitude'),
          "latitude":localStorage.getItem('latitude'),
          "order_distance":"asc"
        }
      })
          .then(function (response) {
            //that.images.splice(0);//清空
            that.clinicList = response.data.data;
            /*let arr = response.data.data;
            for (let i = 0; i < arr.length; i++) {
              that.images.push(arr[i].thumb);
              console.log(arr[i].thumb);
            }*/
          })
          .catch(function (error) {
            console.log(error);
          });
    },
  }
}
</script>

<style scoped>
.location-text {
  color: #565656;
  font-size: small;
  height: 100%;
  /*vertical-align: middle;*/
}

.location-span {
  position: absolute;
  top: 58px;
  left: 80%;
  text-align: center;
  background: white;
  border-radius: 11px;
  float: right;
  border: 1px solid #bcbcbc;
  /*background:rgba(250,250,250,0.5);*/
  padding: 0 5px;

  z-index: 100;
  /*align-items: center;*/
}

.van-icon-location-o {
  font-size: x-small;
  vertical-align: middle;
}
</style>