<template>
  <div>
    <div v-for="(item,index) in dataList" :key="index">
    <van-cell :title="item.date" />
      <van-row v-for="(child,index2) in item.child" :key="index2">
        <van-col span="6"><van-icon name="clock" />{{child.time}}</van-col>
        <van-col span="6">{{child.value1}}mmol/L</van-col>
        <van-col span="6">{{child.value2}}</van-col>
        <van-col span="6">{{ sugarRange(child.value1,child.value2)}}</van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "allSugarData",
  data() {
    return {
      fileList: [],
      activeTab: 0,
      type: '',
      dataList: [],
      userInfo: [],
    };
  },
  mounted() {
    document.title = '所有血糖数据'
    this.userInfo = JSON.parse(localStorage.getItem("userInfo" + localStorage.getItem('appid')));
    setTimeout(() => {
      this.getData()
    }, 200);
  },
  methods: {
    /* 1. 空腹血糖正常值：3.9～6.1毫摩尔/升。
             2. 餐后2小时血糖正常值：3.9-7.8毫摩/升。
             */
    sugarRange(num,str) {
      switch (str) {
        case '空腹':
          return  num > 6.1 ? '偏高' :'正常';
        case '饭后2小时':
          return  num > 7.8? '偏高' :'正常';
        default:
          //未知
          return  num > 7.8? '偏高' :'正常';
      }
    },
    //获取血糖数据
    getData() {
      const that = this;
      axios.post('/chronic/index', {
        "companyId": this.userInfo.companyId,
        "userId": this.userInfo.id,
        "type": 2,
        "limit": 300,
        "startDate": '2024-01-01'
      })
          .then(function (response) {
            let tempDate = '';
            //先组装第一层列表date
            response.data.data.forEach(function (element) {
              if (element.date !== tempDate) {
                tempDate = element.date;
                that.dataList.push(element);
              }
            })
            //将第二层元素组装到child字段里面
            for (let i = 0; i < that.dataList.length; i++) {
              if(that.dataList[i].child === undefined) {
                that.dataList[i].child = [];
              }
              response.data.data.forEach(function (element) {
                if (element.date === that.dataList[i].date) {
                  that.dataList[i].child.push(element);
                }
              })
            }
          })
          .catch(function (error) {
            alert(error)
            console.log("请求失败 " + JSON.stringify(error));
          });
    },
  }

}
</script>
<style scoped>
.van-icon-like::before {
  color: red;
}
.van-col--6 {
  line-height: 2;
}
.van-cell {
  background: #EEEEEE;
}
</style>