<template style="background: #eeeeee">
    <div style="background: #eeeeee">
        <div>
            <img :src=info.picture
                 style="z-index: 1;position: static;width: 100%; "/>
        </div>


        <div style=" border-radius: 8px; background: #eeeeee ">

            <div style="padding: 20px;background: white;">
                <div style="float: left;font-weight: bold;font-size: medium;text-align: left;">{{ info.title }}</div>
                <div style="text-align: left; color: orangered;font-size: 20px;">￥ {{ info.price }}</div>
            </div>
            <van-divider/>
            <div style="padding: 10px;height: 60px;background: white;margin-top: 10px;">
                <div style="height: 35px;padding: 0 20px;float: none;line-height: 30px;">
                    <div style="float: left;color: #bcbcbc;font-size: medium">配送</div>
                    <div style="text-align: right;float: right; font-size: medium;color: #565656;">
                        {{ info.delivery }}
                    </div>
                </div>
                <div style="height: 35px; line-height: 30px;padding: 0 20px;">
                    <div style="float: left;color: #bcbcbc;font-size: medium">已选</div>
                    <div style="text-align: right;float: right; font-size: medium;color: #565656;">
                        {{ desc }}
                    </div>
                </div>
            </div>
            <div style="background: white">
                <p v-html="info.detail" class="content"
                   style=" width: 100%;overflow: hidden; "></p>
            </div>

            <div style="height: 5rem;"></div>
        </div>
        <div class="submit-bar">
            <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                        style="border-radius: 5px;width: 95%; margin: 10px; " @click="show=true">
                立即购买
            </van-button>
            <van-action-sheet title=" " v-model="show" closeable close-icon="cross" style=" ">
                <van-card style="text-align:left;padding-top: 25px;"
                          :num="number"
                          :price="price"
                          :desc="desc"
                          :title="info.title"
                          :thumb="info.picture"
                />

                <div style="text-align: left;margin : 15px; "
                     v-for="(spec,index) in specMap" :key="index">
                    <label style="font-size: 13px;">{{ spec[0] }}</label>
                    <div class="btnGroup">
                        <button
                            v-for="(item, index2) in spec[1]"
                            :key="index2"
                            @click="changeColor(index, index2)"
                            :class="activeIndex[index] === index2 ? 'active' : ''"
                        >
                            {{ item.title }}
                        </button>
                    </div>
                </div>
                <van-cell>
                    <span style="text-align: left;float: left;font-size: 13px; "> 数量</span>
                    <van-stepper v-model="number" style="text-align: right;font-size: 13px;"/>
                </van-cell>

                <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                            style="border-radius: 5px;width: 92%; margin: 10px; " @click="goProductConfirm">
                    立即购买
                </van-button>
            </van-action-sheet>
        </div>
    </div>


</template>

<script>
import Vue from 'vue';
import VueAxios from "vue-axios";
import axios from "axios";
import {SubmitBar} from 'vant';
import {Button} from 'vant';
import {Stepper} from 'vant';

Vue.use(Button);
Vue.use(SubmitBar);
Vue.use(Stepper);

Vue.use(VueAxios, axios)
export default {
    name: "ProductDetail",
    data() {
        return {
            listData: ["上门1次", "上门2次", "上门3次"],
            listData2: ["打1针", "打2针", "打3针"],
            // 标记被选中的按钮的index
            activeIndex: [],
            desc: '',
            checked: 1,
            checked2: 1,
            number: 1,
            show: false,
            loading: true,
            banner: [],
            active: 'news',
            info: [],
            articleClassList: [],
            articleList: [],
            activeTab: 0,
            specMap: [],
            selectSpec: [],
            selectSpecId: [],
            selectSpecPrice: [],
            basePrice: '',
            price: ''
        };
    },
    mounted: function () {
        document.title = "护理上门"
        this.getProductInfo()
    },
    methods: {
        goProductConfirm() {
            //商品数量
            localStorage.setItem('selectNumber', this.number);
            this.$router.push({name: 'ProductConfirm'})
        },
        changeColor(index, index2) {
            // alert(index + " - " + index2);
            this.activeIndex[index] = index2
            this.selectSpec[index] = this.specMap[index][1][index2].title;
            this.selectSpecId[index] = this.specMap[index][1][index2].id;
            this.selectSpecPrice[index] = this.specMap[index][1][index2].price;

            localStorage.setItem('selectSpecId', this.selectSpecId.toString());

            //this.desc = '已选： ' +  this.selectSpec.toString();
            this.desc = '已选： ' + this.selectSpec.toString();
            localStorage.setItem('selectSpec', this.selectSpec.toString());

            let tempTotal = 0.00;
            //价格累计
            for (let i = 0; i < this.selectSpecPrice.length; i++) {
                tempTotal += this.selectSpecPrice[i];
            }

            this.price = (this.basePrice + tempTotal).toFixed(2);
            localStorage.setItem('selectSpecPrice', this.price);

        },

        productDetail() {
            this.$router.push({name: 'index'})
        },
        goMall() {
            this.$router.push({name: 'index'})
        },
        goNewsDetail(itemId) {
            //localStorage.setItem('newsId',itemId)
            //this.$router.push({name: 'newsDetail'})
            this.$router.push({name: 'newsDetail', query: {id: itemId}})
        },
        //获取产品详情
        getProductInfo() {
            let that = this;
            axios.post('/mall/product_info', {
                "id": this.$route.query.id
            })
                .then(function (response) {
                    console.log(response);
                    //that.images.splice(0);//清空
                    that.info = response.data.data;
                    that.specMap = response.data.data.specMap;
                    that.basePrice = that.info.price
                    that.price = that.info.price.toFixed(2)
                    localStorage.setItem('ProductInfo', JSON.stringify(that.info));
                    //默认选中第一个选项
                    for (let i = 0; i < that.specMap.length; i++) {
                        that.changeColor(i, 0)
                    }

                })
                .catch(function (error) {
                    console.log(123123)
                    console.log(error);
                });
        },
    },
}
</script>

<style scoped>
.van-divider {
    margin: 6px 0 0 0;
}

/* CSS样式 */
.van-cell__value {
    display: -webkit-box; /* 必须设置display属性为-webkit-box */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 显示省略号 */
    -webkit-line-clamp: 2; /* 限制显示两行 */
    -webkit-box-orient: vertical; /* 垂直方向上的换行 */
}

.submit-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    -webkit-user-select: none;
    user-select: none;
}

.content >>> img {
    max-width: 100%;
    height: auto;
    display: flex;
}

.van-card__price-integer {
    color: red;
    font-size: 23px;
}

.van-card__price {
    color: red;
    font-size: 23px;
}

.van-card__num {
    color: #222222;
    font-size: 15px;
    font-weight: bolder;
}


</style>

<style lang="less" scoped>
.btnGroup {
    button {
        height: 25px;
        font-size: 15px;
        margin: 20px 0 20px 20px;
        padding: 0 10px;
        background-color: #EEEEEE;
        border: 1px solid #BBBBBB;
        border-radius: 15px;
    }

    // 被选中的按钮的样式
    .active {
        background-color: red;
        border: 0;
        color: white;

    }
}
</style>