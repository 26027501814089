import { render, staticRenderFns } from "./NoticeList.vue?vue&type=template&id=504a4238&scoped=true&"
import script from "./NoticeList.vue?vue&type=script&lang=js&"
export * from "./NoticeList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504a4238",
  null
  
)

export default component.exports