<template>
  <div>
    <van-cell-group inset style="margin: 20px;">
      <van-field v-model="name" label="服务对象" placeholder="请输入姓名"/>
      <van-field v-model="phone" label="手机号码" placeholder="请输入手机号码"/>

      <van-field v-model="dateTime" label="上门时间" placeholder="请选择日期时间" @click="visible=true">
      </van-field>
      <vue-hash-calendar format="YY-MM-DD hh:mm" :minDate="minDate" :visible="visible" model="dialog"
                         @confirm="confirmDate"/>

      <van-field v-model="address" label="上门地址" placeholder="请选择上门地址"/>

    </van-cell-group>

    <van-card style="text-align:left;padding-top: 25px;"
              :num="number"
              :price="price"
              :desc="desc"
              :title="info.title"
              :thumb="info.picture"
    />
    <van-cell-group inset style="padding-bottom: 60px;">
      <van-field value="￥0.00" label="配送费用" input-align="right" readonly/>
      <van-field label="优惠券" placeholder="暂无可用" readonly/>
      <van-field v-model="mark" label="订单备注" placeholder="选填，给商家留言"/>
      <van-field v-model="totalPrice" label="商品总额" input-align="right" readonly/>
      <van-field value="￥0.00" label="运费" input-align="right" readonly/>
    </van-cell-group>


    <div class="submit-bar">
      <van-button color="linear-gradient(to right, #8dc3f9, #1989fa)"
                  style="border-radius: 5px;width: 95%; margin: 10px; " @click="orderConfirm">
        提交订单
      </van-button>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import VueAxios from "vue-axios";
import axios from "axios";

import { Dialog } from 'vant';
import wx from "weixin-js-sdk";

Vue.use(VueAxios, axios, Dialog)
export default {
  name: "ProductConfirm",

  data() {
    return {
      visible: false,
      date: null,
      minDate: new Date(),
      maxDate: new Date() + 7,
      mark: '',
      totalPrice: '',
      name: '',
      phone: '',
      dateTime: '',
      address: '',
      info: [],
      number: 1,
      price: 0.00,
      desc: '',
      articleClassList: [],
      articleList: [],
      activeTab: 0,
      productList: [],
      currentDate: '',
      currentTime: '',
      onConfirm: '',
      userInfo: [],
      onCancel: ''
    };
  },
  mounted: function () {
    document.title = "护理上门"
    this.info = JSON.parse(localStorage.getItem('ProductInfo'))
    this.desc = '已选：' + localStorage.getItem('selectSpec')
    this.price = localStorage.getItem('selectSpecPrice')
    this.number = localStorage.getItem('selectNumber')
    this.totalPrice = (localStorage.getItem('selectNumber') * localStorage.getItem('selectSpecPrice')).toFixed(2);
  },
  methods: {
    confirmDate(value) {
      this.visible = false;
      this.dateTime = value;
    },

    productDetail(itemId) {
      this.$router.push({name: 'ProductDetail', query: {id: itemId}})
    },

    goNewsDetail(itemId) {
      //this.$router.push({name: 'newsDetail'})
      this.$router.push({name: 'newsDetail', query: {id: itemId}})
    },

    //提交订单
    orderConfirm() {
      if(this.name.length === 0 || this.phone.length === 0 || this.address.length === 0 || this.dateTime.length === 0) {
        Dialog.alert({
          message: '请填写姓名、电话、上门时间和上门地址',
        }).then(() => {
          // on close
        });

        return;
      }

      let that = this;
      axios.post('/mall/order_save', {
        "companyId": "65",
        "categoryId": "2",
        "userId": 25851,
        "name": that.name,
        "phone": that.phone,
        "price": that.price,
        "dateTime": that.dateTime,
        "address": that.address,
        "mark": that.mark,
        "productId": that.info.id,
        "productSpec": localStorage.getItem("selectSpec"),
        "productSpecId": localStorage.getItem("selectSpecId"),
        "totalPrice": that.totalPrice,
        "number": that.number,
        "productPicture": that.info.picture,
        "productTitle": that.info.title

      })
          .then(function (response) {
            console.log(response);
            that.payment(response.data.data);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    payment(data) {
      wx.chooseWXPay({
        appId: data.appId,
        timestamp: data.timeStamp,
        nonceStr: data.nonceStr,
        package: data.packageVal,
        signType: data.signType,
        paySign: data.paySign,
        success: function () {
          console.log('支付成功')
        },
        cancel: function () {
          alert('取消支付')
        },
        fail: function (res) {
          console.log("fail", res);
          alert('支付失败')
        }
      })
    }
  }
}
</script>

<style scoped>
.van-divider {
  margin: 6px 0 0 0;
}

/* CSS样式 */
.productTitle {
  display: -webkit-box; /* 必须设置display属性为-webkit-box */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  -webkit-line-clamp: 2; /* 限制显示两行 */
  -webkit-box-orient: vertical; /* 垂直方向上的换行 */
  text-align: left;
}

.van-price {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  align-items: start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: start;
}
</style>