<template>
  <div style="background: #f7f8fd;">
    <div>
      <!--      <van-image contain width="100%" :style="{'height':swipeHeight}" src="https://img.yzcdn.cn/vant/cat.jpeg"/>-->
      <img :style="{'height':swipeHeight}" :src=clinicInfo.thumb
           style="z-index: 1;position: static;width: 100%; "/>
    </div>

    <div
        style="background: white;height: 180px; margin: -35px 15px 0 15px;border-radius: 4px;z-index: 2; position: absolute;box-shadow: 2px 2px 5px 2px #eee;"
        :style="{'width':mainWidth}">
      <div>
        <div style="width: 80%;float:left;">
          <div class="van-ellipsis"
               style="font-size: large;font-weight: 650;color: #008000;margin: 15px;text-align: left">
            {{ clinicInfo.title }}
          </div>
        </div>
        <div style="text-align: right;padding-top: 10px;padding-right: 10px;">
          <van-button v-if="collected === false" icon="plus" size="mini" @click="collect" plain type="info">收藏</van-button>
          <van-button v-else size="mini" plain type="info" style="color: #bcbcbc;border-color: #bcbcbc;" @click="unCollect">已收藏</van-button>
        </div>
      </div>

      <div style="width: 100%;">
        <div style="width: 80%;float:left;">
          <div class="van-multi-ellipsis--l2"
               style="font-size: small;color: #565656;margin-left:15px;text-align: left;line-height: 20px;height: 40px;">
            上班时间：{{ clinicInfo.working_hours }}
          </div>
          <div class="van-multi-ellipsis--l2"
               style="font-size: small;color: #565656;margin-left:15px;text-align: left;line-height: 20px;height: 40px;">
            机构地址：{{ clinicInfo.address }} <!-- TODO 导航到机构-->
          </div>
        </div>

        <div style="width: 20%;float: left;">
          <van-icon size="20" color="#555555" name="phone" @click="call(clinicInfo.telphone)" style="margin-top: 5px;margin-left: 10px;"/>
        </div>

        <div style="width: 20%;float: left;">
          <van-icon size="20" color="#555555" name="location" @click="openMap" style="margin-top: 5px;margin-left: 10px;"/>
        </div>

      </div>

      <div style="width: 100%; position: relative;float: left;">
        <van-grid clickable :column-num="2" style="padding: 0;border:0;background: #efefef;" :border="false">
          <van-grid-item icon="hotel-o" text="机构简介" to="/clinicInfo"/>
          <van-grid-item icon="idcard" text="机构环境" @click="showImagePreview = true"/>
        </van-grid>
      </div>

      <van-image-preview v-model="showImagePreview " :images="images" @change="onChange">
        <!--<template v-slot:index>第{{ index }}页</template>-->
      </van-image-preview>

    </div>

    <div style="height: 170px;width: 80%;">
      <!--占位-->
    </div>



    <div style="width: 100%;background: white;margin-top: 20px">

      <van-notice-bar @click="goNoticeList"
          left-icon="volume-o"  color="#1989fa" background="#ecf9ff"
          :text="noticeList"
      />

      <!--<div style="height: 1rem;"></div>-->
        <div style="margin-top: 15px;margin-left: 15px; float: left; text-align: left; font-weight: 550; font-size: medium;line-height: 30px; ">
           机构服务
        </div>


      <div
          style="float: left; width:90%; text-align: left;margin-left: 15px;  ">
        <van-grid :border="false" :column-num="4">

          <!--<van-grid-item to="/product">
            <van-image width="100%" alt="开始预约" src="http://vaccin-image.wxdev.top/12811670589677_.pic.jpg"/>
          </van-grid-item>

          <van-grid-item  @click="goDoctor" >
            <van-image width="100%" alt="家庭医生" src="http://vaccin-image.wxdev.top/12791670589676_.pic.jpg"/>>-->
            <!--<span class="van-grid-item-text">家庭医生签</span>
          </van-grid-item>-->


          <!-- <van-grid-item  to="insuranceList" >
             <van-image width="100%" alt="医保定点" src="http://vaccin-image.wxdev.top/12821670589678_.pic.jpg"/>

             <span class="van-grid-item-text">医保定点</span>

          </van-grid-item>

          <van-grid-item @click="goReserve">
            <van-image width="100%" alt="我的预约" src="http://vaccin-image.wxdev.top/12801670589677_.pic.jpg"/>
          </van-grid-item>

          <van-grid-item  @click="specialService" >
            <van-image width="100%"  alt="特色服务" src="http://vaccine.images.wxdev.top/特色服务2.png"/>
          </van-grid-item>
           -->
          <van-grid-item icon="underway-o" text="开始预约" to="/product"/>
          <van-grid-item icon="user-o" text="家庭医生" @click="goDoctor"/>
          <van-grid-item icon="hotel-o" text="医保定点" to="insuranceList"/>
          <van-grid-item icon="todo-list-o" text="我的预约" @click="goReserve"/>

          <van-grid-item icon="apps-o" text="特色服务"  @click="specialService" />

        </van-grid>


      <!--<div style="position: relative;width: 100%;height:10px;margin-top: 20px;background: #565656;align-items: center;">


      </div>-->
      <!-- 广告位 -->

      <div style="margin-top: 20px;background: white;align-items: center;">
        <!--      <van-sticky :container="ad">-->
        <span v-for="(item, index) in ad" :key="index">
          <van-image style="margin: 10px 0;"
                     width="100%"
                     fit="contain"
                     :src=item.thumb
                     @click="goAdDetail(item)"

          />
        </span>

        <!--      </van-sticky>-->

      </div>
      <div
          style="float: left;padding-bottom:10px;float: left;position: relative;width:90%;height:30px;text-align: left; font-weight: 550;font-size: medium;align-items: center;line-height: 30px;">
        健康科普
      </div>
      <!--<van-divider/>-->
      <!--<div>
        <span v-for="(item, index) in  articleList.slice(0 , 20)" :key="index" @click="goArticleDetail(item.id)">
          <van-image @click="goArticleDetail(item.id)" contain width="94%" :style="{'height':recommendHeight}" style="border-radius: 8px;margin: 15px 3%;"
                   :src=item.thumb />
        </span>
      </div>-->


        <span style="float:left;" v-for="(item, index) in  articleList.slice(0 , 20)" :key="index" @click="goArticleDetail(item.id)">
          <!--<van-divider/>-->
          <div style="height: 80px;margin: 15px ">
            <div style="width: 70%;float: left;">
              <div style="text-align: left;float: left;">

                <div class="van-multi-ellipsis--l2" style="font-size: medium;font-weight: 550;color: #565656;">
                  {{item.title}}
                </div>
              </div>

              <div style="text-align: left;float: left;margin-top: 20px;">
                  <span style="font-size: small;color: steelblue;">
                    #{{item.category.title}}
                  </span>
                <span style="font-size: small;margin-left: 20px;color: #565656;">
                    {{item.created_at}}
                  </span>
              </div>
            </div>
            <div style=" float: right; height: 30px; width: 30%; text-align: end;">
              <van-image
                  cover
                  radius="6px"
                  width="80px"
                  height="80px"
                  :src=item.thumb
              />
            </div>

          </div>
          <van-divider style="margin: 0"/>
        </span>
        <div style="width: 100%;height: 5rem;float: left"></div>


      <van-divider/>

    </div>
      <!--<div style="height: 1rem;"></div>-->
    </div>
    <van-divider />



    <van-tabbar route v-model="active" >
      <van-tabbar-item replace to="/clinic" icon="hotel-o">
        机构主页
      </van-tabbar-item>
      <van-tabbar-item replace to="/article" icon="newspaper-o">
        健康资讯
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="friends-o">
        个人中心
      </van-tabbar-item>
    </van-tabbar>

  </div>
</template>
<div>

</div>
<script>
import Vue from 'vue';
import {Dialog, Swipe, SwipeItem} from 'vant';
import {Lazyload} from 'vant';
import {Grid, GridItem} from 'vant';
import {Tabbar, TabbarItem} from 'vant';
import {Image as VanImage} from 'vant';
import {ImagePreview} from 'vant';
import {Icon} from 'vant';
import {Tab, Tabs} from 'vant';
import axios from "axios";
import { NoticeBar } from 'vant';
import wx from 'weixin-js-sdk'

Vue.use(NoticeBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);

export default {
  name: "clinic",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      active: 'clinic',
      showImagePreview: false,
      index: 0,
      ad:[],
      images: [],
      articleList:[],
      clinicInfo:[],
      noticeList:'',
      collected:false,
      clinicArticleList:[],
      platformArticleList:[]
    };
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
    this.getClinicInfo();
    //this.getArticleList();
    //this.getClinicArticleList();
    //this.getNoticeList();
    this.getAd();
    //this.getEnvImages();
    //this.getCollectionState();
  },
  methods: {
    //收藏
    collect() {
      let that = this;
      axios.put('/api/collection/company',{
          "collection":1,
          "company_id": localStorage.getItem('goClinicId') * 1
      })
          .then(function (response) {
            Dialog.alert({
              message: response.data.message,
              messageAlign: 'left',
              confirmButtonColor: 'black'
            }).then(() => {
              that.getCollectionState();
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    unCollect() {
      Dialog.confirm({
        message: '机构已收藏，确定取消收藏吗？',
        messageAlign: 'left',
        confirmButtonColor: 'black'
      }).then(() => {
        let that = this;
        axios.put('/api/collection/company',{
          "collection":0,
          "company_id": localStorage.getItem('goClinicId') * 1
        })
            .then(function (response) {
              Dialog.alert({
                message: response.data.message,
                messageAlign: 'left',
                confirmButtonColor: 'black'
              }).then(() => {
                that.getCollectionState();
              });
            })
            .catch(function (error) {
              console.log(error);
            });
      });

    },
    specialService () {
      let that = this;
      axios.get('/api/aggregate',{
        params: {
          "model_type":"individualization",
          "page":1,
          "per_page":1,
          "company_ids":localStorage.getItem('goClinicId')
        }
      })
          .then(function (response) {
            let data = response.data.data;
            // TODO 个性化服务只能显示一条
            that.$router.push({name: 'newsDetail', query: {id: data[0].id}})
          })
          .catch(function (error) {
            console.log(error);
          });
      //this.$router.push({name: 'newsDetail', query: {id: id}})
    },
    goDoctor () {
      this.$router.push('doctorList')
    },
    goReserve () {
      this.$router.push('reserveList')
    },
    //广告详情
    goAdDetail(item) {
      window.location.href = item.url;
    },
    //获取广告
    getAd() {
      let that = this;
      axios.get('/api/aggregate',{
        params: {
          "model_type":"advertisement",
          "code":"index.middle",
          "page":1,
          "per_page":1,
          "company_ids":0
        }
      })
          .then(function (response) {
            that.ad = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    goNoticeList(){
      this.$router.push({name: 'noticeList'})
    },
    goArticleDetail(id) {
      /*localStorage.setItem('newsId',id)
      this.$router.push({name: 'newsDetail'})*/
      this.$router.push({name: 'newsDetail', query: {id: id}})
    },
    openMap(){
      wx.openLocation({
        latitude: this.clinicInfo.latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude: this.clinicInfo.longitude, // 经度，浮点数，范围为180 ~ -180。
        name: this.clinicInfo.title, // 位置名
        address: this.clinicInfo.address, // 地址详情说明
        scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },
    call(phone) {
      //打电话
      //window.open('tel:10086', '_blank')
      window.location.href = 'tel:' + phone;
    },
    onChange(index) {
      this.index = index;
    },
    //获取机构详情
    getClinicInfo() {
      let that = this;
      axios.get('/api/company/'+ localStorage.getItem('goClinicId'))
          .then(function (response) {
            //that.images.splice(0);//清空
            console.log('机构详情：' + JSON.stringify(response.data.data))
            that.clinicInfo = response.data.data;
            document.title = that.clinicInfo.title;
            /*that.images.push(response.data.data.business_license)
            that.images.push(response.data.data.medical_license)*/
            /*let arr = response.data.data;
            for (let i = 0; i < arr.length; i++) {
              that.images.push(arr[i].thumb);
              console.log(arr[i].thumb);
            }*/
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //获取环境图片
    getEnvImages() {
      let that = this;
      axios.get('/api/aggregate/' ,{
        params: {
          "model_type":"environmental",
          "page":1,
          "per_page":10,
          "company_ids":localStorage.getItem('goClinicId')
        }
      })
          .then(function (response) {
            //that.images.splice(0);//清空
            let data = response.data.data;
            for (let i = 0; i < data.length; i++) {
              that.images.push(data[i].thumb)
            }
            //document.title = that.clinicInfo.title;
            /*that.images.push(response.data.data.business_license)
            that.images.push(response.data.data.medical_license)*/
            /*let arr = response.data.data;
            for (let i = 0; i < arr.length; i++) {
              that.images.push(arr[i].thumb);
              console.log(arr[i].thumb);
            }*/
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //是否收藏机构
    getCollectionState() {
      let that = this;
      //axios.defaults.headers.get['Accept'] = 'application/json'
      //axios.defaults.headers.get['ContentType'] = 'application/json'
      axios.get('/api/collection/company/exists', {
        params: {
          "company_id":localStorage.getItem('goClinicId')
        }
      })
          .then(function (response) {
            //that.images.splice(0);//清空
            //that.clinicList = response.data.data;

            that.collected = response.data.data;

          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //获取通知列表
    getNoticeList() {
      let that = this;
      axios.get('/api/aggregate',{
        params: {
          "model_type":"notice",
          "page":1,
          "per_page":1,
          "company_ids":localStorage.getItem('goClinicId')
        }
      })
          .then(function (response) {
            //that.images.splice(0);//清空
            //that.noticeList = response.data.data;

            let arr = response.data.data;
            for (let i = 0; i < arr.length; i++) {
              that.noticeList += '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + arr[i].description +  '\xa0\xa0\xa0\xa0\xa0\xa0\xa0'

            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    //获取推荐的一篇文章
    getArticleList() {
      let that = this;
      axios.get('/api/aggregate',{
        params: {
          "model_type":"article",
          "page":1,
          "per_page":10,
          "company_ids":0
        }
      })
          .then(function (response) {
            console.log('获取推荐的一篇文章')
            console.log(JSON.stringify(response.data.data))
            //that.articleList = response.data.data;
            for (let i in response.data.data) {
              that.articleList.push(response.data.data[i])
              //alert(JSON.stringify(response.data.data[i]))
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getClinicArticleList() {
      let that = this;
      axios.get('/api/aggregate',{
        params: {
          "model_type":"article",
          "page":1,
          "per_page":10,
          "company_ids":localStorage.getItem('goClinicId')
        }
      })
          .then(function (response) {
            console.log('获取机构文章')
            console.log(JSON.stringify(response.data.data))
            that.articleList = response.data.data;
            /*for (let i in response.data.data) {
              that.articleList.push(response.data.data[i])
              //alert(JSON.stringify(response.data.data[i]))
            }*/
            that.getArticleList();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
  },
  computed: {
    swipeHeight: function () {
      let width = document.documentElement.clientWidth;//屏幕宽
      return 0.55 * width + 'px';
    },
    mainWidth: function () {
      let width = document.documentElement.clientWidth;//屏幕宽
      return (width - 30) + 'px';
    },
    recommendHeight: function () {
      let width = document.documentElement.clientWidth;//屏幕宽
      return 0.3 * width + 'px';
    },
    screenHeight: function () {
      let height = document.documentElement.clientHeight;//屏幕宽
      return (100 + height) + 'px';
    }
  }
}
</script>

<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.van-grid-item-text {
  font-size: small;
  color: #2c3e50;
  margin-top: 5px;
  word-break: break-all;
}
</style>